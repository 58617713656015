import { defineStore } from "pinia";
import { clubApi } from "@/lib/backend";
import type {
	DaosModelsBelpoging,
	DaosModelsMemberTracking,
	IomodelsCoachappActieVoorLid,
	IomodelsCoachappAfgenomenMeting,
	IomodelsCoachappDoelFrequentie,
	IomodelsCoachappFitnessAfspraakVoorLid,
	IomodelsCoachappFrequentieGrafiek,
	IomodelsCoachappLidBezoek,
	IomodelsCoachappLidInfo,
	IomodelsCoachappLidMetPrioInfo,
	IomodelsCoachappPersoonlijkeGegevens,
} from "@/lib/backend/club.api";
import type { GroepslessenResultaat, GuessedReservering } from "@/lib/backend/coach.api";
import { CoachService } from "@/services/coach";
import type { Doel, MedicalData, SubscriptionData } from "@/services/types/coach";

const coachService = new CoachService();

type State = {
	id: number;
	basicInfo?: IomodelsCoachappLidMetPrioInfo;
	doelFrequentie: Partial<IomodelsCoachappDoelFrequentie>;
	frequentieGrafiek: Partial<IomodelsCoachappFrequentieGrafiek>;
	bezoeken: IomodelsCoachappLidBezoek[];
	groepslessen: Partial<GroepslessenResultaat>;
	reserveringen: GuessedReservering[];
	aankomendeAfspraken: IomodelsCoachappFitnessAfspraakVoorLid[];
	doel: Partial<Doel>;
	metingen: Partial<{
		doel?: null;
		goalText?: null;
		metingen?: IomodelsCoachappAfgenomenMeting[];
		tracking: boolean;
	}>;
	lidInfo: Partial<IomodelsCoachappLidInfo>;
	medical: Partial<MedicalData>;
	subscription: Partial<SubscriptionData>;
	actions: IomodelsCoachappActieVoorLid[];
	belpogingen: DaosModelsBelpoging[];
	tracking?: DaosModelsMemberTracking;
};

export const useGebruikerStore = defineStore("gebruiker", {
	state: (): State => ({
		id: -1,
		basicInfo: undefined,
		doelFrequentie: {},
		frequentieGrafiek: {},
		bezoeken: [],
		groepslessen: {},
		reserveringen: [],
		aankomendeAfspraken: [],
		doel: {},
		metingen: {},
		lidInfo: {},
		medical: {},
		subscription: {},
		actions: [],
		belpogingen: [],
		tracking: undefined,
	}),
	getters: {
		bezoekenSortedByDate: ({ bezoeken }) =>
			bezoeken.sort((bezoekA, bezoekB) =>
				new Date(bezoekA.timestamp) >= new Date(bezoekB.timestamp) ? -1 : 1,
			),
		getMetingById: (state) => (id: number) =>
			state.metingen?.metingen?.find(({ metingId }) => metingId === id),
	},
	actions: {
		resetState() {
			return this.$reset();
		},
		set(id: number) {
			this.id = id;
		},
		async getBasicInfo() {
			const response = await clubApi.lid.getLidMetStatus(this.id);

			switch (response.status) {
				case 200: {
					this.basicInfo = response.data;
					return this.basicInfo;
				}

				case 404: {
					throw new Error("Lid niet gevonden");
				}

				default: {
					throw response;
				}
			}
		},
		async getLidInfo() {
			const response = await clubApi.sportersDashboard.getLidInfo(this.id);

			switch (response.status) {
				case 200: {
					this.lidInfo = response.data;
					return this.lidInfo;
				}

				default: {
					throw response;
				}
			}
		},

		async getDoelFrequentie() {
			const response = await clubApi.sportersDashboard.doelFrequentie(this.id);

			switch (response.status) {
				case 200: {
					this.doelFrequentie = response.data;

					return this.doelFrequentie;
				}

				default: {
					throw response;
				}
			}
		},
		async getFrequentieGrafiek() {
			const response = await clubApi.sportersDashboard.getFrequentieGrafiek(this.id);

			switch (response.status) {
				case 200: {
					this.frequentieGrafiek = response.data;
					return this.frequentieGrafiek;
				}

				default: {
					throw response;
				}
			}
		},
		async getBezoeken() {
			const response = await clubApi.sportersDashboard.getBezoeken(this.id);

			switch (response.status) {
				case 200: {
					this.bezoeken = response.data;
					return this.bezoeken;
				}

				default: {
					throw response;
				}
			}
		},
		async getGroepslessen() {
			const response = await coachService.getLidGroepslessen(this.id);

			switch (response.status) {
				case 200: {
					this.groepslessen = response.data;

					return this.groepslessen;
				}

				default: {
					throw response;
				}
			}
		},
		async getAankomendeAfspraken() {
			const response = await clubApi.sportersDashboard.getAankomendeAfspraak(this.id);

			switch (response.status) {
				case 200: {
					this.aankomendeAfspraken = response.data;
					return this.aankomendeAfspraken;
				}

				default: {
					throw response;
				}
			}
		},
		async getReserveringen() {
			const response = await coachService.getLidReserveringen(this.id);

			switch (response.status) {
				case 200: {
					this.reserveringen = response.data;

					return this.reserveringen;
				}

				default: {
					throw response;
				}
			}
		},
		async getTracking() {
			const response = await clubApi.tracking.getTracking(this.id);

			switch (response.status) {
				case 200: {
					this.tracking = response.data;

					return this.tracking;
				}

				default: {
					throw response;
				}
			}
		},
		async updateTracking(data: {
			value: boolean;
			type: "groepslessen" | "schemas" | "doel" | "metingen";
			id?: number;
		}) {
			const id = data.id ?? this.id;
			const isExternal = !!data.id;

			delete data.id;

			if (!id) {
				return;
			}

			const response = await coachService.updateTracking(id, {
				type: data.type,
				value: data.value,
			});

			switch (response.status) {
				case 200: {
					if (isExternal) {
						return;
					}

					await this.getBasicInfo();
					await this.getTracking();

					return;
				}

				default: {
					throw response;
				}
			}
		},
		async getDoel() {
			const response = await coachService.getLidDoel(this.id);

			switch (response.status) {
				case 200: {
					this.doel = response.data;

					return this.doel;
				}

				default: {
					throw response;
				}
			}
		},
		async saveDoel(data: Doel) {
			const response = await coachService.saveLidDoel(this.id, data);

			switch (response.status) {
				case 200: {
					this.doel = response.data;

					return this.doel;
				}

				default: {
					throw response;
				}
			}
		},
		async getMetingen() {
			const response = await coachService.getMetingen(this.id);

			switch (response.status) {
				case 200: {
					this.metingen = response.data;

					return this.metingen;
				}

				default: {
					throw response;
				}
			}
		},
		async postMeting(meting: IomodelsCoachappAfgenomenMeting) {
			const response = await clubApi.meting.postMeting(meting);

			switch (response.status) {
				case 200: {
					await this.getMetingen();
					return;
				}

				default: {
					throw response;
				}
			}
		},
		async removeMeting(metingId: number) {
			const response = await coachService.deleteMeting(this.id, metingId);

			switch (response.status) {
				case 200: {
					await this.getMetingen();

					return;
				}

				default: {
					throw response;
				}
			}
		},
		async getDashboardLidInfo() {
			const response = await clubApi.sportersDashboard.getLidInfo(this.id);

			switch (response.status) {
				case 200: {
					this.lidInfo = response.data;
					return this.lidInfo;
				}

				default: {
					throw response;
				}
			}
		},
		async saveDashboardLidInfo(body: IomodelsCoachappPersoonlijkeGegevens) {
			const response = await clubApi.lid.postPersoonlijkeGegevens(body);

			switch (response.status) {
				case 200: {
					return response.data;
				}

				default: {
					throw response;
				}
			}
		},
		async getMedical() {
			const response = await coachService.getPersonalMedical(this.id);

			switch (response.status) {
				case 200: {
					this.medical = response.data;

					return this.medical;
				}

				default: {
					throw response;
				}
			}
		},
		async saveMedical(data: MedicalData) {
			const response = await coachService.savePersonalMedical(this.id, data);

			switch (response.status) {
				case 200: {
					await this.getMedical();

					return;
				}

				default: {
					throw response;
				}
			}
		},
		async getSubscription() {
			const response = await coachService.getPersonalSubscription(this.id);

			switch (response.status) {
				case 200: {
					this.subscription = response.data;
					return this.subscription;
				}

				default: {
					throw response;
				}
			}
		},
		async getActions() {
			const response = await clubApi.sportersDashboard.getActies(this.id);

			switch (response.status) {
				case 200: {
					this.actions = response.data;
					return this.actions;
				}

				default: {
					throw response;
				}
			}
		},
		async getBelpogingen() {
			const response = await clubApi.sportersDashboard.belpogingen(this.id);

			switch (response.status) {
				case 200: {
					this.belpogingen = response.data;
					return this.belpogingen;
				}

				default: {
					throw response;
				}
			}
		},
		async createBelpoging(belpoging: DaosModelsBelpoging) {
			const response = await clubApi.sportersDashboard.postBelpoging(belpoging);

			switch (response.status) {
				case 200: {
					await this.getBelpogingen();
					return;
				}

				default: {
					throw response;
				}
			}
		},
	},
});

export default useGebruikerStore;
