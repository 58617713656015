



























































































































































































import { type PropType, computed, defineComponent, ref, watch } from "@vue/composition-api";
import { useInfiniteQuery, useQuery } from "@tanstack/vue-query";
import { mapActions } from "vuex";
import { coreApi } from "@/lib/backend";
import type { LesUitgebreid } from "@/lib/backend/coach.api";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { useMemberFilters } from "@/lib/hooks/useMemberFilters";
import { queryOptions_gyms } from "@/lib/query/functions/gyms";
import { flattenMembers, infiniteQueryOptions_members } from "@/lib/query/functions/members";
import { toast } from "@/lib/vue2-sonner";
import { useAppStore } from "@/pinia/app";
import { getDateFromDatum, getDayMonthYear } from "@/utils/date";

export default defineComponent({
	props: {
		data: {
			type: Object as PropType<{
				search?: boolean;
				les: LesUitgebreid;
				memberId?: number;
			}>,
			default: () => ({ search: false, memberId: undefined }),
		},
		callback: {
			type: Function as PropType<() => void | Promise<void>>,
			default: () => {
				return;
			},
		},
	},
	setup(props) {
		const selectedMemberId = ref<number | undefined>(props.data.memberId);
		const message = ref<string | undefined>(undefined);
		const recurring = ref(false);
		const hasError = ref(false);
		const { data: gyms } = useQuery(queryOptions_gyms());

		const appStore = useAppStore();

		const { filters } = useMemberFilters();

		watch(filters, () => (selectedMemberId.value = undefined), { deep: true });

		const { status, data } = useInfiniteQuery(
			infiniteQueryOptions_members(computed(() => filters.value)),
		);

		const members = computed(() => flattenMembers(data.value));

		return {
			selectedMemberId,
			message,
			recurring,
			hasError,
			gyms,
			appStore,
			filters,
			status,
			members,
		};
	},
	methods: {
		...mapActions("modal", { openModal: "openModal" }),
		async aanmelden() {
			if (!this.selectedMemberId || !(await this.$validator.validateAll())) {
				toast.error("Niet mogelijk om lid aan te melden");
				return;
			}

			await toast
				.promise(
					coreApi.lessons.lessonControllerSignup(this.data.les.les_id, {
						memberId: this.selectedMemberId,
						recurring: this.recurring,
					}),
					{
						loading: "Lid aanmelden...",
						success: () => {
							this.callback();
							this.message = "Lid succesvol aangemeld";
							return this.message;
						},
						error: (error: unknown) => {
							this.hasError = true;
							this.message = formatErrorMessage(error);
							return this.message;
						},
					},
				)
				?.unwrap();
		},
		openTempMemberModal() {
			this.openModal({
				name: "temp-member",
				data: {
					popup: {
						enable: false,
					},
				},
				callback: async (closeModal: () => void, user: { id: number }) => {
					closeModal();
					this.openModal({
						name: "groepsles-aanmelden",
						data: {
							...this.data,
							memberId: user.id,
						},
						callback: this.callback
							? this.callback
							: () => {
									return;
								},
					});
				},
			});
		},
		getDateFromDatum,
		getDayMonthYear,
	},
});
