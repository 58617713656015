import type { DirectiveFunction, PluginObject } from "vue";
// eslint-disable-next-line no-restricted-imports
import { twMerge } from "tailwind-merge";

function computeClasses(func = twMerge): DirectiveFunction {
	return (el, _binding, vnode, _oldVnode) => {
		const existingClasses = el.classList.value;
		const inheritedClasses = vnode.parent?.data?.class || vnode.parent?.data?.staticClass;

		// No need to run twMerge if there are no classes
		if (!existingClasses || !inheritedClasses) {
			return;
		}

		// This works because all fallthrough classes are added at the end of the string
		el.classList.value = func(existingClasses, inheritedClasses);
	};
}

export const plugin: PluginObject<typeof twMerge> = {
	install: (app, options = twMerge) => {
		const func = computeClasses(options);

		app.directive("twMerge", { bind: func, update: func });
	},
};
