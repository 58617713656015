













import { defineComponent } from "@vue/composition-api";
import { mapState as mapPiniaState } from "pinia";
import { mapActions } from "vuex";
import { useAuthStore } from "@/pinia/auth";
import { getCoachImage } from "@/utils/images";

export default defineComponent({
	computed: {
		...mapPiniaState(useAuthStore, { authUser: "user" }),
	},
	methods: {
		...mapActions("userMenu", ["openUserMenu"]),
		getCoachImage,
	},
});
