import Vue from "vue";
import moment from "moment";

// This file is required for pfg-rooster

// 17:40
Vue.filter("tijdstip", (value) => moment(value).format("H:mm"));

// 31 mei
Vue.filter("dagMaand", (value) => moment(value).format("D MMMM"));

// 31 mei 2017
Vue.filter("dagMaandJaar", (value) => moment(value).format("D MMMM YYYY"));

Vue.filter("dagMaandKort", (value) => {
	return moment(value).format("D/M");
});

// vrijdag
Vue.filter("weekDag", (value) => moment(value).format("dddd"));

// vrijdag (vandaag if is today)
Vue.filter("weekDagVandaag", (value) => {
	if (moment(value).isSame(moment(), "day")) {
		return "vandaag";
	}
	return moment(value).format("dddd");
});

// vr
Vue.filter("weekDagKort", (value) => moment(value).format("ddd"));

Vue.filter("thisWeek", (value) => moment(value).week() === moment().week());

Vue.filter("count", (value) => value.length);

Vue.filter("leeftijd", (value) => {
	if (!value) {
		return "";
	}
	return moment().diff(moment(value, "DD-MM-YYYY"), "year");
});

Vue.filter("meting", (value) => {
	if (value === 0) {
		return "-";
	}
	return value;
});

Vue.filter("capitalize", (value) => {
	if (typeof value === "string") {
		return value.charAt(0).toUpperCase() + value.slice(1);
	}
	return value;
});
