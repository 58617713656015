import {
	APPLICATION_COMMIT_HASH,
	APPLICATION_ENVIRONMENT,
	APPLICATION_NAME,
	APPLICATION_TIME_OF_BUILD,
	APPLICATION_VERSION,
} from "@/lib/constants/env";

export function logInfo() {
	const date = new Date(APPLICATION_TIME_OF_BUILD);

	console.group(
		"%cApplication information",
		"font-family: monospace; font-size: 14px; font-weight: bold;",
	);
	console.table({
		Name: APPLICATION_NAME,
		Version: APPLICATION_VERSION,
		Environment: APPLICATION_ENVIRONMENT,
		"Git hash": APPLICATION_COMMIT_HASH.trim(),
		"Build date": date.toLocaleDateString(),
		"Build time": date.toLocaleTimeString(),
		"Build ISO DateTime": date.toISOString(),
	});
	console.groupEnd();
}
