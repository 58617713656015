








import { defineComponent } from "@vue/composition-api";

export default defineComponent({
	name: "PfgIcon",
	props: {
		iconId: {
			type: String,
			required: true,
		},
		width: {
			type: String,
			required: false,
			default: "1em",
		},
		height: {
			type: String,
			required: false,
			default: "1em",
		},
	},
	computed: {
		iconHref(): string {
			return `#${this.iconId}`;
		},
	},
});
