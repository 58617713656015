<template>
	<!-- eslint-disable vue/no-v-html -->
	<div>
		<transition name="drop">
			<div v-show="opened" class="pfg-modal">
				<div class="pfg-modal_container">
					<transition name="drop">
						<modal-deelnemerslijst-indienen
							v-if="opened == 'deelnemerslijst-indienen'"
							@close="closeModal"
						/>
						<modal-groepsles-aanmelden
							v-if="opened == 'groepsles-aanmelden'"
							:callback="modalCallbacks['groepsles-aanmelden']"
							:data="modalData['groepsles-aanmelden']"
							@close="closeModal"
						/>
						<modal-groepsles-afmelden
							v-if="opened == 'groepsles-afmelden'"
							:callback="modalCallbacks['groepsles-afmelden']"
							:data="modalData['groepsles-afmelden']"
							@close="closeModal"
						/>
						<modal-les-annuleren
							v-if="opened == 'les-annuleren'"
							:callback="modalCallbacks['les-annuleren']"
							:data="modalData['les-annuleren']"
							@close="closeModal"
						/>
						<modal-afspraak-inplannen
							v-if="opened == 'afspraak-inplannen'"
							:callback="modalCallbacks['afspraak-inplannen']"
							:data="modalData['afspraak-inplannen']"
							@close="closeModal"
						/>
						<modal-temp-member
							v-if="opened == 'temp-member'"
							:callback="modalCallbacks['temp-member']"
							:data="modalData['temp-member']"
							@close="closeModal"
						/>
						<modal-webcam
							v-if="opened == 'webcam'"
							:callback="modalCallbacks['webcam']"
							:data="modalData['webcam']"
							@close="closeModal"
						/>
						<modal-undo-lesson-cancellation
							v-if="opened == 'undo-lesson-cancellation'"
							:callback="modalCallbacks['undo-lesson-cancellation']"
							:data="modalData['undo-lesson-cancellation']"
							@close="closeModal"
						/>
						<modal-send-message
							v-if="opened == 'send-message'"
							:callback="modalCallbacks['send-message']"
							:data="modalData['send-message']"
							@close="closeModal"
						/>
						<modal-view-note
							v-if="opened == 'view-note'"
							:callback="modalCallbacks['view-note']"
							:data="modalData['view-note']"
							@close="closeModal"
						/>
						<modal-assign-members
							v-if="opened == 'assign-members'"
							:callback="modalCallbacks['assign-members']"
							:data="modalData['assign-members']"
							@close="closeModal"
						/>

						<modal-confirm
							v-if="opened == 'confirm'"
							:callback="modalCallbacks['confirm']"
							:data="modalData['confirm']"
							@close="closeModal"
						/>

						<modal-missing-info
							v-if="opened == 'missing-info'"
							:callback="modalCallbacks['missing-info']"
							:data="modalData['missing-info']"
							@close="closeModal"
						/>

						<modal-proefsporter-bekend
							v-if="opened == 'proefsporter-bekend'"
							:callback="modalCallbacks['proefsporter-bekend']"
							:data="modalData['proefsporter-bekend']"
							@close="closeModal"
						/>

						<div v-if="opened == 'error'" class="pfg-modal_error">
							<header class="pfg-header">
								<div class="pfg-header_heading">
									<h1 class="s_elem-has-icon-left a_fill_default">Er is iets fout gegaan</h1>
								</div>
							</header>
							<p class="a_margin-top-20" v-html="modalData.error" />
							<div class="pfg-button_group a_flex a_justify_flex-end a_margin-top-40">
								<button
									class="button v_brand_primary v_smallest a_margin-bottom-0"
									@click="closeModal"
								>
									<span class="button-txt">Sluiten</span>
								</button>
							</div>
						</div>
					</transition>
				</div>
			</div>
		</transition>

		<transition name="fade">
			<div v-show="opened" class="pfg-modal_bg" @click="closeModal"></div>
		</transition>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ModalAfspraakInplannen from "@/components/elements/ModalAfspraakInplannen";
import ModalAssignMembers from "@/components/elements/ModalAssignMembers";
import ModalConfirm from "@/components/elements/ModalConfirm";
import ModalDeelnemerslijstIndienen from "@/components/elements/ModalDeelnemerslijstIndienen";
import ModalGroepslesAanmelden from "@/components/elements/ModalGroepslesAanmelden";
import ModalGroepslesAfmelden from "@/components/elements/ModalGroepslesAfmelden";
import ModalLesAnnuleren from "@/components/elements/ModalLesAnnuleren";
import ModalMissingInfo from "@/components/elements/ModalMissingInfo";
import ModalProefsporterBekend from "@/components/elements/ModalProefsporterBekend";
import ModalSendMessage from "@/components/elements/ModalSendMessage";
import ModalTempMember from "@/components/elements/ModalTempMember";
import ModalUndoLessonCancellation from "@/components/elements/ModalUndoLessonCancellation";
import ModalViewNote from "@/components/elements/ModalViewNote";
import ModalWebcam from "@/components/elements/ModalWebcam";

export default {
	name: "Modal",
	components: {
		ModalDeelnemerslijstIndienen,
		ModalGroepslesAanmelden,
		ModalGroepslesAfmelden,
		ModalLesAnnuleren,
		ModalAfspraakInplannen,
		ModalTempMember,
		ModalWebcam,
		ModalUndoLessonCancellation,
		ModalSendMessage,
		ModalViewNote,
		ModalAssignMembers,
		ModalConfirm,
		ModalMissingInfo,
		ModalProefsporterBekend,
	},
	computed: {
		...mapState("modal", ["opened", "modalData", "modalCallbacks"]),
	},
	methods: {
		...mapActions("modal", ["closeModal"]),
	},
};
</script>

<style lang="scss" scoped>
////
////    VUE TRANSITIONS
////

.drop-enter-active {
	transition: all 0.2s ease;
}
.drop-leave-active {
	transition: all 0.2s ease;
}
.drop-enter {
	opacity: 0;
	transform: translateY(-40px);
}
.drop-enter-to,
.drop-leave {
	opacity: 1;
	transform: translateY(0);
}
.drop-leave-to {
	opacity: 0;
	transform: translateY(-40px);
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}
.fade-enter,
.fade-leave-to {
	transition: opacity 0.2s ease 0.1s;
	opacity: 0;
}

////
////    GENETRICS
////
.pfg-modal {
	position: fixed;
	z-index: 5556;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.pfg-modal_container {
	background: $brand-white;
	box-shadow: 0 0 30px rgba(black, 0.3);
	padding: $default-padding;
	border-radius: 15px;
	width: 700px;
	max-width: 85vw;

	@include breakpoint(medium) {
		padding: $default-padding * 2;
	}
}

////
////    BG
////
.pfg-modal_bg {
	position: fixed;
	z-index: 5555;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($brand-primary, 0.9);
}
</style>
