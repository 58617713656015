import Vue from "vue";
import Vuex from "vuex";
import groepsles from "./modules/groepsles";
import modal from "./modules/modal";
import modal2 from "./modules/modal2";
import model from "./modules/model";
import tijdsloten from "./modules/tijdsloten";
import trial from "./modules/trial";
import user from "./modules/user";
import userMenu from "./modules/userMenu";

Vue.use(Vuex);

/** @deprecated Please use `pinia` instead */
export const store = new Vuex.Store({
	strict: process.env.NODE_ENV !== "production",

	state: {},
	getters: {},
	modules: {
		modal,
		modal2,
		userMenu,
		user,
		model,
		groepsles,
		tijdsloten,
		trial,
	},
});

export default store;
