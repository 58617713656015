/* eslint-disable @typescript-eslint/no-non-null-assertion */

const API_CLIENT_ID = process.env.VUE_APP_API_CLIENT_ID!;
const API_CLIENT_SECRET = process.env.VUE_APP_API_CLIENT_SECRET!;

export const config = {
	google_analytics: process.env.VUE_APP_GOOGLE_ANALYTICS!,

	coreBackend: process.env.VUE_APP_CORE_BACKEND!,

	coach: {
		server: process.env.VUE_APP_API_SERVER_COACH!,
		client_id: API_CLIENT_ID,
		client_secret: API_CLIENT_SECRET,
	},
	club: {
		server: process.env.VUE_APP_API_SERVER_CLUB!,
		client_id: API_CLIENT_ID,
		client_secret: API_CLIENT_SECRET,
	},
	reserveer: {
		server: process.env.VUE_APP_API_SERVER_RESERVEER!,
		client_id: API_CLIENT_ID,
		client_secret: API_CLIENT_SECRET,
	},
};
