import type Highcharts from "highcharts";

const primary = "#262261";
const primaryLighter = "#7b7aa0";
const primaryLightest = "#dadae3";
const secondary = "#d4145a";

export const highchartsSettings: Highcharts.Options = {
	lang: {
		months: [
			"Januari",
			"Februair",
			"Maart",
			"April",
			"Mei",
			"Juni",
			"Juli",
			"Augustus",
			"September",
			"Oktober",
			"November",
			"December",
		],
		shortMonths: [
			"Jan",
			"Feb",
			"Mrt",
			"Apr",
			"Mei",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Okt",
			"Nov",
			"Dec",
		],
		weekdays: ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"],
	},
	colors: [secondary, primary, primary, primary, primary],
	credits: {
		enabled: false,
	},
	chart: {
		backgroundColor: undefined,
		marginBottom: 100,
		marginLeft: 0,
		marginRight: 0,
		marginTop: 100,
		style: {
			fontFamily: '"Radikal", Arial, sans-serif',
			fontSize: "16px",
		},
	},
	legend: {
		align: "left",
		itemHiddenStyle: {
			color: primaryLightest,
		},
		itemHoverStyle: {
			color: primary,
		},
		itemStyle: {
			color: primaryLighter,
			fontWeight: "normal",
			fontSize: "14px",
		},
		symbolHeight: 12,
		symbolWidth: 12,
		symbolRadius: 6,
		verticalAlign: "bottom",
		x: 0,
		y: 0,
	},
	plotOptions: {
		series: {
			marker: {
				fillColor: "white",
				lineColor: null,
				lineWidth: 2,
				states: {
					hover: {
						radiusPlus: 1,
						lineWidthPlus: 0,
					},
				},
				radius: 5,
				symbol: "circle",
			},
			states: {
				hover: {
					lineWidthPlus: 0,
				},
			},
		},
	},
	subtitle: {
		align: "left",
		text: undefined,
		style: {
			color: primaryLighter,
		},
	},
	title: {
		align: "left",
		text: null,
		style: {
			color: primary,
		},
	},
	tooltip: {
		backgroundColor: "rgba(255,255,255,0.95)",
		borderColor: "rgba(33,33,33,0.08)",
		borderRadius: 12,
		crosshairs: true,
		followTouchMove: true,
		headerFormat: '<div style="font-size: 16px;">{point.key}</div>',
		shadow: false,
		shared: true,
		snap: 50,
		style: {
			color: primary,
			padding: 20,
			fontSize: "13px",
		},
		useHTML: true,
	},
	xAxis: {
		allowDecimals: false,
		crosshair: {
			color: secondary,
			dashStyle: "dash",
		},
		gridLineColor: primaryLightest,
		labels: {
			style: {
				color: primaryLighter,
				fontSize: "13px",
			},
		},
		lineWidth: 1,
		lineColor: primaryLightest,
		// startOnTick: true,
		tickWidth: 0,
		title: {
			align: "high",
			style: {
				color: primary,
				fontSize: "16px",
			},
			x: 0,
			y: 20,
		},
		type: "linear",
	},
	yAxis: {
		gridLineColor: primaryLightest,
		labels: {
			align: "left",
			x: 0,
			y: -10,
			style: {
				color: primaryLighter,
				fontSize: "13px",
			},
		},
		lineWidth: 0,
		title: {
			align: "high",
			style: {
				color: primary,
				fontSize: "16px",
			},
			offset: 0,
			rotation: 0,
			y: -40,
			x: 70,
		},
	},
	responsive: {
		rules: [
			{
				condition: {
					minWidth: 560, // minus padding of main container = 40
				},
				chartOptions: {
					chart: {
						marginBottom: 80,
						marginLeft: 40,
					},
					legend: {
						align: "right",
						verticalAlign: "top",
					},
					yAxis: {
						title: {
							align: "high",
							offset: 0,
							rotation: 270,
							x: -20,
							y: -20,
						},
					},
				},
			},
		],
	},
};
