



















import { type PropType, defineComponent } from "@vue/composition-api";
import { uuid } from "@/utils/uuid";

type Data = {
	id: string;
};

type ModelValue = boolean | string | string[];

export default defineComponent({
	name: "PfgSwitcher",
	model: {
		prop: "modelValue",
		event: "update:modelValue",
	},
	props: {
		valueName: {
			type: String,
			required: false,
			default: undefined,
		},
		modelValue: {
			type: [String, Array, Boolean] as PropType<ModelValue>,
			required: false,
			default: undefined,
		},
		switchVersion: {
			type: String,
			default: undefined,
			required: false,
		},
		switchDescription: {
			type: String,
			default: undefined,
			required: false,
		},
		switchActive: {
			type: String,
			default: "Aan",
			required: false,
		},
		switchInactive: {
			type: String,
			default: "Uit",
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	emits: ["update:modelValue"],
	data(): Data {
		return {
			id: `switch-${uuid()}`,
		};
	},
	computed: {
		model: {
			get(): ModelValue {
				return this.modelValue;
			},
			set(value: ModelValue) {
				this.$emit("update:modelValue", value);
			},
		},
	},
});
