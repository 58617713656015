<template>
	<div>
		<header class="pfg-header">
			<div class="pfg-header_heading">
				<h1 class="s_elem-has-icon-left a_fill_default">
					Deelnemerslijst indienen
					<pfg-icon icon-id="icon_groepsles" />
				</h1>
			</div>

			<div class="pfg-header_description">
				<p>
					Weet je zeker dat je de delenemerslijst wilt indienen? Wijzigen is hierna niet meer
					mogelijk
				</p>
			</div>
			<ul>
				<li>12 van de 16 deelnemers zijn aanwezig</li>
			</ul>
		</header>

		<hr />

		<div class="pfg-button_group">
			<button
				class="button v_ghost_brand_secondary v_has-icon-right v_smaller a_margin-left-10"
				@click="$emit('close')"
			>
				<span class="button-txt">Annuleren</span>
			</button>
			<button class="button v_ghost_brand_primary v_smaller a_margin-left-10">
				<span class="button-txt">Deelnemerslijst indienen</span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "ModalDeelnemerslijstIndienen",
};
</script>

<style scoped lang="scss"></style>
