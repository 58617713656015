




















import { type PropType, defineComponent } from "@vue/composition-api";

export default defineComponent({
	name: "ModalConfirm",
	props: {
		data: {
			type: Object,
			required: true,
		},
		callback: {
			type: Function as PropType<() => void>,
			default: () => {
				return;
			},
			required: false,
		},
	},
	methods: {
		onConfirm() {
			this.callback();
			this.$emit("close");
		},
	},
});
