import Vue from "vue";
import VueRouter, { type RouteConfig } from "vue-router";
import { useAppStore } from "@/pinia/app";
import { useAuthStore } from "@/pinia/auth";
import { RoutePropsSchemas } from "@/router/types";
import { routeProps } from "@/router/utils";
import { requireAuth, requireUnauth } from "./authGuard";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
	{
		name: "Home",
		path: "/",
		alias: "/index.html",
		beforeEnter: (__to, __from, next) => {
			const auth = useAuthStore();
			auth.init();

			if (auth.isLoggedIn) {
				return next({ name: "Coach dashboard" });
			} else {
				return next({ name: "Login" });
			}
		},
	},
	{
		name: "Login",
		path: "/login",
		beforeEnter: requireUnauth,
		component: async () => await import(/* webpackChunkName: "views/login" */ "@/views/Login.vue"),
		meta: {
			hideTopbar: true,
			fullScreen: true,
		},
	},
	{
		name: "Live feed",
		path: "/live-feed",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/live-feed" */ "@/views/LiveFeed.vue"),
	},
	{
		name: "Leden",
		path: "/leden",
		beforeEnter: requireAuth,
		component: async () => await import(/* webpackChunkName: "views/leden" */ "@/views/Leden.vue"),
	},
	{
		name: "Coach dashboard",
		path: "/coach-dashboard",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/coach-dashboard" */ "@/views/CoachDashboard.vue"),
	},
	{
		name: "Groepslessen",
		path: "/groepslessen",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/groepslessen" */ "@/views/Groepslessen.vue"),
	},
	{
		name: "Groepslessen rooster",
		path: "/groepslessen/rooster",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/groepslessen/rooster" */ "@/views/GroepslessenRooster.vue"
			),
	},
	{
		name: "Groepsles detail",
		path: "/groepslessen/:id/detail",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/groepslessen/[grouplessonId]/detail" */ "@/views/groepslessen/[grouplessonId]/detail.vue"
			),
		props: routeProps(RoutePropsSchemas["grouplesson"], (route) => ({
			grouplessonId: route.params.id,
		})),
	},
	{
		name: "Groepsles detail print",
		path: "/groepslessen/:id/detail/print",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/groepslessen/detail/print" */ "@/views/GroepslesDetailPrint.vue"
			),
		meta: { hideTopbar: true },
	},
	{
		name: "Afspraken",
		path: "/afspraken",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/afspraken" */ "@/views/Afspraken.vue"),
	},
	{
		name: "Formulieren",
		path: "/formulieren",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/formulieren" */ "@/views/Formulieren.vue"),
	},
	{
		name: "Instellingen",
		path: "/instellingen",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/instellingen" */ "@/views/Instellingen.vue"),
	},
	{
		path: "/gebruiker/:id",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/gebruiker" */ "@/views/gebruiker/Gebruiker.vue"),
		meta: { gebruikerView: true },
		children: [
			{
				name: "Gebruiker",
				path: "",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/dashboard" */ "@/views/gebruiker/Dashboard.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker persoonlijk gegevens",
				path: "persoonlijk/gegevens",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/persoonlijk/gegevens" */ "@/views/gebruiker/PersoonlijkGegevens.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker persoonlijk medisch",
				path: "persoonlijk/medisch",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/persoonlijk/medisch" */ "@/views/gebruiker/PersoonlijkMedisch.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker persoonlijk abonnementsvorm",
				path: "persoonlijk/abonnementsvorm",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/persoonlijk/abonnementsvorm" */ "@/views/gebruiker/PersoonlijkAbonnementsvorm.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker profielafbeelding",
				path: "profiel-afbeelding",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/profiel-afbeelding" */ "@/views/gebruiker/ProfielAfbeelding.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				path: "metingen",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/metingen" */ "@/views/gebruiker/metingen/Parent.vue"
					),
				children: [
					{
						name: "Gebruiker metingen",
						path: "",
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/metingen" */ "@/views/gebruiker/metingen/Metingen.vue"
							),
						meta: { gebruikerView: true },
					},
					{
						name: "Gebruiker meting",
						path: "meting/:metingId?",
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/metingen/meting" */ "@/views/gebruiker/metingen/Meting.vue"
							),
						meta: { hideTopbar: true, gebruikerView: false },
					},
				],
			},
			{
				name: "Gebruiker doel",
				path: "doel",
				component: async () =>
					await import(/* webpackChunkName: "views/gebruiker/doel" */ "@/views/gebruiker/Doel.vue"),
				meta: { gebruikerView: true },
			},
			{
				path: "schemas",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/[memberId]/schema" */ "@/views/gebruiker/[memberId]/schema/_layout.vue"
					),
				children: [
					{
						name: "Member programs",
						path: "",
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/[memberId]/schema" */ "@/views/gebruiker/[memberId]/schema/index.vue"
							),
						meta: { gebruikerView: true },
						props: routeProps(RoutePropsSchemas["member"], (route) => ({
							memberId: route.params.id,
						})),
					},
					{
						name: "Member program",
						path: ":programId/week/:week/dag/:day",
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/[memberId]/schema/[programId]/week/[week]/dag/[day]" */ "@/views/gebruiker/[memberId]/schema/[programId]/week/[week]/dag/[day].vue"
							),
						meta: { fullScreen: true, hideTopbar: true, gebruikerView: false },
						props: routeProps(RoutePropsSchemas["Member program"], (route) => ({
							memberId: route.params.id,
							programId: route.params.programId,
							programWeek: route.params.week,
							programDay: route.params.day,
						})),
					},
				],
			},
			{
				name: "Gebruiker groepslessen",
				path: "groepslessen",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/groepslessen" */ "@/views/gebruiker/Groepslessen.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker groepsles detail",
				path: "groepslessen/:groepslesId/detail",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/groepslessen/detail" */ "@/views/gebruiker/GroepslesDetail.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Member activity",
				path: "activiteiten",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/[memberId]/activiteiten" */ "@/views/gebruiker/[memberId]/activiteiten/index.vue"
					),
				meta: { gebruikerView: true },
				props: routeProps(RoutePropsSchemas["member"], (route) => ({
					memberId: route.params.id,
				})),
			},
			{
				name: "Member messages",
				path: "berichten",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/[memberId]/berichten" */ "@/views/gebruiker/[memberId]/berichten/index.vue"
					),
				meta: { gebruikerView: true },
				props: routeProps(RoutePropsSchemas["member"], (route) => ({
					memberId: route.params.id,
				})),
				children: [
					{
						name: "Member message create",
						path: "nieuw",
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/[memberId]/berichten/nieuw" */ "@/views/gebruiker/[memberId]/berichten/nieuw.vue"
							),
						meta: { gebruikerView: true },
						props: routeProps(RoutePropsSchemas["member"], (route) => ({
							memberId: route.params.id,
						})),
					},
					{
						name: "Member message",
						path: "bericht/:messageId",
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/[memberId]/berichten/messageId" */ "@/views/gebruiker/[memberId]/berichten/[messageId].vue"
							),
						meta: { gebruikerView: true },
						props: routeProps(RoutePropsSchemas["Member message"], (route) => ({
							memberId: route.params.id,
							messageId: route.params.messageId,
						})),
					},
				],
			},
			{
				path: "afspraak",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/[memberId]/afspraak" */ "@/views/gebruiker/[memberId]/afspraak/_layout.vue"
					),
				children: [
					{
						name: "Member appointment create",
						path: "inplannen",
						beforeEnter: requireAuth,
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/[memberId]/afspraak/inplannen" */ "@/views/gebruiker/[memberId]/afspraak/inplannen.vue"
							),
						meta: { hideTopbar: true, gebruikerView: false },
						props: routeProps(RoutePropsSchemas["member"], (route) => ({
							memberId: route.params.id,
						})),
					},
					{
						name: "Member appointment edit",
						path: ":afspraakId/aanpassen",
						beforeEnter: requireAuth,
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/[memberId]/afspraak/[appointmentId]/aanpassen" */ "@/views/gebruiker/[memberId]/afspraak/[appointmentId]/aanpassen.vue"
							),
						meta: { hideTopbar: true, gebruikerView: false },
						props: routeProps(RoutePropsSchemas["Member appointment edit"], (route) => ({
							memberId: route.params.id,
							appointmentId: route.params.afspraakId,
						})),
					},
				],
			},
		],
	},

	{
		name: "Gebruiker belpoging",
		path: "/gebruiker/:id/belpoging",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/gebruiker/belpoging" */ "@/views/GebruikerBelpoging.vue"
			),
		meta: { hideTopbar: true },
	},
	{
		name: "Leden aannemen",
		path: "/leden-aannemen",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/leden-aannemen" */ "@/views/LedenAannemen.vue"),
		meta: { hideTopbar: false },
	},
	{
		name: "Not found",
		path: "/:catchAll(.*)*",
		alias: "/404",
		component: async () =>
			await import(/* webpackChunkName: "views/page-not-found" */ "@/views/NotFound.vue"),
		meta: { hideTopbar: true },
	},
];

export const router = new VueRouter({
	mode: "history",
	routes,
	scrollBehavior: (to, _from, savedPosition) => {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth",
			};
		} else if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0, behavior: "smooth" };
		}
	},
});

router.beforeEach((to, _, next) => {
	window.scrollTo(0, 0);

	useAppStore().$state.fullScreen = to.meta?.fullScreen ?? false;
	useAppStore().$state.showingTopbar = !(to.meta?.hideTopbar ?? false);

	return next();
});

export default router;
