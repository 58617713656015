<template>
	<div>
		<div class="pfg-user-menu-links_container">
			<div class="pfg-user-menu-links_button">
				<button
					class="button v_transparent v_has-icon-top a_fill_brand_primary-lightest"
					@click="goToInstellingen"
				>
					<span class="button-txt">Instellingen</span>
					<span class="svg-container">
						<svg role="img">
							<use :xlink:href="'#icon_cogwheel'"></use>
						</svg>
					</span>
				</button>
			</div>
			<div class="pfg-user-menu-links_button">
				<button
					to="/"
					class="button v_transparent v_has-icon-top a_fill_brand_primary-lightest"
					@click="logout"
				>
					<span class="button-txt">Uitloggen</span>
					<span class="svg-container">
						<svg role="img">
							<use :xlink:href="'#icon_afmelden'"></use>
						</svg>
					</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions as mapPiniaActions } from "pinia";
import { mapActions, mapState } from "vuex";
import { useAuthStore } from "@/pinia/auth";

export default {
	name: "Links",
	computed: {
		...mapState("userMenu", ["opened"]),
	},
	methods: {
		...mapPiniaActions(useAuthStore, { signOut: "signOut" }),
		...mapActions("userMenu", ["closeUserMenu"]),
		goToInstellingen() {
			this.$router.push("/instellingen");
			this.closeUserMenu();
		},
		async logout() {
			await this.signOut();
		},
	},
};
</script>

<style scoped lang="scss">
.pfg-user-menu-links_container {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $default-padding;

	@include breakpoint(medium) {
		padding: $default-padding * 2;
	}
}
.pfg-user-menu-links_button {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: $default-border;
	padding: $default-padding 0;

	&:last-child {
		border-right: none;
	}

	.button {
		margin: 0;
		text-transform: uppercase;
		font-size: 0.7rem;
		@include breakpoint(medium) {
			font-size: 0.9rem;
		}

		.svg-container {
			margin-bottom: 1.6em;
			height: 20px;
			@include breakpoint(medium) {
				height: 30px;
			}
		}
		svg {
			width: 20px;
			height: 20px;
			@include breakpoint(medium) {
				width: 30px;
				height: 30px;
			}
		}
	}
}
</style>
