import type {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	HeadersDefaults,
	ResponseType,
} from "axios";
import axios from "axios";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DaosModelsCoronaTijdslot {
	/** @format int64 */
	tijdslotId: number;
	/** @format int64 */
	vestigingId: number;
	status: string;
	/** @format date-time */
	datum: string;
	/** @format int32 */
	duur: number;
}

export interface IomodelsRefreshToken {
	token: string;
}

export interface IomodelsStatistiekGroei {
	huidigeVestiging: IomodelsStatistiekWeergave;
	overigeVestigingen: IomodelsStatistiekWeergave;
}

export interface IomodelsCoachMemberExtern {
	/** @format int64 */
	id: number;
	voornaam: string;
	achternaam: string;
	profileImg: string;
	alerts: string[];
	geboorteDatum: string;
	locatie: string;
	/** @format int64 */
	coachLid?: number | null;
	coachingsGroep?: "30%" | "70%" | null;
}

export interface IomodelsCoachLedenOverviewExtern {
	nextPage: boolean;
	/** @format int32 */
	page: number;
	/** @format int32 */
	total: number;
	users: IomodelsCoachMemberExtern[];
}

export interface IomodelsOverviewFilter {
	vestiging: string;
	sort: "check-in" | "alfabet" | "";
	search: string;
	/** @format int32 */
	page?: number | null;
	/** @format int64 */
	coachId?: number | null;
	coachingsGroep?: "30%" | "70%" | null;
}

export interface DaosModelsMemberTracking {
	/** @format int64 */
	id?: number | null;
	/** @format int64 */
	memberId: number;
	grouplessons: boolean;
	schemas: boolean;
	target: boolean;
	measurements: boolean;
}

export interface IomodelsIngelogdeGebruiker {
	naam: string;
	foto?: string | null;
	vestiging?: string | null;
	admin: boolean;
	/** @format int64 */
	coachId?: number | null;
}

export interface IomodelsBezoekenPerUur {
	/** @format int32 */
	uur: number;
	/** @format int32 */
	bezoeken: number;
	/** @format int64 */
	vestiging: number;
	/** @format date-time */
	dag: string;
}

export interface IomodelsBezoekenResultaat {
	bezoekenVandaag: IomodelsBezoekenPerUur[];
	bezoekenVorigePeriode: IomodelsBezoekenPerUur[];
}

export interface IomodelsStatistiekGrafiek {
	huidige: IomodelsStatistiekWeergave[];
	vorige: IomodelsStatistiekWeergave[];
}

export interface IomodelsCoachappAlgemeneCoachStatistieken {
	/** @format int32 */
	aantal30ProcentSporters: number;
	/** @format int32 */
	aantal70ProcentSporters: number;
	/** @format int32 */
	totaalAantalGekoppeldeSporters: number;
}

export interface IomodelsAfbeelding {
	formaat: string;
	url: string;
}

export interface IomodelsVestigingResult {
	vestigingNaam: string;
	aanvoerderNaam?: string | null;
	aanvoerderFoto?: string | null;
	/** @format date-time */
	opening?: string | null;
	mappingNaam: string;
	afbeeldingen: IomodelsAfbeelding[];
}

export interface IomodelsCoachappFitnessAfspraakVoorLid {
	/** @format int64 */
	afspraakId: number;
	/** @format date-time */
	datum: string;
	meting: boolean;
	trainingsSchema: boolean;
	doelOpstellen: boolean;
	personalTraining: boolean;
	kickOff: boolean;
	opmerkingen?: string | null;
}

export interface IomodelsLaatsteLesWeergave {
	/** @format int64 */
	id: number;
	naam: string;
	/** @format date-time */
	datum: string;
	/** @format int32 */
	plekken: number;
	/** @format int32 */
	bezet: number;
	/** @format double */
	bezettingVergelijking: number;
}

export interface IomodelsVestigingClubAanvoerder {
	/** @format int64 */
	vestigingId: number;
	/** @format int64 */
	coachId: number;
}

export interface IomodelsCoachLoginExtern {
	/** @format int64 */
	coachId: number;
	username: string;
	password: string;
	passwordHerhaling: string;
}

export interface IomodelsLidWeergave {
	/** @format int64 */
	id: number;
	voornaam: string;
	achternaam: string;
	telefoon?: string | null;
	email?: string | null;
	/** @format date-time */
	laatsteBezoek: string;
	app?: boolean | null;
}

export type IomodelsAbonnementenTabelFilter = any;

export interface IomodelsSpecifiekeLesBezetting {
	dag: "vrijdag" | "maandag" | "woensdag" | "donderdag" | "zaterdag" | "zondag" | "dinsdag";
	/** @format time */
	tijd: string;
	/** @format int64 */
	vestigingId: number;
	coach?: string | null;
	/** @format double */
	bezettingsPercentage: number;
}

export interface IomodelsOpkomstPercentageOverzicht {
	dezeWeek: IomodelsStatistiekWeergave;
	vorigeWeek: IomodelsStatistiekWeergave;
	gemiddeldeJaar: IomodelsStatistiekWeergave;
}

export interface IomodelsCoachTabelResult {
	/** @format int64 */
	id: number;
	naam: string;
	foto?: string | null;
	/** @format int32 */
	aantalActies: number;
	/** @format int32 */
	aantalLessen: number;
	/** @format int32 */
	aantalMetingenVerricht: number;
	/** @format int32 */
	aantalAfsprakenGedraaid: number;
	/** @format int32 */
	aantalAfsprakenIngepland: number;
	/** @format int32 */
	aantalSportersGekoppeld: number;
}

export interface IomodelsRecentheid {
	/** @format int32 */
	week: number;
	/** @format int32 */
	aantal: number;
}

export interface IomodelsCoachappLidLog {
	/** @format date-time */
	timestamp: string;
	logType: "bezoek" | "meting" | "notificatie" | "belpoging";
	opmerkingen?: string | null;
}

export interface IomodelsCoachappLogBoek {
	/** @format int64 */
	lidId: number;
	logs: IomodelsCoachappLidLog[];
}

export interface IomodelsCoachappActieVoorLid {
	actie:
		| "metingen"
		| "frequentie"
		| "groepslessen"
		| "doel"
		| "schemas"
		| "onbekende actie"
		| "bellen"
		| "app-installeren"
		| "persoonlijk-gegevens"
		| "coach-voor-lid";
	toelichting?: string | null;
}

export interface IomodelsClubLogin {
	username: string;
	password: string;
}

export interface IomodelsOvernemendeCoach {
	/** @format int64 */
	coachId: number;
}

export interface IomodelsCoachappTijdelijkLidId {
	/** @format int64 */
	tijdelijkId: number;
}

export interface IomodelsCoachappGewrichtsAandoening {
	nek: boolean;
	schouder: boolean;
	pols: boolean;
	rug: boolean;
	heup: boolean;
	knie: boolean;
	enkel: boolean;
	voet: boolean;
	description?: string | null;
}

export interface IomodelsCoachappSpierBlessures {
	nek: boolean;
	schouder: boolean;
	arm: boolean;
	rug: boolean;
	bovenbeen: boolean;
	onderbeen: boolean;
	description?: string | null;
}

export interface IomodelsCoachappIntakePersonalInfo {
	geslacht: "m" | "f" | "";
	voornaam: string;
	achternaam: string;
	/** @format date-time */
	geboortedatum?: string | null;
	email: string;
	adres?: string | null;
	plaats?: string | null;
	postcode?: string | null;
	telefoon?: string | null;
	telefoonMobiel?: string | null;
	language?: "eng" | "nld" | null;
}

export interface IomodelsCoachappIntake {
	personalInfo: IomodelsCoachappIntakePersonalInfo;
	interesses: (
		| "Sportvasten SwitchMe"
		| "Yanga sportwater"
		| "Spierballen in 8 weken"
		| "Personal Training"
		| "Zonnebank"
		| "Afvallen in 8 weken"
	)[];
	hartklachten: boolean;
	kortademigheid: boolean;
	longklachten?: string | null;
	spierBlessures?: IomodelsCoachappSpierBlessures | null;
	gewrichtsAandoening?: IomodelsCoachappGewrichtsAandoening | null;
	overigeKlachten?: string | null;
	uitgezonderdeOefeningen?: string | null;
	consultBijFysiotherapeut: boolean;
	medischCheck: boolean;
	/** @format int32 */
	doelFrequentie?: number | null;
	diensten: ("metingen" | "doel" | "schemas" | "groepslessen")[];
}

export interface IomodelsPercentage {
	omschrijving: string;
	/** @format double */
	percentage: number;
}

export interface IomodelsGroepslesWeergave {
	/** @format int64 */
	id: number;
	/** @format int32 */
	aantalGepland: number;
	/** @format int32 */
	aantalVoltooid: number;
	/** @format int32 */
	bezet: number;
	/** @format double */
	bezettingsPercentage: number;
	/** @format double */
	bezettingVergelijking: number;
}

export interface IomodelsCoachappAfgenomenMeting {
	/** @format int64 */
	metingId?: number | null;
	/** @format int64 */
	lidId: number;
	/** @format date-time */
	metingDatum?: string | null;
	/** @format double */
	bmi?: number | null;
	/** @format double */
	bovendruk?: number | null;
	/** @format double */
	onderdruk?: number | null;
	/** @format double */
	hartslag?: number | null;
	/** @format double */
	vetpercentage?: number | null;
	/** @format double */
	gewicht?: number | null;
	/** @format double */
	lengte?: number | null;
	/** @format double */
	conditie?: number | null;
	/** @format double */
	muscleMass?: number | null;
	/** @format double */
	waistCircumference?: number | null;
}

export interface IomodelsCoachappFrequentieVoorWeek {
	/** @format int32 */
	week: number;
	/** @format int32 */
	frequentie: number;
}

export interface IomodelsCoachappFrequentieGrafiek {
	/** @format int64 */
	lidId: number;
	weken: IomodelsCoachappFrequentieVoorWeek[];
}

export interface IomodelsGetalVoorCategorie {
	naam: string;
	/** @format double */
	getal: number;
}

export interface IomodelsStatistiekWeergave {
	soort: "aantal-leden" | "aantal-bezoeken";
	/** @format double */
	waarde: number;
	/** @format int64 */
	vestiging?: number | null;
	/** @format date-time */
	datum: string;
	periode: "dag" | "week" | "maand" | "jaar";
}

export interface IomodelsStatistiekCompleet {
	huidige: IomodelsStatistiekWeergave;
	vorige?: IomodelsStatistiekWeergave | null;
}

export type IomodelsStatistiekVergelijk = any;

export type IomodelsStatistiekPeriode = any;

export type IomodelsStatistiekSoort = any;

export interface IomodelsCoachResult {
	/** @format int64 */
	id: number;
	naam: string;
	vestigingenIds: number[];
	foto?: string | null;
	/** @format int64 */
	isClubAanvoerder?: number | null;
	heeftLogin: boolean;
	gebruikersRol?: "Coach" | "Club aanvoerder" | "Admin" | null;
}

export interface IomodelsCoachappLedenToewijzen {
	ledenIds: number[];
}

export interface IomodelsCoachappMijnCoachStatistieken {
	/** @format int32 */
	aantalLedenUit30GroepGeweest: number;
	/** @format int32 */
	aantalLedenUit30GroepGeholpen: number;
}

export interface DaosModelsBelpoging {
	/** @format date-time */
	timestamp: string;
	/** @format int64 */
	lidId: number;
	/** @format int64 */
	coachId: number;
	lidGesproken: boolean;
	besproken?: string | null;
}

export interface IomodelsAanvoerderInfo {
	/** @format int64 */
	trainerId: number;
	naam: string;
	foto?: string | null;
}

export interface IomodelsVestigingInfo {
	/** @format int64 */
	vestigingId: number;
	naam: string;
	aanvoerder?: IomodelsAanvoerderInfo | null;
	/** @format date-time */
	opening?: string | null;
}

export interface IomodelsJarenLid {
	/** @format int32 */
	aantalJaar: number;
	/** @format double */
	percentage: number;
}

export interface IomodelsCoachappDoelFrequentie {
	/** @format int64 */
	lidId: number;
	/** @format int32 */
	doelfrequentie?: number | null;
	doelFrequentieOpgegeven: boolean;
}

export interface IomodelsLidVoorFeed {
	/** @format int64 */
	id: number;
	voornaam: string;
	achternaam: string;
	profileImg: string;
	/** @format date-time */
	timestamp: string;
	priority: boolean;
	message: string;
	icon: string;
	link: string;
	/** @format int64 */
	coachLid?: number | null;
	coachingsGroep?: "30%" | "70%" | null;
	coachNaam?: string | null;
	geholpen: boolean;
}

export interface IomodelsMaandelijksTarget {
	/** @format int64 */
	vestigingId: number;
	/** @format int32 */
	maand: number;
	/** @format int32 */
	jaar: number;
	/** @format int32 */
	waarde: number;
}

export interface IomodelsAanwezigheidFitnessAfspraakExtern {
	/** @format int64 */
	lidId: number;
	lidNaam?: string | null;
	status: "afgemeld" | "aangemeld" | "geweest" | "niet_geweest";
	meting: boolean;
	trainingsSchema: boolean;
	doelOpstellen: boolean;
	personalTraining: boolean;
	kickOff: boolean;
	opmerkingen?: string | null;
}

export interface IomodelsFitnessAfspraakExtern {
	/** @format int64 */
	id: number;
	/** @format int64 */
	roosterItemId: number;
	/** @format int64 */
	vestigingId: number;
	/** @format int64 */
	trainerId?: number | null;
	/** @format int32 */
	week: number;
	status: "aankomend" | "afgelopen" | "geannuleerd";
	/** @format date-time */
	datum: string;
	/** @format int32 */
	duur?: number | null;
	ingediend: boolean;
	aanwezigheid?: IomodelsAanwezigheidFitnessAfspraakExtern | null;
}

export interface IomodelsCoachappLidBezoek {
	/** @format date-time */
	timestamp: string;
	groepsles: boolean;
	meting: boolean;
	schema: boolean;
}

export interface IomodelsAccessToken {
	access_token: string;
	/** @format int64 */
	expires_in?: number | null;
	scope?: string | null;
	refresh_token?: string | null;
	token_type: string;
}

export interface IomodelsCoachappLidStatusVoorSlug {
	slug: string;
	status: "ok" | "warning" | "not-ok" | "inactive";
	priority: boolean;
}

export interface IomodelsCoachappLidMetPrioInfo {
	/** @format int64 */
	id: number;
	voornaam: string;
	achternaam: string;
	geslacht: string;
	profileImg: string;
	/** @format date-time */
	geboorteDatum?: string | null;
	tijdelijk: boolean;
	/** @format int64 */
	vestiging?: number | null;
	sections: IomodelsCoachappLidStatusVoorSlug[];
	/** @format int32 */
	doelFrequentie?: number | null;
}

export interface IomodelsCoachappLidInfo {
	voornaam: string;
	achternaam: string;
	geslacht: string;
	/** @format date-time */
	geboortedatum?: string | null;
	adres?: string | null;
	postcode?: string | null;
	stad?: string | null;
	telefoon?: string | null;
	email?: string | null;
	/** @format int64 */
	coachLid?: number | null;
	coachingsGroep?: "30%" | "70%" | null;
	onboardingAfgerond: boolean;
	telefoonMobiel?: string | null;
}

export interface IomodelsHeatMapLes {
	/** @format int64 */
	id: number;
	/** @format int64 */
	trainerId: number;
	/** @format int64 */
	roosterItemId: number;
	status: string;
	/** @format date-time */
	datum: string;
	/** @format double */
	bezettingsPercentage: number;
}

export interface IomodelsCoachappPersoonlijkeGegevens {
	/** @format int64 */
	lidId: number;
	voornaam: string;
	achternaam: string;
	adres?: string | null;
	email: string;
	/** @format date-time */
	geboortedatum?: string | null;
	geslacht: "m" | "f" | "";
	plaats?: string | null;
	postcode?: string | null;
	telefoon?: string | null;
	/** @format int32 */
	doelFrequentie?: number | null;
	telefoonMobiel?: string | null;
	language?: "eng" | "nld" | null;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
	/** Set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** Request path */
	path: string;
	/** Content type of request body */
	type?: ContentType;
	/** Query params */
	query?: QueryParamsType;
	/** Format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** Request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig,
	): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
					{}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === "object" && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		if (input instanceof FormData) {
			return input;
		}
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type ? { "Content-Type": type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @version 0.1-5424da640e5a4282ee0b6a1364a1d6c2e1975cdb
 *
 *   Backend voor club app
 * @title Club backend API
 */
export class ClubApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	/**
	 * No description
	 *
	 * @tags routes
	 * @name Index
	 * @summary API health opvragen
	 * @request GET:/
	 */
	index = (params: RequestParams = {}) =>
		this.request<void, void>({
			path: `/`,
			method: "GET",
			...params,
		});

	login = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name AccessTokenRefresh
		 * @summary Ophalen van een nieuw accesstoken adhv het refresh token
		 * @request POST:/login/refresh
		 */
		accessTokenRefresh: (refreshToken: IomodelsRefreshToken, params: RequestParams = {}) =>
			this.request<IomodelsAccessToken, void>({
				path: `/login/refresh`,
				method: "POST",
				body: refreshToken,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * Inloggen op de club als admin of clubaanvoerder
		 *
		 * @tags routes
		 * @name AccessToken
		 * @summary Aanvragen voor een access token m.b.v. username/password
		 * @request POST:/login
		 */
		accessToken: (clubLogin: IomodelsClubLogin, params: RequestParams = {}) =>
			this.request<IomodelsAccessToken, void>({
				path: `/login`,
				method: "POST",
				body: clubLogin,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	coachLogin = {
		/**
		 * Inloggen als coach
		 *
		 * @tags routes
		 * @name AccessTokenCoach
		 * @summary Aanvragen voor een access token m.b.v. username/password
		 * @request POST:/coach-login
		 */
		accessTokenCoach: (clubLogin: IomodelsClubLogin, params: RequestParams = {}) =>
			this.request<IomodelsAccessToken, void>({
				path: `/coach-login`,
				method: "POST",
				body: clubLogin,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name AccessTokenCoachRefresh
		 * @summary Ophalen van een nieuw accesstoken adhv het refresh token voor een coach
		 * @request POST:/coach-login/refresh
		 */
		accessTokenCoachRefresh: (refreshToken: IomodelsRefreshToken, params: RequestParams = {}) =>
			this.request<IomodelsAccessToken, void>({
				path: `/coach-login/refresh`,
				method: "POST",
				body: refreshToken,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	oauth2 = {
		/**
		 * Afhankelijk van de geidentificeerde gebruiker worden er een aantal scopes terug gestuurd.
		 * Deze bepalen toegang tot de functies: * een statische applicatie heeft altijd alleen de scope
		 * `applicatie` * een trainer heeft altijd de scopes `trainer` en `lid` * een club aanvoerder
		 * heeft altijd de scopes `club_aanvoerder`, `trainer` en `lid`
		 *
		 * @tags routes
		 * @name ProxyAccessToken
		 * @summary access token opvragen middels normale OAuth2 parameters
		 * @request POST:/oauth2/access_token
		 */
		proxyAccessToken: (
			data: {
				/** Type request, ondersteunt of `client_credentials`, `refresh_token` of `password` */
				grant_type: "authorization_code" | "refresh_token" | "password";
				/** Het client-id */
				client_id: string;
				/** Het client-secret */
				client_secret: string;
				/** Geldig refresh token (verplicht indien `grant_type` is `refresh_token`) */
				refresh_token?: string;
				/** De gebruikersnaam (verplicht indien `grant_type` is `password`) */
				username?: string;
				/** Bijbehorende wachtwoord (verplicht indien `grant_type` is `password`) */
				password?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsAccessToken, void>({
				path: `/oauth2/access_token`,
				method: "POST",
				body: data,
				type: ContentType.UrlEncoded,
				format: "json",
				...params,
			}),
	};
	coaches = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name AlleTrainers
		 * @summary Alle coaches met hun info ophalen
		 * @request GET:/coaches
		 */
		alleTrainers: (params: RequestParams = {}) =>
			this.request<IomodelsCoachResult[], void>({
				path: `/coaches`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name CoachesTabel
		 * @summary levert de coaches op voor om een tabel mee te kunnen maken; per coach zijn de basisgevevens en basisstatistieken bekend
		 * @request GET:/coaches/tabel/{periode}
		 */
		coachesTabel: (
			periode: IomodelsStatistiekPeriode,
			query?: {
				/**
				 * Het aantal dagen wat in een variabele periode zit (verplicht indien `periode` `variabel`
				 * is)
				 *
				 * @format int32
				 */
				dagen?: number | null;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsCoachTabelResult[], any>({
				path: `/coaches/tabel/${periode}`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	coach = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name CoachVerwijderen
		 * @summary Het "verwijderen" van een coach. Dit verwijdert de coachlogin + voert een vervanger door voor de leden die deze coach als coach toegewezen hebben
		 * @request POST:/coach/{coachId}/verwijderen
		 */
		coachVerwijderen: (
			coachId: number,
			overnemendeCoach: IomodelsOvernemendeCoach,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/coach/${coachId}/verwijderen`,
				method: "POST",
				body: overnemendeCoach,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name LedenToewijzen
		 * @summary update de aangewezen coach voor alle meegegeven leden naar betreffende coachId
		 * @request POST:/coach/{coachId}/leden-toewijzen
		 */
		ledenToewijzen: (
			coachId: number,
			ledenIds: IomodelsCoachappLedenToewijzen,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/coach/${coachId}/leden-toewijzen`,
				method: "POST",
				body: ledenIds,
				type: ContentType.Json,
				...params,
			}),
	};
	ingelogd = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name Ingelogd
		 * @summary Gegevens van de ingelogde gebruiker ophalen
		 * @request GET:/ingelogd
		 */
		ingelogd: (params: RequestParams = {}) =>
			this.request<IomodelsIngelogdeGebruiker, any>({
				path: `/ingelogd`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	coachlogin = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name PostCoachLogin
		 * @summary Aanmaken of wijzigen van een login voor een coach
		 * @request POST:/coachlogin
		 */
		postCoachLogin: (coachLogin: IomodelsCoachLoginExtern, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/coachlogin`,
				method: "POST",
				body: coachLogin,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name DeleteCoachLogin
		 * @summary CoachLogin verwijderen van coach met meegegeven id
		 * @request DELETE:/coachlogin/{coachId}
		 */
		deleteCoachLogin: (coachId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/coachlogin/${coachId}`,
				method: "DELETE",
				...params,
			}),
	};
	vestiging = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name MaakAanvoerder
		 * @summary Een coach clubaanvoerder maken. Dit vervangt een eventueel eerdere clubaanvoerder.
		 * @request POST:/vestiging/aanvoerder
		 */
		maakAanvoerder: (
			vestigingClubAanvoerder: IomodelsVestigingClubAanvoerder,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/vestiging/aanvoerder`,
				method: "POST",
				body: vestigingClubAanvoerder,
				type: ContentType.Json,
				...params,
			}),
	};
	vestigingen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name AlleVestigingen
		 * @summary Alle vestigingen met hun club aanvoerders ophalen
		 * @request GET:/vestigingen
		 */
		alleVestigingen: (params: RequestParams = {}) =>
			this.request<IomodelsVestigingResult[], any>({
				path: `/vestigingen`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	vestigingenOverzicht = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name Overzicht
		 * @summary overzicht van alle vestigingen ophalen met hun club-aanvoerder informatie
		 * @request GET:/vestigingen-overzicht
		 */
		overzicht: (params: RequestParams = {}) =>
			this.request<IomodelsVestigingInfo[], any>({
				path: `/vestigingen-overzicht`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	statistieken = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetStatistiekWaardeMetPeriode
		 * @summary een statistiek waarde ophalen met een specifieke periode
		 * @request GET:/statistieken/{soort}/{periode}
		 */
		getStatistiekWaardeMetPeriode: (
			soort: IomodelsStatistiekSoort,
			periode: IomodelsStatistiekPeriode,
			query?: {
				/** Optionele parameter om ook de waarde voor een vorige periode op te halen */
				vergelijkMet?: IomodelsStatistiekVergelijk | null;
				/**
				 * De datum om de waarde van op te halen (format `dd-mm-yyyy`), default is huidige datum
				 *
				 * @format date-time
				 */
				datum?: string | null;
				/**
				 * Het aantal dagen wat in een variabele periode zit (verplicht indien `periode` `variabel`
				 * is)
				 *
				 * @format int32
				 */
				dagen?: number | null;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsStatistiekCompleet, any>({
				path: `/statistieken/${soort}/${periode}`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetStatistiekGroei
		 * @summary een statistiek 'groei' ophalen voor een bepaalde soort. De groei is het verschil tussen 2 periode voor dezelfde statistiek in percentage. Tevens geeft deze functie voor de gevraagde groei ook de gemiddelde groei van de statistiek over de andere vestigingen.
		 * @request GET:/statistieken/{soort}/{periode}/{vergelijkMet}
		 */
		getStatistiekGroei: (
			soort: IomodelsStatistiekSoort,
			periode: IomodelsStatistiekPeriode,
			vergelijkMet: IomodelsStatistiekVergelijk,
			query?: {
				/**
				 * De datum om de waarde van op te halen (format `dd-mm-yyyy`), default is huidige datum
				 *
				 * @format date-time
				 */
				datum?: string | null;
				/**
				 * Het aantal dagen wat in een variabele periode zit (verplicht indien `periode` `variabel`
				 * is)
				 *
				 * @format int32
				 */
				dagen?: number | null;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsStatistiekGroei, any>({
				path: `/statistieken/${soort}/${periode}/${vergelijkMet}`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name KpiGrafiek
		 * @summary een statistiek grafiek ophalen voor een bepaald statistiek en periode
		 * @request GET:/statistieken/grafiek/{soort}/{periode}/{vergelijkMet}
		 */
		kpiGrafiek: (
			soort: IomodelsStatistiekSoort,
			periode: IomodelsStatistiekPeriode,
			vergelijkMet: IomodelsStatistiekVergelijk,
			query?: {
				/**
				 * De datum om de waarde van op te halen (format `dd-mm-yyyy`), default is huidige datum
				 *
				 * @format date-time
				 */
				datum?: string | null;
				/**
				 * Het aantal dagen wat in een variabele periode zit (verplicht indien `periode` `variabel`
				 * is)
				 *
				 * @format int32
				 */
				dagen?: number | null;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsStatistiekGrafiek, void>({
				path: `/statistieken/grafiek/${soort}/${periode}/${vergelijkMet}`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	dashboard = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name BezoekenPerUur
		 * @summary de bezoeken per uur ophalen voor vandaag en ook de gegevens voor de meegegeven vergelijk periode ophalen
		 * @request GET:/dashboard/bezoeken-per-uur/{vergelijkMet}
		 */
		bezoekenPerUur: (
			vergelijkMet: IomodelsStatistiekVergelijk,
			query?: {
				/**
				 * De datum om de waarde van op te halen (format `dd-mm-yyyy`), default is huidige datum
				 *
				 * @format date-time
				 */
				datum?: string | null;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsBezoekenResultaat, any>({
				path: `/dashboard/bezoeken-per-uur/${vergelijkMet}`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	leden = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GemiddeldeOpkomst
		 * @summary de gemiddelde opkomst over het gehele jaar voor de vestiging ophalen. Dit is de gemiddelde opkomst over de weken in het huidige jaar behalve de huidige, lopende, week
		 * @request GET:/leden/gemiddelde-opkomst
		 */
		gemiddeldeOpkomst: (params: RequestParams = {}) =>
			this.request<IomodelsOpkomstPercentageOverzicht, any>({
				path: `/leden/gemiddelde-opkomst`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name Recentheid
		 * @summary de recentheid ophalen; per week (huidige t/m 10 weken terug) per week het aantal leden wat sinds die week niet meer is komen opdagen bepalen
		 * @request GET:/leden/recentheid
		 */
		recentheid: (params: RequestParams = {}) =>
			this.request<IomodelsRecentheid, any>({
				path: `/leden/recentheid`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name RecentheidTabel
		 * @summary de leden die sinds x weken geleden niet meer zijn geweest ophalen
		 * @request GET:/leden/recentheid-tabel/{week}
		 */
		recentheidTabel: (week: number, params: RequestParams = {}) =>
			this.request<IomodelsRecentheid, any>({
				path: `/leden/recentheid-tabel/${week}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerdelingAantalJarenLid
		 * @summary bepaalt de verdeling van aantal jaren lid van de actieve leden. In percentages.
		 * @request GET:/leden/aantal-jaar-lid
		 */
		verdelingAantalJarenLid: (params: RequestParams = {}) =>
			this.request<IomodelsJarenLid[], any>({
				path: `/leden/aantal-jaar-lid`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerdelingGeslacht
		 * @summary Haal de percentages per geslacht van huidige leden op
		 * @request GET:/leden/verdeling-geslacht
		 */
		verdelingGeslacht: (params: RequestParams = {}) =>
			this.request<IomodelsPercentage[], any>({
				path: `/leden/verdeling-geslacht`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerdelingLeeftijd
		 * @summary Haal de percentages voor de leeftijd categorien van de actieve leden op
		 * @request GET:/leden/verdeling-leeftijd
		 */
		verdelingLeeftijd: (params: RequestParams = {}) =>
			this.request<IomodelsPercentage[], any>({
				path: `/leden/verdeling-leeftijd`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name OverviewVoorCoachApp
		 * @summary Haal leden voor CoachApp op adhv meegegeven filter. Er kan gefilterd worden op zoeken op naam, coach, coachinggroep of vestiging
		 * @request POST:/leden/overview
		 */
		overviewVoorCoachApp: (OverviewFilter: IomodelsOverviewFilter, params: RequestParams = {}) =>
			this.request<IomodelsCoachLedenOverviewExtern, void>({
				path: `/leden/overview`,
				method: "POST",
				body: OverviewFilter,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	lessen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name LaatsteLessenTabel
		 * @summary bepaalt de laatste-lessen tabel voor de gegeven periode. Dit is het overzicht van de laatst gegeven les per type groepsles met wat statistieken
		 * @request GET:/lessen/tabel/{periode}
		 */
		laatsteLessenTabel: (
			periode: IomodelsStatistiekPeriode,
			query?: {
				/**
				 * Het aantal dagen wat in een variabele periode zit (verplicht indien `periode` `variabel`
				 * is)
				 *
				 * @format int32
				 */
				dagen?: number | null;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsLaatsteLesWeergave, any>({
				path: `/lessen/tabel/${periode}`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	groepslessen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GroepslesStatistieken
		 * @summary bepaalt van een groepsles wat basis statistieken zoals hoeveelheid lessen en bezettingen
		 * @request GET:/groepslessen/{groepslesId}/statistieken
		 */
		groepslesStatistieken: (groepslesId: number, params: RequestParams = {}) =>
			this.request<IomodelsGroepslesWeergave, any>({
				path: `/groepslessen/${groepslesId}/statistieken`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GemiddeldeBezettingAfgelopen4Weken
		 * @summary Voor de betreffende groepsles haal gemiddeld bezettingspercentage op over afgelopen 4 weken
		 * @request GET:/groepslessen/{groepslesId}/bezetting
		 */
		gemiddeldeBezettingAfgelopen4Weken: (groepslesId: number, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/groepslessen/${groepslesId}/bezetting`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name BezettingperLesAfgelopen4Weken
		 * @summary Voor de betreffende groepsles haal de gemiddelde bezetting vd afgelopen 4 weken per roosteritem op (dus gegroepeerd per dag+tijd+coach+vestiging)
		 * @request GET:/groepslessen/{groepslesId}/bezettingperles
		 */
		bezettingperLesAfgelopen4Weken: (groepslesId: number, params: RequestParams = {}) =>
			this.request<IomodelsSpecifiekeLesBezetting[], any>({
				path: `/groepslessen/${groepslesId}/bezettingperles`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	heatmap = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name HeatMap
		 * @summary Haal voor de betreffende week alle lessen op met bijbehorende lesbezetting
		 * @request GET:/heatmap/week/{weekNr}/jaar/{jaartal}
		 */
		heatMap: (weekNr: number, jaartal: number, params: RequestParams = {}) =>
			this.request<IomodelsHeatMapLes[], any>({
				path: `/heatmap/week/${weekNr}/jaar/${jaartal}`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	lidmaatschappen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name AflopendeAbonnementenTabel
		 * @summary bepaalt de tabel van leden met een aflopend abonnement; type abonnement is bepaalt door het mee gegeven filter
		 * @request GET:/lidmaatschappen/aflopende-abonmenenten/{filter}
		 */
		aflopendeAbonnementenTabel: (
			filter: IomodelsAbonnementenTabelFilter,
			params: RequestParams = {},
		) =>
			this.request<IomodelsLidWeergave[], any>({
				path: `/lidmaatschappen/aflopende-abonmenenten/${filter}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerdelingAbonnementen
		 * @summary bepaalt de verdeling van types abonnementen van de actieve leden. In percentages. De mogelijke types abonnementen komen uit Logifit
		 * @request GET:/lidmaatschappen/verdeling-abonnementen
		 */
		verdelingAbonnementen: (params: RequestParams = {}) =>
			this.request<IomodelsGetalVoorCategorie[], any>({
				path: `/lidmaatschappen/verdeling-abonnementen`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerdelingArrangementen
		 * @summary bepaalt de verdeling van arrangementen van de actieve leden. In percentages. De mogelijke types arrangementen komen uit Logifit
		 * @request GET:/lidmaatschappen/verdeling-arrangementen
		 */
		verdelingArrangementen: (params: RequestParams = {}) =>
			this.request<IomodelsGetalVoorCategorie[], any>({
				path: `/lidmaatschappen/verdeling-arrangementen`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name AantallenVoorAboExtras
		 * @summary bepaalt het aantal actieve leden voor de diverse extras die er zijn voor de abonnementen. In gehele getallen.
		 * @request GET:/lidmaatschappen/extras
		 */
		aantallenVoorAboExtras: (params: RequestParams = {}) =>
			this.request<IomodelsGetalVoorCategorie[], any>({
				path: `/lidmaatschappen/extras`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	aanmeldingtargets = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetAanmeldingTargets
		 * @summary levert alle aanmelding targets (deze zijn per vestiging per maand) op voor het meegegeven jaar
		 * @request GET:/aanmeldingtargets/{jaar}
		 */
		getAanmeldingTargets: (jaar: number, params: RequestParams = {}) =>
			this.request<IomodelsMaandelijksTarget[], any>({
				path: `/aanmeldingtargets/${jaar}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name UpdateAanmeldingTargets
		 * @summary Aanmelding targets toevoegen/updaten
		 * @request POST:/aanmeldingtargets
		 */
		updateAanmeldingTargets: (
			aanmeldingTargets: IomodelsMaandelijksTarget,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/aanmeldingtargets`,
				method: "POST",
				body: aanmeldingTargets,
				type: ContentType.Json,
				...params,
			}),
	};
	afmeldingtargets = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetAfmeldingTargets
		 * @summary levert alle afmelding targets (deze zijn per vestiging per maand) op voor het meegegeven jaar
		 * @request GET:/afmeldingtargets/{jaar}
		 */
		getAfmeldingTargets: (jaar: number, params: RequestParams = {}) =>
			this.request<IomodelsMaandelijksTarget[], any>({
				path: `/afmeldingtargets/${jaar}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name UpdateAfmeldingTargets
		 * @summary Afmelding targets toevoegen/updaten
		 * @request POST:/afmeldingtargets
		 */
		updateAfmeldingTargets: (
			aanmeldingTargets: IomodelsMaandelijksTarget,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/afmeldingtargets`,
				method: "POST",
				body: aanmeldingTargets,
				type: ContentType.Json,
				...params,
			}),
	};
	tijdslotenHeatmap = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name TijdslotenHeatMap
		 * @summary Haal voor de betreffende week alle tijdsloten op met bijbehorende bezetting
		 * @request GET:/tijdsloten-heatmap/week/{weekNr}/jaar/{jaartal}
		 */
		tijdslotenHeatMap: (weekNr: number, jaartal: number, params: RequestParams = {}) =>
			this.request<DaosModelsCoronaTijdslot[], any>({
				path: `/tijdsloten-heatmap/week/${weekNr}/jaar/${jaartal}`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	lid = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name CoachVoorLidWijzigen
		 * @summary Update de coach voor het betreffende lid naar meegegeven coachId. Overschrijft een eventuele eerdere aangewezen coach.
		 * @request POST:/lid/{lidId}/coach/{coachId}
		 */
		coachVoorLidWijzigen: (lidId: number, coachId: number, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/lid/${lidId}/coach/${coachId}`,
				method: "POST",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name CoachVoorLidVerwijderen
		 * @summary Verwijder de coach voor betreffende lid, zodat deze geen aangewezen coach meer heeft.
		 * @request POST:/lid/{lidId}/verwijder-coach
		 */
		coachVoorLidVerwijderen: (lidId: number, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/lid/${lidId}/verwijder-coach`,
				method: "POST",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GroepVoorLidWijzigen
		 * @summary Update de groep voor het betreffende lid naar meegegeven groep. Overschrijft een eventuele eerdere aangewezen groep.
		 * @request POST:/lid/{lidId}/groep/{groep}
		 */
		groepVoorLidWijzigen: (lidId: number, groep: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/lid/${lidId}/groep/${groep}`,
				method: "POST",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetLidMetStatus
		 * @summary Haal user info met prio info voor alle secties op
		 * @request GET:/lid/{lidId}
		 */
		getLidMetStatus: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsCoachappLidMetPrioInfo, void>({
				path: `/lid/${lidId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetLogsVoorLid
		 * @summary Haal logboek (bezoeken, metingen, notificaties en belpogingen) voor lid op
		 * @request POST:/lid/{lidId}/logs
		 */
		getLogsVoorLid: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsCoachappLogBoek, any>({
				path: `/lid/${lidId}/logs`,
				method: "POST",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name PostPersoonlijkeGegevens
		 * @summary Post persoonsgegevens voor lid
		 * @request POST:/lid/persoonlijke-gegevens
		 */
		postPersoonlijkeGegevens: (
			belpoging: IomodelsCoachappPersoonlijkeGegevens,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/lid/persoonlijke-gegevens`,
				method: "POST",
				body: belpoging,
				type: ContentType.Json,
				...params,
			}),
	};
	liveFeed = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name LiveFeedVoorCoachApp
		 * @summary Leden die nu aanwezig zijn in de meegegeven vestiging met hun status
		 * @request GET:/live-feed
		 */
		liveFeedVoorCoachApp: (params: RequestParams = {}) =>
			this.request<IomodelsLidVoorFeed[], any>({
				path: `/live-feed`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	coachDashboard = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetMijnStatistieken
		 * @summary Haal "Mijn Statistieken" op voor een coach. Deze bestaan uit (1) aantal leden die vandaag in meegegeven vestiging zijn geweest en in de 30% groep vallen en (2) aantal verschillende leden die coach vandaag heeft geholpen
		 * @request GET:/coach-dashboard/mijn-statistieken/{coachId}
		 */
		getMijnStatistieken: (coachId: number, params: RequestParams = {}) =>
			this.request<IomodelsCoachappMijnCoachStatistieken, void>({
				path: `/coach-dashboard/mijn-statistieken/${coachId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetAlgemeneStatistieken
		 * @summary Haal "Algemene Statistieken" op voor een coach. Deze bestaan uit (1) Aantal 30% sporters van de coach, (2) Aantal 70% sporters van de coach en (3) totaal aantal sporters van de coach (ook die zonder groep)
		 * @request GET:/coach-dashboard/algemene-statistieken/{coachId}
		 */
		getAlgemeneStatistieken: (coachId: number, params: RequestParams = {}) =>
			this.request<IomodelsCoachappAlgemeneCoachStatistieken, void>({
				path: `/coach-dashboard/algemene-statistieken/${coachId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetAanwezigeLedenZonderCoach
		 * @summary Haal leden op die aanwezig zijn in de betreffende locatie, geen coach hebben en in de 30% groep zitten
		 * @request GET:/coach-dashboard/aanwezige-leden-zonder-coach
		 */
		getAanwezigeLedenZonderCoach: (params: RequestParams = {}) =>
			this.request<IomodelsLidVoorFeed[], any>({
				path: `/coach-dashboard/aanwezige-leden-zonder-coach`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetMijnAanwezigeLeden
		 * @summary Haal leden op die aanwezig zijn in de betreffende locatie, de meegegeven coach als coach hebben en in de 30% groep zitten
		 * @request GET:/coach-dashboard/mijn-aanwezige-leden/{coachId}
		 */
		getMijnAanwezigeLeden: (coachId: number, params: RequestParams = {}) =>
			this.request<IomodelsLidVoorFeed[], void>({
				path: `/coach-dashboard/mijn-aanwezige-leden/${coachId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetMijnLangAfwezigeLeden
		 * @summary Haal leden op al lang niet in de club zijn geweest en de meegegeven coach als coach hebben
		 * @request GET:/coach-dashboard/mijn-leden-lang-afwezig
		 */
		getMijnLangAfwezigeLeden: (
			query: {
				/**
				 * Id van de coach waarvoor de aanwezige leden opgehaald worden
				 *
				 * @format int64
				 */
				coachId: number;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsLidVoorFeed[], void>({
				path: `/coach-dashboard/mijn-leden-lang-afwezig`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetMijnAfspraken
		 * @summary Haal aankomende afspraken voor vandaag op voor coach
		 * @request GET:/coach-dashboard/mijn-afspraken
		 */
		getMijnAfspraken: (
			query: {
				/**
				 * Id van de coach waarvoor de aanwezige leden opgehaald worden
				 *
				 * @format int64
				 */
				coachId: number;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsFitnessAfspraakExtern[], void>({
				path: `/coach-dashboard/mijn-afspraken`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	sportersDashboard = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name DoelFrequentie
		 * @summary Haal de doelfrequentie voor een lid op, indien deze is opgegeven
		 * @request GET:/sporters-dashboard/doel-frequentie/{lidId}
		 */
		doelFrequentie: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsCoachappDoelFrequentie, any>({
				path: `/sporters-dashboard/doel-frequentie/${lidId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name PostBelpoging
		 * @summary Post een belpoging van een coach naar een lid. Alleen uit te voeren door een coach voor zichzelf.
		 * @request POST:/sporters-dashboard/belpoging
		 */
		postBelpoging: (belpoging: DaosModelsBelpoging, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/sporters-dashboard/belpoging`,
				method: "POST",
				body: belpoging,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name Belpogingen
		 * @summary Haal alle belpogingen voor het betreffende lid op
		 * @request GET:/sporters-dashboard/{lidId}/belpogingen
		 */
		belpogingen: (lidId: number, params: RequestParams = {}) =>
			this.request<DaosModelsBelpoging[], any>({
				path: `/sporters-dashboard/${lidId}/belpogingen`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetActies
		 * @summary Haal acties voor het lid op.
		 * @request GET:/sporters-dashboard/{lidId}/acties
		 */
		getActies: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsCoachappActieVoorLid[], any>({
				path: `/sporters-dashboard/${lidId}/acties`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetLidInfo
		 * @summary Haal persoonlijke info over het lid op
		 * @request GET:/sporters-dashboard/{lidId}/lid-info
		 */
		getLidInfo: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsCoachappLidInfo, any>({
				path: `/sporters-dashboard/${lidId}/lid-info`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetAankomendeAfspraak
		 * @summary Haal de afspraken op die het lid heeft gereserveerd en nog aankomend zijn
		 * @request GET:/sporters-dashboard/{lidId}/aankomende-afspraken
		 */
		getAankomendeAfspraak: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsCoachappFitnessAfspraakVoorLid[], any>({
				path: `/sporters-dashboard/${lidId}/aankomende-afspraken`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetFrequentieGrafiek
		 * @summary Haal de frequentiegrafiek op voor het betreffende lid
		 * @request GET:/sporters-dashboard/{lidId}/frequentie-grafiek
		 */
		getFrequentieGrafiek: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsCoachappFrequentieGrafiek, any>({
				path: `/sporters-dashboard/${lidId}/frequentie-grafiek`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetBezoeken
		 * @summary Haal de bezoeken van de afgelopen 3 maanden op met de info wat er tijdens het bezoek gedaan is (meting, groepsles, schema)
		 * @request GET:/sporters-dashboard/{lidId}/bezoeken
		 */
		getBezoeken: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsCoachappLidBezoek[], any>({
				path: `/sporters-dashboard/${lidId}/bezoeken`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	members = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetMemberAppointments
		 * @summary Haal de afspraken op die het lid heeft gereserveerd en nog aankomend zijn
		 * @request GET:/members/{memberId}/appointments
		 */
		getMemberAppointments: (
			memberId: number,
			query?: {
				/** @format date-time */
				datum_equal?: string | null;
				/** @format date-time */
				datum_gte?: string | null;
				/** @format date-time */
				datum_lte?: string | null;
				/** @format date-time */
				datum_gt?: string | null;
				/** @format date-time */
				datum_lt?: string | null;
				/**
				 * Number of the records (default 10)
				 *
				 * @format int32
				 */
				limit?: number | null;
				/** OrderBy datum */
				orderby_datum?: "asc" | "desc";
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsCoachappFitnessAfspraakVoorLid[], any>({
				path: `/members/${memberId}/appointments`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	intake = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name PostIntake
		 * @summary Post intake
		 * @request POST:/intake
		 */
		postIntake: (intale: IomodelsCoachappIntake, params: RequestParams = {}) =>
			this.request<IomodelsCoachappTijdelijkLidId, any>({
				path: `/intake`,
				method: "POST",
				body: intale,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	meting = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name PostMeting
		 * @summary Nieuwe meting toevoegen of bestaande meting updaten
		 * @request POST:/meting
		 */
		postMeting: (meting: IomodelsCoachappAfgenomenMeting, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/meting`,
				method: "POST",
				body: meting,
				type: ContentType.Json,
				...params,
			}),
	};
	tracking = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetTracking
		 * @summary Haal tracking info voor dit lid op
		 * @request GET:/tracking/{lidId}
		 */
		getTracking: (lidId: number, params: RequestParams = {}) =>
			this.request<DaosModelsMemberTracking, void>({
				path: `/tracking/${lidId}`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
}
