









































































































import { type PropType, defineComponent } from "@vue/composition-api";
import { coreApi } from "@/lib/backend";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { toast } from "@/lib/vue2-sonner";
import { dagMaandJaar } from "@/utils/date";

export default defineComponent({
	props: {
		data: {
			type: Object as PropType<{
				les: {
					les_id: number;
					groepsles_naam: string;
					date: string;
					tijd: string;
					vestiging_naam: string;
					zaal_naam: string;
					herhalend: boolean;
				};
				lid: { lidId: number; lidNaam: string };
				lidId: number;
			}>,
			default: () => ({}),
		},
		callback: {
			type: Function as PropType<() => void | Promise<void>>,
			default: () => {
				return;
			},
		},
	},
	data() {
		return {
			message: "",
			error: false,
			herhalend: false,
		};
	},
	methods: {
		async afmelden() {
			await toast
				.promise(
					coreApi.lessons.lessonControllerSignOff(this.data.les.les_id, {
						memberId: this.data.lid.lidId,
						recurring: this.herhalend,
					}),
					{
						loading: "Lid afmelden...",
						success: () => {
							this.callback();
							this.message = "Lid succesvol afgemeld";
							return this.message;
						},
						error: (error: unknown) => {
							this.error = true;
							this.message = formatErrorMessage(error);
							return this.message;
						},
					},
				)
				?.unwrap();
		},
		dagMaandJaar,
	},
});
