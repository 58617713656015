import type { UseQueryOptions } from "@tanstack/vue-query";
import type { MaybeRef } from "@tanstack/vue-query/build/lib/types";
import { minutesToMilliseconds } from "date-fns";
import { coreApi } from "@/lib/backend";
import { getCoachName } from "@/lib/getCoachName";

const apiCall = coreApi.coaches.coachControllerFindAll;
type ApiCall = typeof apiCall;
type Data = Awaited<ReturnType<ApiCall>>["data"]["data"];

export function queryOptions_coaches(
	query?: MaybeRef<Parameters<ApiCall>[0]>,
): UseQueryOptions<Data, unknown, Data, readonly ["coaches", typeof query]> {
	return {
		queryKey: ["coaches", query] as const,
		queryFn: async (context) =>
			await apiCall(context.queryKey[1], { signal: context.signal }).then(
				(response) => response.data.data,
			),
		select: (data) => data.sort((a, b) => getCoachName(a).localeCompare(getCoachName(b))),
		placeholderData: [] as Data,
		cacheTime: Infinity,
		staleTime: minutesToMilliseconds(60),
	};
}
