<template>
	<div>
		<header class="pfg-header">
			<div class="pfg-header_heading">
				<h1 class="s_elem-has-icon-left a_fill_default">
					Oeps.. Proefsporter is al bekend
					<pfg-icon icon-id="icon_groepslessen" />
				</h1>
			</div>
		</header>

		<hr class="v_less-margin" />
		<div class="a_padding-20 a_bg_brand_white">
			<div class="pfg-content_flex">
				<div class="pfg-content_1-2">
					De sporter heeft al eerder proefsporten gedaan (proefles of proef fitness) en mag daarom
					niet nog een keer proefsporten.
					<br />
					<br />
					De sporter kan een abonnement afsluiten of een dagpas aanschaffen voor €10,- via

					<a
						:href="buyDayPassLink"
						target="_blank"
						rel="noopener noreferrer"
						style="text-decoration: underline"
						>{{ buyDayPassLink }}</a
					>
					<copy-to-clip-board :text="buyDayPassLink" :link="buyDayPassLink" />
				</div>
			</div>
		</div>

		<div class="pfg-button_group">
			<div style="padding: 0 20px">
				<button
					class="button v_ghost_brand_secondary v_has-icon-right v_smaller a_margin-left-10"
					@click="$emit('close')"
				>
					<span class="button-txt"> Sluiten </span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import CopyToClipBoard from "./CopyToClipBoard.vue";

export default {
	name: "ModalProefsporterBekend",
	components: { CopyToClipBoard },
	data() {
		return {
			buyDayPassLink: "https://profitgym.nl/dagpas-kopen/",
		};
	},
};
</script>
