


























import { defineComponent } from "@vue/composition-api";

type Data = {
	dropdownIsOpen: boolean;
	isHovering: boolean;
	closeDropdownTimeout?: ReturnType<typeof setTimeout>;
};

export default defineComponent({
	name: "PfgInlineDropdown",
	props: {
		title: {
			type: String,
			required: false,
			default: undefined,
		},
		collapsable: Boolean,
		position: {
			type: String,
			default: "left",
		},
		width: {
			type: Number,
			default: 200,
		},
		type: {
			type: String,
			default: "button",
		},
		top: {
			type: String,
			default: "100%",
		},
		icon: {
			type: String,
			default: "icon_ui_caret",
		},
	},
	data(): Data {
		return {
			dropdownIsOpen: false,
			isHovering: false,
			closeDropdownTimeout: undefined,
		};
	},
	methods: {
		toggleDropdown() {
			this.dropdownIsOpen = !this.dropdownIsOpen;
			this.isHovering = !this.isHovering;
		},
		closeDropdown() {
			if (this.closeDropdownTimeout) {
				clearTimeout(this.closeDropdownTimeout);
			}
			this.isHovering = false;
			this.closeDropdownTimeout = setTimeout(() => {
				if (!this.isHovering) {
					this.dropdownIsOpen = false;
				}
				if (this.closeDropdownTimeout) clearTimeout(this.closeDropdownTimeout);
				this.closeDropdownTimeout = undefined;
			}, 333);
		},
	},
});
