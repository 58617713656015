import { defineStore } from "pinia";

type Popup = {
	title?: string;
	message?: {
		title?: string;
		text?: string;
	};
	body?: string;
	buttons?: {
		confirm?: string;
		cancel?: string;
	};
	callback?:
		| (() => void)
		| (() => Promise<void>)
		| ((text?: string) => void)
		| ((text?: string) => Promise<void>);
};

type State = {
	opened: boolean;
	content: Popup;
};

export const usePopupStore = defineStore("popup", {
	state: (): State => ({
		opened: false,
		content: {
			title: undefined,
			message: {
				title: undefined,
				text: undefined,
			},
			body: undefined,
			buttons: {
				confirm: undefined,
				cancel: undefined,
			},
			callback: undefined,
		},
	}),
	getters: {},
	actions: {
		open(popup: Popup) {
			this.opened = true;
			this.content = popup;
		},
		close() {
			this.opened = false;
			this.$reset();
		},
		showError(error: string) {
			this.open({
				title: "Oeps!",
				body: error,
				buttons: {
					cancel: "Sluiten",
				},
			});
		},
	},
});
