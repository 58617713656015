
































import { defineComponent } from "@vue/composition-api";
import Modal2 from "@/components/elements/Modal2.vue";
import Modal from "@/components/elements/Modal.vue";
import UserMenu from "@/components/elements/UserMenu.vue";
import Symbols from "@/components/parts/Symbols.vue";
import TopBar from "@/components/parts/TopBar.vue";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import Toaster from "@/lib/vue2-sonner/Toaster.vue";
import { useAppStore } from "@/pinia/app";

export default defineComponent({
	name: "App",
	components: {
		Modal,
		Modal2,
		UserMenu,
		Symbols,
		TopBar,
		Toaster,
	},
	setup() {
		useCurrentGym();
		const appStore = useAppStore();

		return { appStore };
	},
	computed: {
		showGebruikerView(): boolean {
			return this.$route.matched.at(-1)?.meta.gebruikerView ?? false;
		},
	},
});
