








































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
	name: "PfgLoader",
	props: {
		loading: {
			type: Boolean,
			default: false,
			required: false,
		},
		size: {
			type: String,
			default: "36px",
			required: false,
		},
	},
});
