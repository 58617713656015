






















































import { defineComponent } from "@vue/composition-api";
import { usePopupStore } from "@/pinia/popup";

export default defineComponent({
	name: "PfgPopup",
	setup() {
		const popupStore = usePopupStore();

		function performBackdropClick() {
			if (!popupStore.content.buttons?.confirm) popupStore.close();
		}

		function performCancel() {
			popupStore.close();
		}

		function performConfirm() {
			popupStore.content.callback?.(popupStore.content.message?.text);
			popupStore.close();
		}

		return {
			popupStore,
			performBackdropClick,
			performCancel,
			performConfirm,
		};
	},
});
