


































































































































































































import { type PropType, defineComponent } from "@vue/composition-api";
import { DateTime } from "klokwerk";
import { mapStores } from "pinia";
import { clubApi } from "@/lib/backend";
import type {
	IomodelsCoachappIntake,
	IomodelsCoachappPersoonlijkeGegevens,
} from "@/lib/backend/club.api";
import { DEFAULT_LANGUAGE } from "@/lib/constants/misc";
import { GESLACHT_MAP, LANGUAGE_MAP } from "@/lib/constants/options";
import { usePopupStore } from "@/pinia/popup";
import { getDateFromDatum } from "@/utils/date";

type Data = {
	loading: boolean;
	form: {
		voornaam: string;
		achternaam: string;
		telefoonMobiel: string;
		email: string;
		geslacht: Geslacht;
		geboortedatum: string;
		language: IomodelsCoachappPersoonlijkeGegevens["language"];
	};
	GESLACHT_MAP: typeof GESLACHT_MAP;
	LANGUAGE_MAP: typeof LANGUAGE_MAP;
};

export default defineComponent({
	name: "PfgModalTempMember",
	props: {
		data: {
			type: Object as PropType<{ popup: { enable: boolean } }>,
			default: () => ({}),
			required: false,
		},
		callback: {
			type: Function as PropType<(param1: unknown, param2?: unknown) => void>,
			default: () => {
				return;
			},
			required: false,
		},
	},
	data(): Data {
		return {
			loading: false,
			form: {
				voornaam: undefined as unknown as string,
				achternaam: undefined as unknown as string,
				telefoonMobiel: undefined as unknown as string,
				email: undefined as unknown as string,
				geslacht: undefined as unknown as Geslacht,
				geboortedatum: undefined as unknown as string,
				language: DEFAULT_LANGUAGE,
			},
			GESLACHT_MAP,
			LANGUAGE_MAP,
		};
	},
	computed: {
		...mapStores(usePopupStore),
	},
	methods: {
		async save() {
			const isValid = await this.$validator.validateAll();

			if (!isValid) return false;

			this.loading = true;

			try {
				const body: IomodelsCoachappIntake = {
					hartklachten: false,
					kortademigheid: false,
					consultBijFysiotherapeut: false,
					diensten: [],
					interesses: [],
					medischCheck: false,
					personalInfo: {
						geslacht: this.form.geslacht,
						voornaam: this.form.voornaam,
						achternaam: this.form.achternaam,
						email: this.form.email,
						telefoonMobiel: this.form.telefoonMobiel,
						geboortedatum: new DateTime(getDateFromDatum(this.form.geboortedatum))
							.setHours(12)
							.setMinutes(0)
							.setSeconds(0)
							.setMilliseconds(0)
							.toISOString(),
						language: this.form.language,
					},
				};

				const response = await clubApi.intake.postIntake(body);

				switch (response.status) {
					case 200: {
						const lidId = response.data.tijdelijkId;

						if (this.data.popup.enable) {
							this.popupStore.open({
								...this.data.popup,
								buttons: {
									confirm: "Afspraak inplannen",
								},
								callback: () => this.callback({ id: lidId, ...body }),
							});
							this.$emit("close");
						} else if (this.callback) {
							this.callback(() => this.$emit("close"), {
								id: lidId,
								...body,
							});
						}

						return true;
					}

					default: {
						throw response;
					}
				}
			} catch (error: { message: string } | { statusText: string } | string | unknown) {
				const message =
					(error as { message: string })?.message ||
					(error as { statusText: string })?.statusText ||
					error;

				this.popupStore.showError(
					`Er ging iets mis bij het opslaan van het tijdelijke lid: ${message}.<br/>Probeer het later nog eens.`,
				);

				return false;
			} finally {
				this.loading = false;
			}
		},
	},
});
