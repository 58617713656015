import { z } from "zod";

export const RouteParamSchema = <T extends z.ZodTypeAny>(schema: T) => z.any().pipe(schema);
export const NumberSchema = RouteParamSchema(z.coerce.number());

export const RoutePropsSchemas = {
	/** Generic */
	member: { memberId: NumberSchema },
	grouplesson: { grouplessonId: NumberSchema },

	/** Specific routes */
	"Member program": {
		memberId: NumberSchema,
		programId: NumberSchema,
		programWeek: RouteParamSchema(z.coerce.number().positive()),
		programDay: RouteParamSchema(z.coerce.number().positive()),
	},
	"Member message": {
		memberId: NumberSchema,
		messageId: NumberSchema,
	},
	"Member appointment edit": {
		memberId: NumberSchema,
		appointmentId: NumberSchema,
	},
} as const;
export type RouteProps<T extends keyof typeof RoutePropsSchemas> = z.infer<
	z.ZodObject<(typeof RoutePropsSchemas)[T]>
>;
