import createAuthRefreshInterceptor from "axios-auth-refresh";
import Cookies from "js-cookie";
import { defineStore } from "pinia";
import { axios, axiosInterceptorRequests, axiosInterceptorResponses } from "@/axios";
import { clubApi, coachApi, performForAllApis } from "@/lib/backend";
import type {
	IomodelsAccessToken,
	IomodelsClubLogin,
	IomodelsIngelogdeGebruiker,
	IomodelsRefreshToken,
} from "@/lib/backend/club.api";
import type { IngelogdeGebruiker } from "@/lib/backend/coach.api";
import {
	COOKIE_ACCESS_TOKEN,
	COOKIE_REFRESH_TOKEN,
	COOKIE_SCOPE,
	COOKIE_TOKEN_TYPE,
} from "@/lib/constants/cookies";
import { logger } from "@/logger";

type State = {
	initialized: boolean;
	userCoach?: IngelogdeGebruiker;
	userClub?: IomodelsIngelogdeGebruiker;
	accessToken?: string;
	refreshToken?: string;
	scope: string | null | undefined;
	tokenType?: string;
	expiresIn: number | null | undefined;
};

type Role = AutocompleteOptions<"admin" | "club_aanvoerder" | "trainer" | "lid">;

export const useAuthStore = defineStore("auth", {
	state: (): State => ({
		initialized: false,
		userCoach: undefined,
		userClub: undefined,
		accessToken: undefined,
		refreshToken: undefined,
		scope: undefined,
		tokenType: undefined,
		expiresIn: undefined,
	}),
	getters: {
		isLoggedIn: ({ accessToken, tokenType, refreshToken, scope }) =>
			!!accessToken && !!refreshToken && !!scope && !!tokenType,
		user: ({ userCoach, userClub }) => ({
			...userCoach,
			...userClub,
		}),
		roles({ scope }): Role[] | undefined {
			if (scope) {
				return scope.split(",") as Role[];
			}

			return;
		},
		role(): Role | undefined {
			if (this.roles) {
				return this.roles[0];
			}

			return;
		},
		isClubAanvoerder(): boolean {
			return this.roles?.includes("club_aanvoerder") ?? false;
		},
	},
	actions: {
		async init() {
			if (this.initialized) {
				return;
			}

			this.initialized = true;

			await this.authorize({
				access_token: Cookies.get(COOKIE_ACCESS_TOKEN),
				refresh_token: Cookies.get(COOKIE_REFRESH_TOKEN),
				scope: Cookies.get(COOKIE_SCOPE),
				token_type: Cookies.get(COOKIE_TOKEN_TYPE),
			});
		},
		async signIn(credentials: IomodelsClubLogin) {
			try {
				// First try club login
				const response = await clubApi.login.accessToken(credentials);

				switch (response.status) {
					case 200: {
						await this.authorize(response.data);

						return response;
					}

					default: {
						break;
					}
				}
			} catch (error) {
				// Club login failed, try normal login
			}

			try {
				const response = await clubApi.coachLogin.accessTokenCoach(credentials);

				switch (response.status) {
					case 200: {
						await this.authorize(response.data);

						return response;
					}

					default: {
						throw response;
					}
				}
			} catch (error: unknown) {
				switch (
					(error as { response: { data: { error_description: string } } }).response.data
						.error_description
				) {
					case "username or password is incorrect": {
						throw "Verkeerde gebruikersnaam of wachtwoord";
					}

					default: {
						throw error;
					}
				}
			}
		},
		async refresh(refreshToken: IomodelsRefreshToken) {
			try {
				// First try club refresh
				const response = await clubApi.login.accessTokenRefresh(refreshToken);

				switch (response.status) {
					case 200: {
						await this.authorize(response.data);

						return response;
					}

					default: {
						break;
					}
				}
			} catch (error) {
				// Club refresh failed, try normal refresh
			}

			try {
				const response = await clubApi.coachLogin.accessTokenCoachRefresh(refreshToken);

				switch (response.status) {
					case 200: {
						await this.authorize(response.data);

						return response;
					}

					default: {
						await this.deauthorize();
						throw response;
					}
				}
			} catch (error: unknown) {
				logger.error(error);
				throw error;
			}
		},
		async signOut() {
			await this.deauthorize();
			window.location.href = "/";
		},
		async getInfo() {
			const responseCoach = await coachApi.api.loginInfoList();

			switch (responseCoach.status) {
				case 200: {
					this.userCoach = responseCoach.data;
					break;
				}

				default: {
					throw responseCoach;
				}
			}

			const responseClub = await clubApi.ingelogd.ingelogd();

			switch (responseClub.status) {
				case 200: {
					this.userClub = responseClub.data;
					break;
				}

				default: {
					throw responseClub;
				}
			}
		},
		async authorize({
			access_token,
			refresh_token,
			scope,
			token_type,
			expires_in,
		}: Partial<IomodelsAccessToken>) {
			if (!refresh_token) {
				return;
			}

			this.accessToken = access_token;
			this.refreshToken = refresh_token;
			this.scope = scope;
			this.tokenType = token_type;
			this.expiresIn = expires_in;

			if ((!token_type || !access_token) && refresh_token) {
				await this.refresh({ token: refresh_token });
			}

			const Authorization = `${token_type} ${access_token}`;

			performForAllApis(
				(api) => (api.instance.defaults.headers.common.Authorization = Authorization),
			);

			axios.defaults.headers.common.Authorization = Authorization;

			this.getInfo();

			if (axiosInterceptorRequests.length >= 1) {
				axiosInterceptorRequests.forEach((value) => axios.interceptors.request.eject(value));
			}

			performForAllApis((api) =>
				axiosInterceptorRequests.push(
					createAuthRefreshInterceptor(
						api.instance,
						async (failedRequest) => {
							await this.refresh({ token: refresh_token });
							return api.instance.request(failedRequest);
						},
						{
							statusCodes: [401],
							pauseInstanceWhileRefreshing: true,
						},
					),
				),
			);

			axiosInterceptorRequests.push(
				createAuthRefreshInterceptor(
					axios,
					async (failedRequest) => {
						await this.refresh({ token: refresh_token });
						return axios.request(failedRequest);
					},
					{
						statusCodes: [401],
						pauseInstanceWhileRefreshing: true,
					},
				),
			);
		},
		async deauthorize() {
			this.accessToken = undefined;
			this.refreshToken = undefined;
			this.scope = undefined;
			this.tokenType = undefined;
			this.expiresIn = undefined;

			axiosInterceptorRequests.forEach((value) => axios.interceptors.request.eject(value));

			axiosInterceptorResponses.forEach((value) => axios.interceptors.response.eject(value));

			return Promise.resolve();
		},
	},
});
