import { axios } from "@/axios";
import { config } from "@/lib/config";

/** @deprecated Please use `pinia` instead */
const buildReserveerUrl = (path) => `${config.reserveer.server}/${path}`;

/** @deprecated Please use `pinia` instead */
const trial = {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		async saveTrialSports(context, data) {
			return axios.post(buildReserveerUrl("proef-fitness"), data);
		},
		async saveTrialGrouplesson(context, data) {
			return axios.post(buildReserveerUrl("proefles-deelname"), data);
		},
		async saveWelcomeForm(context, data) {
			return axios.post(buildReserveerUrl("welkomstformulier"), data);
		},
	},
};

export default trial;
