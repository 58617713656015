import type { NavigationGuard } from "vue-router";
import { useAuthStore } from "@/pinia/auth";

export const requireAuth: NavigationGuard = (to, __from, next) => {
	const auth = useAuthStore();
	auth.init();

	if (!auth.isLoggedIn) {
		return next({
			name: "Login",
			...(to.path ? { query: { callbackUrl: to.path } } : {}),
		});
	} else {
		return next();
	}
};

export const requireUnauth: NavigationGuard = (__to, __from, next) => {
	const auth = useAuthStore();
	auth.init();

	if (auth.isLoggedIn) {
		return next({ name: "Home" });
	} else {
		return next();
	}
};
