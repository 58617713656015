import type {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	HeadersDefaults,
	ResponseType,
} from "axios";
import axios from "axios";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IomodelsNotificatieExtern {
	/** @format int64 */
	id: number;
	titel: string;
	inhoud: string;
	/** @format date-time */
	verstuurdOp: string;
	gelezen: boolean;
	notifcatieType?:
		| "les-reservering"
		| "afspraak-reservering"
		| "les-gemist"
		| "les-gewijzigd"
		| "les-geannuleerd"
		| "afspraak-geannuleerd"
		| "herhaling-afgemeld"
		| "schema-6-weken-oud"
		| "meting-4-weken-oud"
		| "geen-doel-opgesteld"
		| "les-bijna-vol"
		| "compliment-aantal-bezoeken"
		| "compliment-aantal-groepslessen"
		| "compliment-over-meting"
		| "vriend-al-geweest"
		| "vriend-aansporen"
		| "maand-rapport"
		| "kwartaal-rapport"
		| "jaar-rapport"
		| "specifieke-datum-notificatie"
		| "afval-tips"
		| "spierballen-arrangement"
		| "vaste-sportdag"
		| "vaker-fitness-afspraak"
		| "zonnebank-tip"
		| "vriend-les-uitnodiging"
		| "vriend-uitnoding"
		| "les-herinnering"
		| null;
}

export interface OauthError {
	error?: string;
	error_description?: string;
}

export interface IomodelsCoronaTijdslot {
	/** @format int64 */
	tijdslotId: number;
	/** @format int64 */
	vestigingId: number;
	status?: "aankomend" | "afgelopen" | "geannuleerd" | null;
	/** @format date-time */
	datum: string;
	/** @format int32 */
	duur: number;
	/** @format int32 */
	plekken: number;
	/** @format int32 */
	bezet: number;
	aanwezigheden: DaosModelsAanwezigheid[];
	/** @format int64 */
	groepslesId: number;
	ingediend?: boolean | null;
}

export interface IomodelsAppointmentFilter {
	/** @format date-time */
	startDatum: string;
	/** @format date-time */
	eindDatum: string;
	location?: IomodelsLocationFilter | null;
	status?: IomodelsLesStatusFilter | null;
	aanwezigheidsStatus?: ("aangemeld" | "afgemeld" | "geweest" | "niet_geweest")[] | null;
}

export interface IomodelsTijdelijkLidId {
	/** @format int64 */
	tijdelijkId: number;
}

export interface IomodelsAfbeelding {
	formaat: string;
	url: string;
}

export interface IomodelsOefeningExtern {
	/** @format int32 */
	index: number;
	naam: string;
	/** @format int32 */
	herhalingen: number;
	herhalingenStringToevoeging?: string | null;
	/** @format int32 */
	sets: number;
	cardio: boolean;
	/** @format double */
	adviesGewicht?: number | null;
	videoUrl: string;
	/** @format double */
	huidigeWaarde?: number | null;
}

export interface IomodelsTrainingsProgrammaVoortgangExtern {
	/** @format int64 */
	trainingsProgrammaId: number;
	/** @format int32 */
	week: number;
	/** @format int32 */
	dag: number;
	voortgang: IomodelsVoortgangItem[];
}

export interface IomodelsDeelnamesResult {
	/** @format int32 */
	aantal: number;
}

export interface IomodelsLesUitgebreid {
	/** @format int64 */
	id: number;
	/** @format int64 */
	roosterItemId: number;
	/** @format int64 */
	groepslesId?: number | null;
	/** @format int64 */
	trainerId: number;
	status: "aankomend" | "afgelopen" | "geannuleerd";
	/** @format date-time */
	datum: string;
	/** @format int64 */
	vestigingId: number;
	/** @format int64 */
	zaalId: number;
	/** @format int32 */
	plekken: number;
	/** @format int32 */
	bezet: number;
	/** @format int32 */
	duur: number;
	attributen: IomodelsAttribuut[];
	kenmerken: IomodelsAttribuut[];
	aanwezigheidsLijstExtern?: IomodelsAanwezigheidsLijstExtern | null;
	coronaTijdslot: boolean;
	itemType: "live" | "virtual" | "buiten";
}

export interface IomodelsAanwezigheidExtern {
	/** @format int64 */
	lidId: number;
	lidNaam?: string | null;
	status: "aangemeld" | "afgemeld" | "geweest" | "niet_geweest";
	herhalend?: boolean | null;
	/** @format int32 */
	deelnames?: number | null;
}

export interface IomodelsRoosterExtern {
	/** @format int64 */
	id: number;
	/** @format int64 */
	versie?: number | null;
	status?: "concept" | "gepubliceerd" | "niet_gepubliceerd" | null;
	/** @format date-time */
	aangemaakt?: string | null;
	/** @format date-time */
	gewijzigd?: string | null;
	roosterItems?: IomodelsRoosterItemExtern[] | null;
}

export interface IomodelsLidMeta {
	/** @format int32 */
	doelFrequentie?: number | null;
}

export interface IomodelsAnnuleringExtern {
	/** @format int64 */
	id: number;
	reden?: string | null;
}

export interface IomodelsGroepslesUitgebreid {
	/** @format int64 */
	id: number;
	naam: string;
	beschrijving: string;
	groepslesTypes: string[];
	afbeelding: IomodelsAfbeelding[];
	video?: string | null;
	attributen: IomodelsAttribuut[];
	kenmerken: IomodelsAttribuut[];
	quote?: string | null;
	/** @format int64 */
	sourceId?: number | null;
}

export interface IomodelsOverlappendeItemsError {
	message: string;
	items: IomodelsRoosterItemExtern[];
}

export interface IomodelsRoosterItemExtern {
	/** @format int64 */
	id?: number | null;
	/** @format int64 */
	groepslesId?: number | null;
	/** @format int64 */
	trainerId: number;
	/** @format int64 */
	vestigingId: number;
	/** @format int64 */
	zaalId: number;
	/** @format int32 */
	plekken: number;
	dag: "maandag" | "dinsdag" | "woensdag" | "donderdag" | "vrijdag" | "zaterdag" | "zondag";
	/** @format time */
	tijd: string;
	/** @format int32 */
	duur: number;
	range?: IomodelsRange | null;
	coronaTijdslot?: boolean | null;
	itemType?: "live" | "virtual" | "buiten" | null;
}

export interface IomodelsLidFrequentie {
	/** @format int32 */
	week: number;
	/** @format int32 */
	frequentie: number;
}

export interface IomodelsTrainingsDagExtern {
	/** @format int64 */
	programmaId: number;
	programmaTitel: string;
	/** @format int32 */
	week: number;
	/** @format int32 */
	dag: number;
	laatsteDagVanSchema: boolean;
	/** @format int32 */
	dagenPerWeek: number;
	oefeningen: IomodelsOefeningExtern[];
}

export interface IomodelsLidKickOffStatus {
	afgenomen: boolean;
	kickOffAfspraken: IomodelsFitnessAfspraakExtern[];
}

export interface IomodelsVestigingContact {
	straat: string;
	postcode: string;
	plaats: string;
	telefoon: string;
	/** @format double */
	lat: number;
	/** @format double */
	lng: number;
}

export interface IomodelsVestiging {
	/** @format int64 */
	id: number;
	naam: string;
	slug:
		| "epe"
		| "dalfsen"
		| "zwolle-zuid"
		| "assen"
		| "ede"
		| "amsterdam"
		| "emmen"
		| "vlaardingen"
		| "zwolle-dieze"
		| "hilversum"
		| "apeldoorn-centrum"
		| "groningen"
		| "apeldoorn-zuid"
		| "nieuw-vennep"
		| "ommen"
		| "hardenberg"
		| "nieuwegein"
		| "zwolle-stadshagen";
	email: string;
	zalen: IomodelsZaal[];
	afbeeldingen: IomodelsAfbeelding[];
	openingsTijden: IomodelsOpeningsSlot[];
	tekst: string;
	contactInfo?: IomodelsVestigingContact | null;
}

export interface DaosModelsMeting {
	metingMeta: DaosModelsMetingMeta;
	items: DaosModelsMetingItem[];
}

export interface IomodelsArrangementVanLidWijziging {
	/** @format int64 */
	lidId: number;
	/** @format int64 */
	arrangementId: number;
	/** @format int64 */
	vestigingId: number;
	wijzigingsType: "afsluiten" | "annuleren";
}

export interface IomodelsLidBezoek {
	/** @format int64 */
	lidId: number;
	/** @format date-time */
	datum: string;
}

export interface IomodelsLidBezoeken {
	/** @format int64 */
	lidId: number;
	bezoeken: IomodelsLidBezoek[];
	grafiek: IomodelsLidFrequentie[];
}

export interface IomodelsAanwezigheidsLijstExtern {
	/** @format int64 */
	lesId: number;
	lijst: IomodelsAanwezigheidExtern[];
	ingediend?: boolean | null;
}

export interface IomodelsFitnessAfspraakExtern {
	/** @format int64 */
	id: number;
	/** @format int64 */
	roosterItemId: number;
	/** @format int64 */
	vestigingId: number;
	/** @format int64 */
	trainerId?: number | null;
	/** @format int32 */
	week: number;
	status: "aankomend" | "afgelopen" | "geannuleerd";
	/** @format date-time */
	datum: string;
	/** @format int32 */
	duur?: number | null;
	ingediend: boolean;
	aanwezigheid?: IomodelsAanwezigheidFitnessAfspraakExtern | null;
}

export interface IomodelsLesBericht {
	/** @format int64 */
	id: number;
	titel: string;
	inhoud: string;
	/** @format date-time */
	geplandOp?: string | null;
}

export interface IomodelsConflicterendeItems {
	bestaandeItems: IomodelsRoosterItemExtern[];
	opgegevenItem: IomodelsRoosterItemExtern;
}

export interface IomodelsIDFilter {
	/** @format int64 */
	equals?: number | null;
	in?: number[] | null;
}

export interface OauthUser {
	userType?: "lid" | "trainer" | "club_aanvoerder";
	lidId?: number;
	trainerId?: number;
	clubAanvoerderId?: number;
}

export type DaosModelsNieuwLidVerwijzing = any;

export interface IomodelsVoortgangItem {
	/** @format int32 */
	index: number;
	/** @format double */
	waarde: number;
}

export interface IomodelsZaal {
	/** @format int64 */
	id: number;
	naam: string;
}

export interface IomodelsGeslotenDag {
	/** @format int64 */
	id?: number | null;
	label?: string | null;
	/** @format date-time */
	datum: string;
}

export interface IomodelsTijdelijkLidInfo {
	locatie:
		| "epe"
		| "dalfsen"
		| "zwolle-zuid"
		| "assen"
		| "ede"
		| "amsterdam"
		| "emmen"
		| "vlaardingen"
		| "zwolle-dieze"
		| "hilversum"
		| "apeldoorn-centrum"
		| "groningen"
		| "apeldoorn-zuid"
		| "nieuw-vennep"
		| "ommen"
		| "hardenberg"
		| "nieuwegein"
		| "zwolle-stadshagen";
	geslacht: "m" | "f" | "";
	voornaam: string;
	achternaam: string;
	email: string;
	adres?: string | null;
	postcode?: string | null;
	stad?: string | null;
	vastNr?: string | null;
	/** @format date-time */
	geboorteDatum?: string | null;
	telefoonMobiel?: string | null;
	language?: "eng" | "nld" | null;
}

export interface IomodelsOpeningsSlot {
	text: string;
	/** @format time */
	tijdVan: string;
	/** @format time */
	tijdTot: string;
	dagVan: "maandag" | "dinsdag" | "woensdag" | "donderdag" | "vrijdag" | "zaterdag" | "zondag";
	dagTot: "maandag" | "dinsdag" | "woensdag" | "donderdag" | "vrijdag" | "zaterdag" | "zondag";
}

export interface IomodelsBrochureVoorLid {
	/** @format int64 */
	lidId: number;
	brochureType:
		| "Afvallen in 8 weken"
		| "Spierballen in 8 weken"
		| "Zonnebank"
		| "Sportvasten SwitchMe";
}

export interface IomodelsBezetTijdslotError {
	message: string;
	conflicterendeParen: IomodelsConflicterendeItems[];
}

export interface IomodelsProefsporterLes {
	/** @format int64 */
	lesId: number;
	email: string;
	geslacht: "m" | "f" | "";
	locatie:
		| "epe"
		| "dalfsen"
		| "zwolle-zuid"
		| "assen"
		| "ede"
		| "amsterdam"
		| "emmen"
		| "vlaardingen"
		| "zwolle-dieze"
		| "hilversum"
		| "apeldoorn-centrum"
		| "groningen"
		| "apeldoorn-zuid"
		| "nieuw-vennep"
		| "ommen"
		| "hardenberg"
		| "nieuwegein"
		| "zwolle-stadshagen";
	voornaam: string;
	achternaam: string;
	telefoonNummer?: string | null;
	mobielNummer?: string | null;
	doel?: string | null;
	ondersteuning?: string | null;
	/** @format int32 */
	frequentie?: number | null;
	verwijzing?:
		| "vrienden"
		| "facebook"
		| "gratis-weekpas"
		| "instagram"
		| "zoekmachine"
		| "anders"
		| null;
	interesses?: string | null;
	/** @format date-time */
	geboorteDatum?: string | null;
}

export interface IomodelsTrainingsProgrammaExtern {
	/** @format int64 */
	id: number;
	zesWekenProgramma: boolean;
	titel: string;
	voltooid: boolean;
	/** @format date-time */
	startDatum: string;
}

export interface IomodelsAanwezigheidFitnessAfspraakExtern {
	/** @format int64 */
	lidId: number;
	lidNaam?: string | null;
	status: "aangemeld" | "afgemeld" | "geweest" | "niet_geweest";
	meting: boolean;
	trainingsSchema: boolean;
	doelOpstellen: boolean;
	personalTraining: boolean;
	kickOff: boolean;
	opmerkingen?: string | null;
}

export interface IomodelsApiStatus {
	version: string;
	alive: boolean;
}

export interface IomodelsApplicatieNotificatie {
	/** @format int64 */
	lidId: number;
	/** @format date-time */
	geplandOp?: string | null;
	notificatieType:
		| "les-reservering"
		| "afspraak-reservering"
		| "les-gemist"
		| "les-gewijzigd"
		| "les-geannuleerd"
		| "afspraak-geannuleerd"
		| "herhaling-afgemeld"
		| "schema-6-weken-oud"
		| "meting-4-weken-oud"
		| "geen-doel-opgesteld"
		| "les-bijna-vol"
		| "compliment-aantal-bezoeken"
		| "compliment-aantal-groepslessen"
		| "compliment-over-meting"
		| "vriend-al-geweest"
		| "vriend-aansporen"
		| "maand-rapport"
		| "kwartaal-rapport"
		| "jaar-rapport"
		| "specifieke-datum-notificatie"
		| "afval-tips"
		| "spierballen-arrangement"
		| "vaste-sportdag"
		| "vaker-fitness-afspraak"
		| "zonnebank-tip"
		| "vriend-les-uitnodiging"
		| "vriend-uitnoding"
		| "les-herinnering";
	/** @format int64 */
	lesId?: number | null;
	/** @format int64 */
	vriendId?: number | null;
}

export interface IomodelsNotificatieInstellingExtern {
	key: string;
	label?: string | null;
	method: "push" | "email" | "beide" | "geen";
}

export interface IomodelsConsumable {
	/** @format int64 */
	id: number;
	titel: string;
	prijs: string;
	afbeelding: IomodelsAfbeelding[];
	vestigingen: number[];
}

export interface IomodelsRange {
	/** @format int32 */
	startWeek: number;
	/** @format int32 */
	eindWeek: number;
}

export interface IomodelsArrangement {
	/** @format int64 */
	id: number;
	naam: string;
	secties: unknown[];
	afbeelding: IomodelsAfbeelding[];
	/** @format float */
	prijs: number;
	/** @format float */
	prijsDecimaal: number;
	tekstOnderPrijs: string;
}

export interface IomodelsAbonnement {
	naam: string;
	/** @format date-time */
	start: string;
	/** @format date-time */
	eind?: string | null;
}

export interface IomodelsLes {
	/** @format int64 */
	id: number;
	/** @format int64 */
	roosterItemId: number;
	/** @format int64 */
	trainerId: number;
	/** @format int32 */
	week: number;
	status?: "aankomend" | "afgelopen" | "geannuleerd" | null;
	/** @format date-time */
	datum: string;
}

export interface OauthToken {
	token_type?: string;
	access_token?: string;
	expires_in?: number;
	refresh_token?: string;
	/**
	 * Afhankelijk van de gebruiker 1 of meerdere scopes. Mogelijke opties zijn `lid`, `trainer` en
	 * `club_aanvoerder`.
	 */
	scope?: string;
}

export interface IomodelsNieuweGepersonaliseerdeTraining {
	/** @format int64 */
	lidId: number;
	trainingsCode: number[];
}

export interface IomodelsAttribuut {
	attribuut: string;
	/** @format int32 */
	waarde: number;
}

export interface DaosModelsConsumableVoorLid {
	/** @format int64 */
	id?: number | null;
	/** @format int64 */
	lidId: number;
	/** @format int64 */
	consumableId: number;
	titel: string;
	imgUrl?: string | null;
	/** @format date-time */
	datumToegevoegd: string;
}

export interface IomodelsLessenFilter {
	lijstIngediend?: boolean | null;
	/** @format int64 */
	groepsles?: number | null;
	kenmerken?: IomodelsAttribuut[] | null;
	attributen?: IomodelsAttribuut[] | null;
	/** @format int32 */
	duur?: number | null;
	/** @format int64 */
	vestiging?: number | null;
	metDeelnames?: boolean | null;
	itemType?: "live" | "virtual" | "buiten" | null;
}

export interface DaosModelsMetingMeta {
	/** @format int64 */
	id: number;
	/** @format int64 */
	memberId: number;
	/** @format date-time */
	aangemaakt: string;
	verwijderd: boolean;
}

export interface IomodelsProefsporterTijdslot {
	/** @format int64 */
	tijdslotId: number;
	email: string;
	geslacht: "m" | "f" | "";
	locatie:
		| "epe"
		| "dalfsen"
		| "zwolle-zuid"
		| "assen"
		| "ede"
		| "amsterdam"
		| "emmen"
		| "vlaardingen"
		| "zwolle-dieze"
		| "hilversum"
		| "apeldoorn-centrum"
		| "groningen"
		| "apeldoorn-zuid"
		| "nieuw-vennep"
		| "ommen"
		| "hardenberg"
		| "nieuwegein"
		| "zwolle-stadshagen";
	voornaam: string;
	achternaam: string;
	telefoonNummer?: string | null;
	doel?: string | null;
	ondersteuning?: string | null;
	/** @format int32 */
	frequentie?: number | null;
	verwijzing?:
		| "vrienden"
		| "facebook"
		| "gratis-weekpas"
		| "instagram"
		| "zoekmachine"
		| "anders"
		| null;
	interesses?: string | null;
	/** @format date-time */
	geboorteDatum?: string | null;
	telefoonMobiel?: string | null;
}

export interface IomodelsLid {
	/** @format int64 */
	id: number;
	memberNumber: string;
	voornaam: string;
	achternaam: string;
	/** @format date */
	geboorteDatum?: string | null;
	/** @format int64 */
	vestiging: number;
	/** @format int64 */
	coach: number;
	coachingsGroep?: "30%" | "70%" | null;
	meta: IomodelsLidMeta;
	language: "eng" | "nld";
}

export interface IomodelsLessenFilterMetDatumRange {
	/** @format date-time */
	startDatum: string;
	/** @format date-time */
	eindDatum: string;
	lijstIngediend?: boolean | null;
	/** @format int64 */
	groepsles?: number | null;
	kenmerken?: IomodelsAttribuut[] | null;
	attributen?: IomodelsAttribuut[] | null;
	/** @format int32 */
	duur?: number | null;
	/** @format int64 */
	vestiging?: number | null;
	metAanwezigheid?: boolean | null;
	metDeelnames?: boolean | null;
	itemType?: "live" | "virtual" | "buiten" | null;
}

export interface IomodelsTrainerFilter {
	/** @format int64 */
	lesId?: number | null;
	vestigingenIds?: number[] | null;
}

export interface DaosModelsAanwezigheid {
	/** @format int64 */
	lijstId: number;
	/** @format int64 */
	lidId: number;
	lidNaam: string;
	status: "aangemeld" | "afgemeld" | "geweest" | "niet_geweest";
}

export interface IomodelsWelkomstFormulierExtern {
	email: string;
	locatie:
		| "epe"
		| "dalfsen"
		| "zwolle-zuid"
		| "assen"
		| "ede"
		| "amsterdam"
		| "emmen"
		| "vlaardingen"
		| "zwolle-dieze"
		| "hilversum"
		| "apeldoorn-centrum"
		| "groningen"
		| "apeldoorn-zuid"
		| "nieuw-vennep"
		| "ommen"
		| "hardenberg"
		| "nieuwegein"
		| "zwolle-stadshagen";
	voornaam?: string | null;
	achternaam?: string | null;
	telefoonNummer?: string | null;
	mobielNummer?: string | null;
	doel?: string | null;
	ondersteuning?: string | null;
	/** @format int32 */
	frequentie?: number | null;
	verwijzing?:
		| "vrienden"
		| "facebook"
		| "gratis-weekpas"
		| "instagram"
		| "zoekmachine"
		| "anders"
		| null;
	interesses?: string | null;
	geslacht?: "m" | "f" | "" | null;
	/** @format date-time */
	geboorteDatum?: string | null;
}

export interface DaosModelsMetingItem {
	/** @format int64 */
	id: number;
	/** @format int64 */
	metingId: number;
	unitId:
		| "onderdruk"
		| "bovendruk"
		| "conditie"
		| "waistCircumference"
		| "muscleMass"
		| "lengte"
		| "hartslag"
		| "gewicht"
		| "vetpercentage"
		| "BMI";
	waarde: string;
}

export interface IomodelsPushToken {
	token: string;
}

export interface IomodelsTrainerResult {
	/** @format int64 */
	id: number;
	naam: string;
	groepslessenIds: number[];
	vestigingenIds: number[];
	foto?: string | null;
}

export interface IomodelsGroepslesFilter {
	types: string[];
}

export interface IomodelsTijdslotenFilter {
	/** @format date-time */
	startDatum: string;
	/** @format date-time */
	eindDatum: string;
	/** @format int64 */
	vestiging?: number | null;
}

export interface IomodelsLocationFilter {
	id?: IomodelsIDFilter | null;
}

export interface IomodelsLesStatusFilter {
	equals?: "aankomend" | "afgelopen" | "geannuleerd" | null;
	in?: ("aankomend" | "afgelopen" | "geannuleerd")[] | null;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
	/** Set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** Request path */
	path: string;
	/** Content type of request body */
	type?: ContentType;
	/** Query params */
	query?: QueryParamsType;
	/** Format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** Request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig,
	): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
					{}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === "object" && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		if (input instanceof FormData) {
			return input;
		}
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type ? { "Content-Type": type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @version 0.1-49558f0293bb94114d0b0d1e54cb511df6a3f654
 *
 *   Backend voor reserveer app
 * @title Reserveer backend API
 */
export class ReserveerApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	/**
	 * No description
	 *
	 * @tags routes
	 * @name Index
	 * @summary API health opvragen
	 * @request GET:/
	 */
	index = (params: RequestParams = {}) =>
		this.request<any, void>({
			path: `/`,
			method: "GET",
			...params,
		});

	abonnement = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name AbonnementVanLid
		 * @summary Ophalen van het abonnement van het ingelogde lid
		 * @request GET:/abonnement/lid
		 * @secure
		 */
		abonnementVanLid: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/abonnement/lid`,
				method: "GET",
				secure: true,
				...params,
			}),
	};
	arrangementen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name Arrangementen
		 * @summary Alle bestaande arrangementen ophalen
		 * @request GET:/arrangementen
		 * @secure
		 */
		arrangementen: (params: RequestParams = {}) =>
			this.request<IomodelsArrangement[], any>({
				path: `/arrangementen`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name ArrangementenVoorVestiging
		 * @summary Alle arrangementen voor de betreffende vestiging ophalen
		 * @request GET:/arrangementen/{vestigingId}
		 * @secure
		 */
		arrangementenVoorVestiging: (vestigingId: number, params: RequestParams = {}) =>
			this.request<IomodelsArrangement[], any>({
				path: `/arrangementen/${vestigingId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name ArrangementenVanLid
		 * @summary Arrangementen ophalen dat een lid heeft bij zijn/haar abonnement
		 * @request GET:/arrangementen/lid/{lidId}
		 * @secure
		 */
		arrangementenVanLid: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsArrangement[], any>({
				path: `/arrangementen/lid/${lidId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name UitgelichtArrangement
		 * @summary Haal het id op van het arrangement dat uitgelicht moet worden op de Home tab van de leden app. Deze wordt gehaald uit de arrangementen die beschikbaar zijn voor alle vestigingen
		 * @request GET:/arrangementen/uitgelicht
		 * @secure
		 */
		uitgelichtArrangement: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/arrangementen/uitgelicht`,
				method: "GET",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name UitgelichtArrangementVoorVestiging
		 * @summary Haal het id op van het arrangement dat uitgelicht moet worden op de Home tab van de leden app. Deze wordt voor de vestiging specifiek bepaald
		 * @request GET:/arrangementen/uitgelicht/{vestigingId}
		 * @secure
		 */
		uitgelichtArrangementVoorVestiging: (vestigingId: number, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/arrangementen/uitgelicht/${vestigingId}`,
				method: "GET",
				secure: true,
				...params,
			}),
	};
	arrangementwijzigen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name ArrangementWijzigen
		 * @summary Afsluiten/annuleren van arrangement door lid
		 * @request POST:/arrangementwijzigen
		 * @secure
		 */
		arrangementWijzigen: (types: IomodelsArrangementVanLidWijziging, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/arrangementwijzigen`,
				method: "POST",
				body: types,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	};
	vestigingen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name Vestigingen
		 * @summary Alle vestigingen met hun zalen ophalen
		 * @request GET:/vestigingen
		 */
		vestigingen: (params: RequestParams = {}) =>
			this.request<IomodelsVestiging[], any>({
				path: `/vestigingen`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	groepslessen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GroepslessenFilterType
		 * @summary Gefilterde groepslessen ophalen; een groepsles in het resultaat bevat tenminste de groepslesTypes die meegegeven zijn in het filter
		 * @request POST:/groepslessen
		 */
		groepslessenFilterType: (types: IomodelsGroepslesFilter, params: RequestParams = {}) =>
			this.request<IomodelsGroepslesUitgebreid[], void>({
				path: `/groepslessen`,
				method: "POST",
				body: types,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name Groepslessen
		 * @summary Alle groeplessen met hun details ophalen
		 * @request GET:/groepslessen
		 */
		groepslessen: (params: RequestParams = {}) =>
			this.request<IomodelsGroepslesUitgebreid[], any>({
				path: `/groepslessen`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name Groepsles
		 * @summary Een enkele groepsles met het gegeven id ophalen
		 * @request GET:/groepslessen/{groepslesId}
		 */
		groepsles: (groepslesId: number, params: RequestParams = {}) =>
			this.request<IomodelsGroepslesUitgebreid, void>({
				path: `/groepslessen/${groepslesId}`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	trainers = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GroepslessenVoorTrainer
		 * @summary Alle groepslessen ophalen die de betreffende trainer geeft
		 * @request GET:/trainers/{trainerId}/groepslessen
		 */
		groepslessenVoorTrainer: (trainerId: number, params: RequestParams = {}) =>
			this.request<IomodelsGroepslesUitgebreid[], any>({
				path: `/trainers/${trainerId}/groepslessen`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name TrainersFilter
		 * @summary Trainers ophalen met meegegeven filter met filteropties voor lesId en vestigingen.
		 * @request POST:/trainers
		 */
		trainersFilter: (trainerFilter: IomodelsTrainerFilter, params: RequestParams = {}) =>
			this.request<IomodelsTrainerResult[], void>({
				path: `/trainers`,
				method: "POST",
				body: trainerFilter,
				format: "json",
				...params,
			}),
	};
	trainersWeergave = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name TrainersVoorWeergave
		 * @summary Trainers ophalen, voor weergave, met meegegeven filter met filteropties voor lesId en vestigingen. ALleen trainers die gepubliceerd/private staan worden opgehaald
		 * @request POST:/trainers-weergave
		 */
		trainersVoorWeergave: (trainerFilter: IomodelsTrainerFilter, params: RequestParams = {}) =>
			this.request<IomodelsTrainerResult[], void>({
				path: `/trainers-weergave`,
				method: "POST",
				body: trainerFilter,
				format: "json",
				...params,
			}),
	};
	roosters = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name Publiceer
		 * @summary Publiceer het rooster met meegegeven roosterId. Deze actie heeft veel gevolgen!!
		 * - Alle lessen voor de komende 8 weken worden geupdate adhv dit nieuw gepubliceerde rooster
		 * - Als een les niet meer bestaat in het nieuwe gepubliceerde rooster, dan wordt deze verwijderd, net als de reserveringen die zijn gemaakt voor de les!
		 * @request POST:/roosters/{roosterId}/publiceer
		 * @secure
		 */
		publiceer: (roosterId: number, params: RequestParams = {}) =>
			this.request<IomodelsRoosterExtern, void>({
				path: `/roosters/${roosterId}/publiceer`,
				method: "POST",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name UpdateRooster
		 * @summary Een gewijzigd rooster indienen. De RoosterItems zijn Groepslessen of Fitnessafspraken, bij een FitnessAfspraak is groepslesId null.
		 * @request POST:/roosters
		 * @secure
		 */
		updateRooster: (roosterExtern: IomodelsRoosterExtern, params: RequestParams = {}) =>
			this.request<
				IomodelsRoosterExtern,
				void | IomodelsOverlappendeItemsError | IomodelsBezetTijdslotError
			>({
				path: `/roosters`,
				method: "POST",
				body: roosterExtern,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetRoosters
		 * @summary Alle roosters ophalen. Alleen de metadata, dus zonder groepslessen/fitnessafspraken.
		 * @request GET:/roosters
		 * @secure
		 */
		getRoosters: (params: RequestParams = {}) =>
			this.request<IomodelsRoosterExtern[], any>({
				path: `/roosters`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetRoosterMetItems
		 * @summary Haal 1 specifiek rooster inclusief groepslessen/fitnessafspraken (roosterItems) op.
		 * @request GET:/roosters/{roosterId}
		 * @secure
		 */
		getRoosterMetItems: (roosterId: number, params: RequestParams = {}) =>
			this.request<IomodelsRoosterExtern, void>({
				path: `/roosters/${roosterId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
	roosteritems = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name DeleteRoosterItem
		 * @summary Verwijder het roosterItem met het meegegeven roosterItemId
		 * @request DELETE:/roosteritems/{roosterItemId}
		 * @secure
		 */
		deleteRoosterItem: (roosterItemId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/roosteritems/${roosterItemId}`,
				method: "DELETE",
				secure: true,
				...params,
			}),
	};
	lessen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name WijzigLes
		 * @summary Wijzig een specifieke les. De datum/tijd kan gewijzigd worden en/of de trainer. Overige velden zijn niet te wijzigen.
		 * @request POST:/lessen
		 * @secure
		 */
		wijzigLes: (les: IomodelsLes, params: RequestParams = {}) =>
			this.request<IomodelsLes, void>({
				path: `/lessen`,
				method: "POST",
				body: les,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name FilterLessen
		 * @summary Haal lessen in een specifieke week op, eventuele filtering op; of de aanwezigheidslijst is ingediend, type groepsles, kenmerken, attributen, duur en vestiging
		 * @request POST:/lessen/week/{weekNr}/jaar/{jaartal}
		 */
		filterLessen: (
			weekNr: number,
			jaartal: number,
			filter: IomodelsLessenFilter,
			params: RequestParams = {},
		) =>
			this.request<IomodelsLesUitgebreid[], void>({
				path: `/lessen/week/${weekNr}/jaar/${jaartal}`,
				method: "POST",
				body: filter,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name LessenVoorRange
		 * @summary Haal lessen op die binnen de meegegeven start/eind datum vallen (inclusief begin en eind), met eventuele filtering; of de aanwezigheidslijst is ingediend, type groepsles, kenmerken, attributen, duur en vestiging. Als er metAanwezigheid = true wordt meegestuurd, wordt in het LesUitgebreid object de AanwezigheidslijstExtern toegevoegd.
		 * @request POST:/lessen/range
		 * @secure
		 */
		lessenVoorRange: (
			filterMetDatumRange: IomodelsLessenFilterMetDatumRange,
			params: RequestParams = {},
		) =>
			this.request<IomodelsLesUitgebreid[], void>({
				path: `/lessen/range`,
				method: "POST",
				body: filterMetDatumRange,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name AnnuleerLes
		 * @summary Annuleer een specifieke les, eventueel met reden.
		 * @request POST:/lessen/annuleren
		 * @secure
		 */
		annuleerLes: (annulering: IomodelsAnnuleringExtern, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/lessen/annuleren`,
				method: "POST",
				body: annulering,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerstuurLesBericht
		 * @summary Verzend een bericht voor een specifieke les aan alle, op dit moment, ingeschreven leden. Les moet aankomend zijn (en kan daarnaast ook de status geannuleerd hebben).
		 * @request POST:/lessen/bericht
		 * @secure
		 */
		verstuurLesBericht: (bericht: IomodelsLesBericht, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/lessen/bericht`,
				method: "POST",
				body: bericht,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerwijderAnnulering
		 * @summary Ongedaan maken van een les annulering
		 * @request DELETE:/lessen/annuleren/{lesId}
		 * @secure
		 */
		verwijderAnnulering: (lesId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/lessen/annuleren/${lesId}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name LijstVoorLes
		 * @summary Haal de aanwezigheidslijst voor een specifieke les op
		 * @request GET:/lessen/{lesId}/aanwezigheidslijst
		 * @secure
		 */
		lijstVoorLes: (lesId: number, params: RequestParams = {}) =>
			this.request<IomodelsAanwezigheidsLijstExtern, void>({
				path: `/lessen/${lesId}/aanwezigheidslijst`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetLes
		 * @summary Haal de specifieke les voor het meegegeven id op
		 * @request GET:/lessen/{lesId}
		 * @secure
		 */
		getLes: (lesId: number, params: RequestParams = {}) =>
			this.request<IomodelsLesUitgebreid, void>({
				path: `/lessen/${lesId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name UpdateAanwezigheidsLijst
		 * @summary Update de aanwezigheidslijst voor een les door een trainer. Voor elk lid kan de status van de aanwezigheid aangemeld/afgemeld/geweest/niet geweest worden. Straftijden worden pas doorgevoerd wanneer de lijst definitief gemaakt wordt.
		 * @request POST:/lessen/aanwezigheidslijst
		 * @secure
		 */
		updateAanwezigheidsLijst: (
			aanwezigheidslijst: IomodelsAanwezigheidsLijstExtern,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/lessen/aanwezigheidslijst`,
				method: "POST",
				body: aanwezigheidslijst,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name UpdateAanwezigheid
		 * @summary Aanmelden/afmelden voor een specifieke les door een lid. Dit kan ook aan/afmelden van een herhalende reservering zijn voor het roosteritem waar deze specifieke les bijhoort. Als een lid straftijd heeft of de les geannuleerd/afgelopen is kan aanmelden niet.
		 * @request POST:/lessen/{lesId}/aanwezigheid
		 * @secure
		 */
		updateAanwezigheid: (
			lesId: number,
			aanwezigheid: IomodelsAanwezigheidExtern,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/lessen/${lesId}/aanwezigheid`,
				method: "POST",
				body: aanwezigheid,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name AanwezigheidsLijstDefinitief
		 * @summary Maak de aanwezigheidslijst voor een les definitief. Dit is het moment waarop straftijden worden doorgevoerd voor leden die, met aanmelding, niet zijn komen opdagen.
		 * @request POST:/lessen/{lesId}/aanwezigheidslijst/definitief
		 * @secure
		 */
		aanwezigheidsLijstDefinitief: (lesId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/lessen/${lesId}/aanwezigheidslijst/definitief`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name LessenVoorTrainer
		 * @summary Haal specifieke lessen voor trainer met trainerId in de meegegeven week op. _Let op_ lessen worden max 8 weken vooruit aangemaakt.
		 * @request GET:/lessen/{trainerId}/week/{weekNr}
		 */
		lessenVoorTrainer: (trainerId: number, weekNr: number, params: RequestParams = {}) =>
			this.request<IomodelsLesUitgebreid[], any>({
				path: `/lessen/${trainerId}/week/${weekNr}`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	fitnessafspraken = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name AfsprakenVoorRange
		 * @summary Haal afspraken met bijbehorende aanwezigheid op die binnen de meegegeven start/eind datum vallen (inclusief begin en eind)
		 * @request POST:/fitnessafspraken/range
		 * @secure
		 */
		afsprakenVoorRange: (range: IomodelsAppointmentFilter, params: RequestParams = {}) =>
			this.request<IomodelsFitnessAfspraakExtern[], void>({
				path: `/fitnessafspraken/range`,
				method: "POST",
				body: range,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetAfspraak
		 * @summary Haal de fitness afspraak voor het meegegeven id op
		 * @request GET:/fitnessafspraken/{afspraakId}
		 * @secure
		 */
		getAfspraak: (afspraakId: number, params: RequestParams = {}) =>
			this.request<IomodelsFitnessAfspraakExtern, void>({
				path: `/fitnessafspraken/${afspraakId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetAfsprakenVoorLid
		 * @summary Haal aankomende fitnessafspraken waar het lid voor is aangemeld op
		 * @request GET:/fitnessafspraken/lid/{lidId}
		 */
		getAfsprakenVoorLid: (
			lidId: number,
			query?: {
				/** @format date-time */
				datum_equal?: string | null;
				/** @format date-time */
				datum_gte?: string | null;
				/** @format date-time */
				datum_lte?: string | null;
				/** @format date-time */
				datum_gt?: string | null;
				/** @format date-time */
				datum_lt?: string | null;
				/**
				 * Number of the records (default 10)
				 *
				 * @format int32
				 */
				limit?: number | null;
				/** OrderBy datum */
				orderby_datum?: "asc" | "desc";
				/** @default "aankomend" */
				status?: "aankomend" | "afgelopen" | "geannuleerd";
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsFitnessAfspraakExtern[], any>({
				path: `/fitnessafspraken/lid/${lidId}`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name WijzigAfspraak
		 * @summary Update een specifieke fitness afspaak
		 * @request POST:/fitnessafspraken
		 * @secure
		 */
		wijzigAfspraak: (fitnessAfspraak: IomodelsFitnessAfspraakExtern, params: RequestParams = {}) =>
			this.request<IomodelsFitnessAfspraakExtern, void>({
				path: `/fitnessafspraken`,
				method: "POST",
				body: fitnessAfspraak,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name Indienen
		 * @summary Indienen van een specifieke fitness afspaak
		 * @request POST:/fitnessafspraken/{afspraakId}/indienen
		 * @secure
		 */
		indienen: (
			afspraakId: number,
			aanwezigheid: IomodelsAanwezigheidExtern,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/fitnessafspraken/${afspraakId}/indienen`,
				method: "POST",
				body: aanwezigheid,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name AnnuleerAfspraak
		 * @summary Annuleer een specifieke fitness afspraak, eventueel met reden.
		 * @request POST:/fitnessafspraken/annuleren
		 * @secure
		 */
		annuleerAfspraak: (annulering: IomodelsAnnuleringExtern, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/fitnessafspraken/annuleren`,
				method: "POST",
				body: annulering,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name UpdateAanwezigheid
		 * @summary Update de aanwezigheid voor een fitness afspraak. Dit kan aanmelden/afmelden zijn, maar ook het updaten van de boolean waarden voor meting/trainingsschema/doelOpstellen of een opmerking wijzigen
		 * @request POST:/fitnessafspraken/{afspraakId}/aanwezigheid
		 * @secure
		 */
		updateAanwezigheid: (
			afspraakId: number,
			fitnessAfspraakAanwezigheid: IomodelsAanwezigheidFitnessAfspraakExtern,
			params: RequestParams = {},
		) =>
			this.request<IomodelsFitnessAfspraakExtern, void>({
				path: `/fitnessafspraken/${afspraakId}/aanwezigheid`,
				method: "POST",
				body: fitnessAfspraakAanwezigheid,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	lid = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name LidAfspraken
		 * @summary Haal aankomende fitnessafspraken waar het lid voor is aangemeld op, op basis van authenticatie wordt het ingelogde lid bepaald
		 * @request GET:/lid/afspraken
		 * @secure
		 */
		lidAfspraken: (params: RequestParams = {}) =>
			this.request<IomodelsFitnessAfspraakExtern, any>({
				path: `/lid/afspraken`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name LidAfgelopenfAspraken
		 * @summary Haal afgelopen fitnessafspraken waar het lid voor was aangemeld op, op basis van authenticatie wordt het ingelogde lid bepaald
		 * @request GET:/lid/afgelopen-afspraken
		 * @secure
		 */
		lidAfgelopenfAspraken: (params: RequestParams = {}) =>
			this.request<IomodelsFitnessAfspraakExtern, any>({
				path: `/lid/afgelopen-afspraken`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lid endpoints
		 * @name LidInfo
		 * @summary Haal de info van het ingelogde lid op
		 * @request GET:/lid
		 * @secure
		 */
		lidInfo: (params: RequestParams = {}) =>
			this.request<IomodelsLid, void>({
				path: `/lid`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lid endpoints
		 * @name LidLaatsteMeting
		 * @summary Haal de info van de laatste meting van het ingelogde lid op
		 * @request GET:/lid/meting
		 * @secure
		 */
		lidLaatsteMeting: (params: RequestParams = {}) =>
			this.request<DaosModelsMeting, void>({
				path: `/lid/meting`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lid endpoints
		 * @name LidMetingen
		 * @summary Haal de info van alle metingen van het ingelogde lid op
		 * @request GET:/lid/metingen
		 * @secure
		 */
		lidMetingen: (params: RequestParams = {}) =>
			this.request<DaosModelsMeting[], void>({
				path: `/lid/metingen`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lid endpoints
		 * @name LidBezoeken
		 * @summary Haal alle bezoeken van het ingelogde lid in de afgelopen 12 weken op
		 * @request GET:/lid/bezoeken
		 * @secure
		 */
		lidBezoeken: (params: RequestParams = {}) =>
			this.request<IomodelsLidBezoeken, any>({
				path: `/lid/bezoeken`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lid endpoints
		 * @name UploadProfielFoto
		 * @summary Upload een nieuwe profiel foto door het lid
		 * @request POST:/lid/profiel-foto
		 * @secure
		 */
		uploadProfielFoto: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/lid/profiel-foto`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lid endpoints
		 * @name GetProfielFoto
		 * @summary Ophalen profiel foto door het lid
		 * @request GET:/lid/profiel-foto
		 * @secure
		 */
		getProfielFoto: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/lid/profiel-foto`,
				method: "GET",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lid endpoints
		 * @name GetProfielFotoVoorLid
		 * @summary Ophalen profiel foto voor een willekeurig lid
		 * @request GET:/lid/profiel-foto/{lidId}
		 */
		getProfielFotoVoorLid: (lidId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/lid/profiel-foto/${lidId}`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lid endpoints
		 * @name UpdateMember
		 * @summary Updates a member
		 * @request PATCH:/lid/{id}
		 * @secure
		 */
		updateMember: (id: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/lid/${id}`,
				method: "PATCH",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lid endpoints
		 * @name GetKickOffStatus
		 * @summary Heeft lid kickoff zich aangemeld voor kickoff? Zoja, welke afspraak (of afspraken) bestaan er met kickoff = true waar het lid aan gelinked is
		 * @request GET:/lid/kickoff
		 * @secure
		 */
		getKickOffStatus: (params: RequestParams = {}) =>
			this.request<IomodelsLidKickOffStatus, any>({
				path: `/lid/kickoff`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
	geslotendagen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name UpdateGeslotenDagen
		 * @summary Lijst gewijzigde/nieuwe gesloten dagen wordt opgeslagen in de db.
		 * @request POST:/geslotendagen
		 * @secure
		 */
		updateGeslotenDagen: (GeslotenDag: IomodelsGeslotenDag[], params: RequestParams = {}) =>
			this.request<IomodelsGeslotenDag[], void>({
				path: `/geslotendagen`,
				method: "POST",
				body: GeslotenDag,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name AlleGeslotenDagen
		 * @summary Haal alle gesloten dagen op
		 * @request GET:/geslotendagen
		 */
		alleGeslotenDagen: (params: RequestParams = {}) =>
			this.request<IomodelsGeslotenDag[], any>({
				path: `/geslotendagen`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	deelnames = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name AantalDeelnamesGroepslesVoorLid
		 * @summary Haal voor een lid het aantal deelnames aan een bepaalde les over de meegegeven periode op
		 * @request GET:/deelnames/{groepslesId}/lid/{lidId}
		 */
		aantalDeelnamesGroepslesVoorLid: (
			groepslesId: number,
			lidId: number,
			query: {
				/**
				 * Dagen range waarover het aantal deelnames bepaald moet worden (dus t/m x dagen terug)
				 *
				 * @format int32
				 */
				dagen: number;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsDeelnamesResult, void>({
				path: `/deelnames/${groepslesId}/lid/${lidId}`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	reserveringen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name ToekomstigeReserveringen
		 * @summary Haal toekomstige reserveringen voor een lid op.
		 * @request GET:/reserveringen/{lidId}
		 */
		toekomstigeReserveringen: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsLesUitgebreid[], any>({
				path: `/reserveringen/${lidId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name HistorischeReserveringen
		 * @summary Haal historische reserveringen voor een lid op voor een bepaald aantal dagen terug.
		 * @request GET:/reserveringen/historie/{lidId}
		 */
		historischeReserveringen: (
			lidId: number,
			query: {
				/**
				 * Range indicatie. Historische reserveringen t/m x dagen terug
				 *
				 * @format int32
				 */
				dagen: number;
			},
			params: RequestParams = {},
		) =>
			this.request<IomodelsLesUitgebreid[], any>({
				path: `/reserveringen/historie/${lidId}`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name HerhalendeReserveringen
		 * @summary Haal roosterItems op waar het lid een herhalende reservering voor heeft
		 * @request GET:/reserveringen/herhalend/{lidId}
		 */
		herhalendeReserveringen: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsRoosterItemExtern[], any>({
				path: `/reserveringen/herhalend/${lidId}`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	pushtoken = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name RegisterToken
		 * @summary registeren push token
		 * @request POST:/pushtoken
		 * @secure
		 */
		registerToken: (PushToken: IomodelsPushToken[], params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pushtoken`,
				method: "POST",
				body: PushToken,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	};
	notificatievoorkeuren = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name UpdateNotificatieVoorkeuren
		 * @summary update de notificatie voorkeuren voor een lid
		 * @request POST:/notificatievoorkeuren
		 * @secure
		 */
		updateNotificatieVoorkeuren: (
			notificatie: IomodelsNotificatieInstellingExtern[],
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/notificatievoorkeuren`,
				method: "POST",
				body: notificatie,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetNotificatieVoorkeuren
		 * @summary ophalen van de notificatie voorkeuren voor een lid
		 * @request GET:/notificatievoorkeuren
		 * @secure
		 */
		getNotificatieVoorkeuren: (params: RequestParams = {}) =>
			this.request<IomodelsNotificatieInstellingExtern[], void>({
				path: `/notificatievoorkeuren`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
	usernotifications = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetNotifications
		 * @summary Haal de niet verwijderde notificaties voor het huidige lid op
		 * @request GET:/usernotifications/{lidId}
		 * @secure
		 */
		getNotifications: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsNotificatieExtern[], any>({
				path: `/usernotifications/${lidId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name NotificatieOpGelezen
		 * @summary Zet de user notificatie van het betreffende id op gelezen
		 * @request POST:/usernotifications/{notificatieId}/gelezen
		 * @secure
		 */
		notificatieOpGelezen: (notificatieId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/usernotifications/${notificatieId}/gelezen`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name NotificatieOpVerwijderd
		 * @summary Zet de user notificatie van het betreffende id op verwijderd
		 * @request POST:/usernotifications/{notificatieId}/verwijderd
		 * @secure
		 */
		notificatieOpVerwijderd: (notificatieId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/usernotifications/${notificatieId}/verwijderd`,
				method: "POST",
				secure: true,
				...params,
			}),
	};
	notifications = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerzendApplicatieNotificatie
		 * @summary Versturen van een notificatie naar een lid vanuit een PFG applicatie
		 * @request POST:/notifications/applicatie-notificatie
		 * @secure
		 */
		verzendApplicatieNotificatie: (
			applicatieNotificatie: IomodelsApplicatieNotificatie,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/notifications/applicatie-notificatie`,
				method: "POST",
				body: applicatieNotificatie,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	};
	oauth2 = {
		/**
		 * Afhankelijk van de geidentificeerde gebruiker worden er een aantal scopes terug gestuurd.
		 * Deze bepalen toegang tot de functies: * een lid heeft altijd alleen de scope `lid` * een
		 * trainer heeft altijd de scopes `trainer` en `lid` * een club aanvoerder heeft altijd de
		 * scopes `club_aanvoerder`, `trainer` en `lid`
		 *
		 * @tags routes
		 * @name AccessToken
		 * @summary aanvragen voor een access token m.b.v. een authorization code of een refresh token
		 * @request POST:/oauth2/access_token
		 */
		accessToken: (
			data: {
				/**
				 * Type request, ondersteunt of `authorization_code`, `refresh_token`, `password` of
				 * `client_credentials`
				 */
				grant_type: "authorization_code" | "refresh_token" | "password";
				/** Het client-id */
				client_id: string;
				/** Het client-secret */
				client_secret: string;
				/** Authorization code (verplicht indien `grant_type` is `authorization_code`) */
				code?: string;
				/** Geldig refresh token (verplicht indien `grant_type` is `refresh_token`) */
				refresh_token?: string;
				/** De gebruikersnaam (verplicht indien `grant_type` is `password`) */
				username?: string;
				/** Bijbehorende wachtwoord (verplicht indien `grant_type` is `password`) */
				password?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<OauthToken, OauthError>({
				path: `/oauth2/access_token`,
				method: "POST",
				body: data,
				type: ContentType.UrlEncoded,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name Authenticated
		 * @summary checken of de aanroepen partij geldige authenticatie heeft
		 * @request GET:/oauth2/authenticated
		 */
		authenticated: (params: RequestParams = {}) =>
			this.request<OauthUser, void>({
				path: `/oauth2/authenticated`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	tijdsloten = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetTijdslotenVoorRange
		 * @summary Haal voor betreffende datum range en evt vestiging alle corona tijdsloten op.
		 * @request POST:/tijdsloten
		 * @secure
		 */
		getTijdslotenVoorRange: (range: IomodelsTijdslotenFilter, params: RequestParams = {}) =>
			this.request<IomodelsCoronaTijdslot[], void>({
				path: `/tijdsloten`,
				method: "POST",
				body: range,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GereserveerdeTijdsloten
		 * @summary Haal toekomstige gereserveerde tijdsloten voor een lid op.
		 * @request GET:/tijdsloten/{lidId}
		 */
		gereserveerdeTijdsloten: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsLesUitgebreid[], any>({
				path: `/tijdsloten/${lidId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name AanwezigheidsLijstTijdslotDefinitief
		 * @summary Maak de aanwezigheidslijst voor een tijdslot definitief. Dit is het moment waarop straftijden voor tijdsloten worden doorgevoerd voor leden die, met aanmelding, niet zijn komen opdagen.
		 * @request POST:/tijdsloten/{tijdslotId}/aanwezigheidslijst/definitief
		 * @secure
		 */
		aanwezigheidsLijstTijdslotDefinitief: (tijdslotId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tijdsloten/${tijdslotId}/aanwezigheidslijst/definitief`,
				method: "POST",
				secure: true,
				...params,
			}),
	};
	persoonlijkeTrainingen = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name NieuweTrainingToevoegen
		 * @summary Een nieuwe gepersonaliseerde training voor meegegeven lid. De meegestuurde code bevat alle info om de training van de FitOut Sport server te kunnen halen
		 * @request POST:/persoonlijke-trainingen
		 */
		nieuweTrainingToevoegen: (
			nieuweGepersonaliseerdeTraining: IomodelsNieuweGepersonaliseerdeTraining,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/persoonlijke-trainingen`,
				method: "POST",
				body: nieuweGepersonaliseerdeTraining,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name TrainingsProgrammas
		 * @summary Haal de persoonlijke trainingsschemas voor lid op (allemaal, voltooid en niet voltooid)
		 * @request GET:/persoonlijke-trainingen/{lidId}
		 * @secure
		 */
		trainingsProgrammas: (lidId: number, params: RequestParams = {}) =>
			this.request<IomodelsTrainingsProgrammaExtern[], void>({
				path: `/persoonlijke-trainingen/${lidId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetTrainingsDag
		 * @summary Haal workout op voor lid + programma + week + dag
		 * @request GET:/persoonlijke-trainingen/{lidId}/{programmaId}/dag/{week}/{dag}
		 * @secure
		 */
		getTrainingsDag: (
			lidId: number,
			programmaId: number,
			week: number,
			dag: number,
			params: RequestParams = {},
		) =>
			this.request<IomodelsTrainingsDagExtern, void>({
				path: `/persoonlijke-trainingen/${lidId}/${programmaId}/dag/${week}/${dag}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetHuidigeTrainingsDag
		 * @summary Haal workout op voor waar het lid was gebleven voor dit schema
		 * @request GET:/persoonlijke-trainingen/huidige-dag/{lidId}/{programmaId}
		 * @secure
		 */
		getHuidigeTrainingsDag: (lidId: number, programmaId: number, params: RequestParams = {}) =>
			this.request<IomodelsTrainingsDagExtern, void>({
				path: `/persoonlijke-trainingen/huidige-dag/${lidId}/${programmaId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name TrainingAfronden
		 * @summary Workout afronden, evt met voortgang per oefening
		 * @request POST:/persoonlijke-trainingen/afronden
		 */
		trainingAfronden: (
			trainingsProgrammaVoortgangExtern: IomodelsTrainingsProgrammaVoortgangExtern,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/persoonlijke-trainingen/afronden`,
				method: "POST",
				body: trainingsProgrammaVoortgangExtern,
				type: ContentType.Json,
				...params,
			}),
	};
	welkomstformulier = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name PostWelkomstFormulier
		 * @summary Welkomst formulier toevoegen, lid komt enkel voor informatie. Het formulier wordt opgeslagen, email adres wordt toegevoegd aan MailChimp lijst, Rutger+aanvoerder worden gemaild ter info.
		 * @request POST:/welkomstformulier
		 */
		postWelkomstFormulier: (
			welkomstFormulier: IomodelsWelkomstFormulierExtern,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/welkomstformulier`,
				method: "POST",
				body: welkomstFormulier,
				...params,
			}),
	};
	proeflesDeelname = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name PostProeflesDeelname
		 * @summary Lid doet een proef les mee, hier is wat meer informatie voor nodig. Het formulier wordt opgeslagen, email adres wordt toegevoegd aan MailChimp lijst, Rutger+aanvoerder worden gemaild ter info. Daarnaast wordt er een tijdelijk lid aangemaakt en deze wordt aangemeld voor de betreffende les.
		 * @request POST:/proefles-deelname
		 */
		postProeflesDeelname: (proefsporterLes: IomodelsProefsporterLes, params: RequestParams = {}) =>
			this.request<IomodelsTijdelijkLidId, void>({
				path: `/proefles-deelname`,
				method: "POST",
				body: proefsporterLes,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	proefFitness = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name PostProefFitness
		 * @summary Lid komt proef fitnessen. Het formulier wordt opgeslagen, email adres wordt toegevoegd aan MailChimp lijst, Rutger+aanvoerder worden gemaild ter info.
		 * @request POST:/proef-fitness
		 */
		postProefFitness: (
			welkomstFormulier: IomodelsWelkomstFormulierExtern,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/proef-fitness`,
				method: "POST",
				body: welkomstFormulier,
				...params,
			}),
	};
	proefTijdslot = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name PostProefTijdslot
		 * @summary Lid doet een tijdslot mee, hier is wat meer informatie voor nodig. Het formulier wordt opgeslagen, email adres wordt toegevoegd aan MailChimp lijst, Rutger+aanvoerder worden gemaild ter info. Daarnaast wordt er een tijdelijk lid aangemaakt en deze wordt aangemeld voor het betreffende tijdslot.
		 * @request POST:/proef-tijdslot
		 */
		postProefTijdslot: (
			proefsporterTijdslot: IomodelsProefsporterTijdslot,
			params: RequestParams = {},
		) =>
			this.request<IomodelsTijdelijkLidId, void>({
				path: `/proef-tijdslot`,
				method: "POST",
				body: proefsporterTijdslot,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	verwijzingOpties = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetVerwijzingOpties
		 * @summary De opties die de backend kent voor nieuw lid verwijzing. Lijst met strings.
		 * @request GET:/verwijzing-opties
		 */
		getVerwijzingOpties: (params: RequestParams = {}) =>
			this.request<DaosModelsNieuwLidVerwijzing[], any>({
				path: `/verwijzing-opties`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	verstuurBrochure = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerstuurBrochure
		 * @summary Verstuur een informatieve brochure aan een lid.
		 * @request POST:/verstuur-brochure
		 */
		verstuurBrochure: (brochureVoorLid: IomodelsBrochureVoorLid, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/verstuur-brochure`,
				method: "POST",
				body: brochureVoorLid,
				type: ContentType.Json,
				...params,
			}),
	};
	verstuurPtAanvraag = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerstuurPTaanvraag
		 * @summary Verstuur een PT aanvraag aan aanvoerder van vestiging uit naam van lid
		 * @request POST:/verstuur-pt-aanvraag/{lidId}/vestiging/{vestigingId}
		 */
		verstuurPTaanvraag: (lidId: number, vestigingId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/verstuur-pt-aanvraag/${lidId}/vestiging/${vestigingId}`,
				method: "POST",
				...params,
			}),
	};
	verstuurFysioMail = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name VerstuurFysioMail
		 * @summary Verstuur een mail naar een externe fysio partij dat een lid interesse heeft in fysio
		 * @request POST:/verstuur-fysio-mail/{lidId}/vestiging/{vestigingId}
		 */
		verstuurFysioMail: (lidId: number, vestigingId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/verstuur-fysio-mail/${lidId}/vestiging/${vestigingId}`,
				method: "POST",
				...params,
			}),
	};
	tijdelijkLid = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name MaakTijdelijkLidAan
		 * @summary Maak een tijdelijk lid aan
		 * @request POST:/tijdelijk-lid
		 */
		maakTijdelijkLidAan: (tijdelijkLidInfo: IomodelsTijdelijkLidInfo, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/tijdelijk-lid`,
				method: "POST",
				body: tijdelijkLidInfo,
				type: ContentType.Json,
				...params,
			}),
	};
	consumables = {
		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetConsumables
		 * @summary Alle consumables ophalen
		 * @request GET:/consumables
		 */
		getConsumables: (params: RequestParams = {}) =>
			this.request<IomodelsConsumable[], any>({
				path: `/consumables`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetConsumablesVoorVestiging
		 * @summary Alle consumables ophalen voor een gegeven vestiging
		 * @request GET:/consumables/{vestigingId}
		 */
		getConsumablesVoorVestiging: (vestigingId: number, params: RequestParams = {}) =>
			this.request<IomodelsConsumable[], any>({
				path: `/consumables/${vestigingId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name AddConsumableVoorLid
		 * @summary Consumable "aankoop" registreren voor lid
		 * @request POST:/consumables/{vestigingId}/lid/{lidId}/{consumableId}
		 * @secure
		 */
		addConsumableVoorLid: (
			lidId: number,
			consumableId: number,
			vestigingId: number,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/consumables/${vestigingId}/lid/${lidId}/${consumableId}`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags routes
		 * @name GetConsumablesVoorLid
		 * @summary Alle "gekochte" consumables voor lid ophalen
		 * @request GET:/consumables/lid/{lidId}
		 * @secure
		 */
		getConsumablesVoorLid: (lidId: number, params: RequestParams = {}) =>
			this.request<DaosModelsConsumableVoorLid[], any>({
				path: `/consumables/lid/${lidId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
}
