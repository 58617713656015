<template>
	<div>
		<header class="pfg-header">
			<div class="pfg-header_heading">
				<h1 class="missing-info-header">Er ontbreken gegevens!</h1>
			</div>
			<span>De volgende informatie ontbreekt nog bij deze sporter:</span>
		</header>

		<hr class="v_less-margin" />

		<div class="missing-info-container">
			<div
				v-for="item in items"
				:key="item.property"
				class="missing-info-container_item"
				@click="onPropertyClicked(item.property)"
			>
				<div class="missing-info-container_icon">
					<pfg-icon :icon-id="item.icon" width="2em" height="2em" />
				</div>
				<div class="missing-info-container_content">
					<b>{{ item.title }}</b>
					<p>{{ item.description }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ModalMissingInfo",
	props: {
		/**
		 * @type {Object}
		 * @property {String[]} missingProperties - Array of missing properties (doelfrequentie, coach,
		 *   doelgroep, medischeGegevens)
		 * @property {(property) => void} callback - Callback function to call when a missing property
		 *   is clicked
		 */
		data: {
			type: Object,
			required: true,
		},
		callback: {
			type: Function,
			required: true,
		},
	},

	computed: {
		items() {
			const _items = [];

			if (this.isMissing("doelfrequentie")) {
				_items.push({
					title: "Doelfrequentie",
					property: "doelfrequentie",
					icon: "icon_frequentie",
					description: "Dit lid heeft nog geen doelfrequentie.",
				});
			}

			if (this.isMissing("coach")) {
				_items.push({
					title: "Coach",
					property: "coach",
					icon: "icon_coaches",
					description: "Dit lid heeft nog geen coach.",
				});
			}

			if (this.isMissing("doelgroep")) {
				_items.push({
					title: "Doelgroep",
					property: "doelgroep",
					icon: "icon_live",
					description: "Dit lid heeft nog geen doelgroep.",
				});
			}

			if (this.isMissing("medischeGegevens")) {
				_items.push({
					title: "Medische gegevens",
					property: "medischeGegevens",
					icon: "icon_schemas",
					description: "Er ontbreken nog medische gegevens.",
				});
			}

			return _items;
		},
	},

	methods: {
		isMissing(property) {
			return this.data.missingProperties.includes(property);
		},
		onPropertyClicked(property) {
			this.callback(property);
			this.$emit("close");
		},
	},
};
</script>

<style lang="scss" scoped>
.missing-info-header {
	text-transform: none;
}

.missing-info-container {
	display: flex;
	flex-direction: column;
	gap: 10px;

	&_item {
		display: flex;
		align-items: center;
		gap: 20px;
		padding: 1em;
		background-color: $brand-primary-lightester;
		border-radius: 1em;
		cursor: pointer;

		&:hover {
			background-color: $brand-primary-lightest;
		}

		&_icon {
			height: 100%;
		}

		/* &_content {} */
	}

	p {
		padding: 0;
		margin: 0;
	}
}

.actions {
	margin-top: 20px;
	margin-left: 0;
}
</style>
