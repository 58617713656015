import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { setDefaultOptions } from "date-fns";
import { nl } from "date-fns/locale";
// @ts-expect-error idle-vue is not typed
import IdleVue from "idle-vue";
import Cookies from "js-cookie";
import moment from "moment";
import "script-loader!foundation-sites/dist/js/foundation.min";
import "script-loader!jquery/dist/jquery.min";
import "script-loader!motion-ui/dist/motion-ui.min";
import "script-loader!what-input/dist/what-input.min";
import VeeValidate from "vee-validate";
// @ts-expect-error vue-analytics is not typed
import VueAnalytics from "vue-analytics";
// @ts-expect-error vue-highcharts is not typed because it is an old version
import VueHighcharts from "vue-highcharts";
import App from "@/App.vue";
import { performForAllApis } from "@/lib/backend";
import { config } from "@/lib/config";
import {
	COOKIE_ACCESS_TOKEN,
	COOKIE_REFRESH_TOKEN,
	COOKIE_SCOPE,
	COOKIE_TOKEN_TYPE,
} from "@/lib/constants/cookies";
import { LOGGING, NODE_ENV } from "@/lib/constants/env";
import { LS_LIVEFEED_COACHINGSGROEP } from "@/lib/constants/localstorage";
import "@/lib/filters";
import { highchartsSettings } from "@/lib/highchartsSettings";
import { plugin as twMergeDirective } from "@/lib/pfg/utils/twmerge.directive";
import { queryClient } from "@/lib/query/client";
import { twMerge } from "@/lib/style/twmerge.config";
import { pinia } from "@/pinia";
import { useAuthStore } from "@/pinia/auth";
import { useLiveFeedStore } from "@/pinia/liveFeed";
import "@/registerSentry";
import "@/registerServiceWorker";
import router from "@/router";
import { store } from "@/store";
import "@/styles/tailwind.css";
import { logInfo } from "@/utils/logInfo";
import { registerComponents } from "./registerComponents";

setDefaultOptions({ locale: nl });

Vue.use(VueCompositionApi);

Vue.use(twMergeDirective, twMerge);

Vue.use(VueQueryPlugin, {
	queryClient,
	enableDevtoolsV6Plugin: true,
});

registerComponents();

window.moment = moment;

const eventsHub = new Vue();

Vue.use(IdleVue, {
	// 10 minutes until idle
	idleTime: 60 * 10 * 1000,
	eventEmitter: eventsHub,
});
Vue.use(VueHighcharts);
Vue.use(VeeValidate);

if (NODE_ENV !== "development") {
	Vue.use(VueAnalytics, {
		id: config.google_analytics,
		router,
	});
}

moment.locale("nl");

new Vue({
	data: {},
	created() {
		// @ts-expect-error `this.Highcharts` doesn't exist
		this.Highcharts.setOptions(highchartsSettings);
	},
	pinia,
	router,
	store,
	render: (h) => h(App),
	// @ts-expect-error OnIdle doesn't seem to be definded
	async onIdle() {
		const auth = useAuthStore();
		await auth.signOut();
	},
}).$mount("#app");

const auth = useAuthStore();
auth.init();
// Subscribe to actions to update and persist the state
auth.$onAction(({ name, store, after }) => {
	switch (name) {
		case "authorize":
		case "deauthorize": {
			after(() => {
				const { accessToken, refreshToken, scope, tokenType, expiresIn = 3600 } = store;

				if (accessToken === undefined) {
					Cookies.remove(COOKIE_ACCESS_TOKEN);
				} else {
					Cookies.set(COOKIE_ACCESS_TOKEN, accessToken, {
						expires: (expiresIn ?? 3600) / (60 * 60 * 24),
					});
				}

				if (refreshToken === undefined) {
					Cookies.remove(COOKIE_REFRESH_TOKEN);
				} else {
					Cookies.set(COOKIE_REFRESH_TOKEN, refreshToken, {
						expires: 365,
					});
				}

				if (scope === undefined) {
					Cookies.remove(COOKIE_SCOPE);
				} else {
					Cookies.set(COOKIE_SCOPE, scope ?? "", {
						expires: (expiresIn ?? 3600) / (60 * 60 * 24),
					});
				}

				if (tokenType === undefined) {
					Cookies.remove(COOKIE_TOKEN_TYPE);
				} else {
					Cookies.set(COOKIE_TOKEN_TYPE, tokenType, {
						expires: (expiresIn ?? 3600) / (60 * 60 * 24),
					});
				}
			});

			break;
		}

		default: {
			break;
		}
	}
});
auth.$subscribe(
	(_, { tokenType, accessToken }) =>
		performForAllApis(
			(api) => (api.instance.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`),
		),
	{ immediate: true },
);

const liveFeed = useLiveFeedStore();
liveFeed.$subscribe((_, { coachingsGroep }) => {
	localStorage.setItem(LS_LIVEFEED_COACHINGSGROEP, coachingsGroep || "all");
});

if (LOGGING && NODE_ENV !== "development") {
	logInfo();
}
