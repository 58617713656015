import { useStorage } from "@vueuse/core";
import { z } from "zod";
import type { IomodelsOverviewFilter } from "@/lib/backend/club.api";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { logger } from "@/logger";

export function useMemberFilters() {
	const { legacy_locationSlug: gymSlug } = useCurrentGym();

	const defaults: IomodelsOverviewFilter = {
		vestiging: gymSlug.value || "all",
		sort: "check-in",
		search: "",
	};

	const storage = useStorage<IomodelsOverviewFilter>("filters_members", defaults, localStorage, {
		serializer: {
			read: (value) => {
				const parsed = z
					.object({
						vestiging: z.string(),
						sort: z.enum(["check-in", "alfabet", ""]),
						coachId: z.coerce.number().nullish(),
						coachingsGroep: z.enum(["30%", "70%"]).nullish(),
					})
					.safeParse(JSON.parse(value));

				if (!value || !parsed.success) {
					return defaults;
				}

				return {
					...parsed.data,
					search: defaults.search,
				};
			},
			write: JSON.stringify,
		},
		onError: logger.error,
	});

	return {
		filters: storage,
		reset: () => (storage.value = defaults),
	};
}
