import type { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/vue-query";
import type { MaybeRef } from "@tanstack/vue-query/build/lib/types";
import { clubApi } from "@/lib/backend";
import type {
	IomodelsCoachLedenOverviewExtern,
	IomodelsCoachMemberExtern,
} from "@/lib/backend/club.api";

const apiCall = clubApi.leden.overviewVoorCoachApp;
type ApiCall = typeof apiCall;
type Data = Awaited<ReturnType<ApiCall>>["data"];

export function infiniteQueryOptions_members(
	filter: MaybeRef<Parameters<ApiCall>[0]>,
): UseInfiniteQueryOptions<Data, unknown, Data, readonly ["members", typeof filter]> {
	return {
		queryKey: ["members", filter] as const,
		queryFn: async (context) =>
			await apiCall(
				{
					...context.queryKey[1],
					page: context.pageParam || context.queryKey[1].page,
				},
				{ signal: context.signal },
			).then((response) => response.data),
		getPreviousPageParam: (firstPage: Data) =>
			firstPage.page <= 1 ? undefined : firstPage.page - 1,
		getNextPageParam: (lastPage: Data) => (lastPage.nextPage ? lastPage.page + 1 : undefined),
	};
}

export function flattenMembers(
	param: InfiniteData<IomodelsCoachLedenOverviewExtern> | undefined,
): IomodelsCoachMemberExtern[] {
	if (!param || !param.pages) {
		return [];
	}

	return param.pages
		.flatMap((page) => page.users)
		.filter((user, index, self) => index === self.findIndex((value) => value.id === user.id));
}
