import type { UseQueryOptions } from "@tanstack/vue-query";
import { minutesToMilliseconds } from "date-fns";
import { reserveerApi } from "@/lib/backend";

const apiCall = reserveerApi.vestigingen.vestigingen;
type ApiCall = typeof apiCall;
type Data = Awaited<ReturnType<ApiCall>>["data"];

export function queryOptions_gyms(): UseQueryOptions<Data, unknown, Data, readonly ["gyms"]> {
	return {
		queryKey: ["gyms"] as const,
		queryFn: async (context) =>
			await apiCall({ signal: context.signal }).then((response) => response.data),
		select: (data) => data.sort((a, b) => a.naam.localeCompare(b.naam)),
		placeholderData: [] as Data,
		cacheTime: Infinity,
		staleTime: minutesToMilliseconds(60),
	};
}
