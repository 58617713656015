





































































































































































import { type PropType, defineComponent } from "@vue/composition-api";
import { GESLACHT_MAP } from "@/lib/constants/options";

export default defineComponent({
	name: "PfgFormProefsportenPersoonlijkeGegevens",
	props: {
		geslacht: {
			type: String as PropType<Geslacht>,
			required: false,
			default: undefined,
		},
		geboortedatum: {
			type: String,
			required: false,
			default: undefined,
		},
		voornaam: {
			type: String,
			required: false,
			default: undefined,
		},
		achternaam: {
			type: String,
			required: false,
			default: undefined,
		},
		email: {
			type: String,
			required: false,
			default: undefined,
		},
		telefoonNummer: {
			type: String,
			required: false,
			default: undefined,
		},
		telefoonMobiel: {
			type: String,
			required: false,
			default: undefined,
		},
	},
	data() {
		return { GESLACHT_MAP };
	},
});
