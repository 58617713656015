import { register } from "register-service-worker";
import { logger } from "@/logger";

const shouldLog = process.env.VUE_APP_LOGGING === "true";
const isProduction = process.env.NODE_ENV === "production";
const isStaging = process.env.NODE_ENV === "staging";
const shouldInstall = isProduction || isStaging;

if (shouldInstall) {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready() {
			if (shouldLog) {
				logger.info(
					"App is being served from cache by a service worker. \n For more details, visit https://goo.gl/AFskqB",
				);
			}
		},
		registered() {
			if (shouldLog) {
				logger.info("Service  worker has been registered.");
			}
		},
		cached() {
			if (shouldLog) {
				logger.info("Content has been cached for offline use.");
			}
		},
		updatefound() {
			if (shouldLog) {
				logger.info("New content is downloading.");
			}
		},
		updated() {
			if (shouldLog) {
				logger.info("New content is available; please refresh.");
			}

			logger.info("Trying to refresh content automagically.");

			window.location.reload();
		},
		offline() {
			if (shouldLog) {
				logger.warn("No internet connection found. App is running in offline mode.");
			}
		},
		error(error) {
			if (shouldLog) {
				logger.error("Error during service worker registration:", error);
			}
		},
	});
}
