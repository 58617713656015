import type { AxiosResponse, Method } from "axios";
import { axios } from "@/axios";

export class BaseService {
	protected get<ResponseData, RequestBody>(url: string, params?: RequestParams<RequestBody>) {
		return this.request<ResponseData, RequestBody>("GET", url, params);
	}
	protected put<ResponseData, RequestBody>(url: string, params?: RequestParams<RequestBody>) {
		return this.request<ResponseData, RequestBody>("PUT", url, params);
	}
	protected post<ResponseData, RequestBody>(url: string, params?: RequestParams<RequestBody>) {
		return this.request<ResponseData, RequestBody>("POST", url, params);
	}
	protected patch<ResponseData, RequestBody>(url: string, params?: RequestParams<RequestBody>) {
		return this.request<ResponseData, RequestBody>("PATCH", url, params);
	}
	protected delete<ResponseData, RequestBody>(url: string, params?: RequestParams<RequestBody>) {
		return this.request<ResponseData, RequestBody>("DELETE", url, params);
	}

	private async request<ResponseData, RequestBody>(
		method: Method,
		url: string,
		{ body, urlParams, headers }: RequestParams<RequestBody> = {},
	) {
		return await axios.request<ResponseData, AxiosResponse<ResponseData, RequestBody>, RequestBody>(
			{
				url,
				method,
				headers,
				data: body,
				params: urlParams,
			},
		);
	}
}
