import axios from "axios";
import service from "@/lib/service";

/** @deprecated Please use `pinia` instead */
const modal = {
	namespaced: true,
	state: {
		user: {
			achternaam: "",
			geboortedatum: "",
			geslacht: "",
			id: "",
			profileImg: "",
			sections: [],
		},
		lidId: undefined,
		persoonlijk: {},
		medisch: {},
		abonnementsvorm: {},
		metingen: {},
	},
	getters: {
		getMetingById: (state) => (id) =>
			state.metingen.metingen.find((sMeting) => sMeting.metingId === id),
		priority(state) {
			return state.user.sections.length
				? state.user.sections.find((section) => section.priority)
				: "";
		},
	},
	mutations: {
		populateUser(state, payload) {
			state.user = payload;
		},
		setLidId(state, payload) {
			state.lidId = payload;
		},
		clearUser(state) {
			state.user = {
				achternaam: "",
				geboortedatum: "",
				geslacht: "",
				id: "",
				profileImg: "",
				sections: [],
			};
			state.persoonlijk = {};
			state.medisch = {};
			state.abonnementsvorm = {};
		},
		populatePersoonlijk(state, payload) {
			state.persoonlijk = payload;
		},
		populateMedisch(state, payload) {
			state.medisch = payload;
		},
		populateAbonnementsvorm(state, payload) {
			state.abonnementsvorm = payload;
		},
		populateMetingen(state, payload) {
			state.metingen = payload;
		},
	},
	actions: {
		getUser({ commit }, payload) {
			// when a user is requested
			return service
				.get(`user/${payload}`)
				.then((data) => {
					commit("populateUser", data);
				})
				.catch(() => {
					commit("populateUser", {});
				});
		},
		getUserById(context, id) {
			return service.get(`user/${id}`);
		},
		setLidId({ commit, dispatch, state }, payload) {
			if (payload !== undefined && state.lidId !== payload) {
				commit("clearUser");
				commit("setLidId", payload);
				dispatch("getUser", payload);
			}
		},
		clearUser({ commit }) {
			commit("clearUser");
		},
		getUserPersoonlijk({ commit }, payload) {
			return new Promise((resolve, reject) => {
				if (!payload || typeof payload !== "number") {
					return reject("no user id");
				}
				return service
					.get(`user/${payload}/personal-data`)
					.then((response) => {
						response.coachLid = response.coachLid === null ? "" : response.coachLid;
						response.coachingsGroep =
							response.coachingsGroep === null ? "" : response.coachingsGroep;
						commit("populatePersoonlijk", response);
						resolve(response);
					})
					.catch((err) => {
						commit("populatePersoonlijk", {});
						reject(err);
					});
			});
		},
		async saveUserPersoonlijk(_, { id, data }) {
			if (!id) {
				return Promise.reject("no user id");
			}
			if (!data) {
				return Promise.reject("no data to save");
			}
			const { coachingsGroep, coachLid, ...rest } = data;

			coachingsGroep &&
				(await service.post(
					`${
						process.env.VUE_APP_CLUB_API_SERVER
					}/lid/${id}/groep/${encodeURIComponent(coachingsGroep)}`,
				));
			if (coachLid) {
				await service.post(`${process.env.VUE_APP_CLUB_API_SERVER}/lid/${id}/coach/${coachLid}`);
			} else {
				await service.post(`${process.env.VUE_APP_CLUB_API_SERVER}/lid/${id}/verwijder-coach`);
			}

			return await service.post(`user/${id}/personal-data`, rest);
		},
		getUserMedisch({ commit }, payload) {
			return new Promise((resolve, reject) => {
				if (!payload) {
					return reject("no user id");
				}
				return service
					.get(`user/${payload}/personal-medical`)
					.then((response) => {
						commit("populateMedisch", response);
						resolve(response);
					})
					.catch((err) => {
						commit("populateMedisch", {});
						reject(err);
					});
			});
		},
		saveUserMedisch(_, { id, data }) {
			if (!id) {
				return Promise.reject("no user id");
			}
			if (!data) {
				return Promise.reject("no data to save");
			}
			return axios.post(`user/${id}/personal-medical`, data);
		},
	},
};

export default modal;
