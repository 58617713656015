import { config } from "@/lib/config";

export const getCoachImage = (
	coach: { id: number } | string | number,
	format: "style" | "css-var" = "style",
) => {
	const formatMap = {
		style: "background-image:",
		"css-var": "--background-image:",
	};
	const formatString = formatMap[format];

	const url =
		typeof coach === "string" || typeof coach === "number"
			? `${config.coach.server}/trainer/${coach}/profile-image`
			: `${config.coach.server}/trainer/${coach.id}/profile-image`;

	return `${formatString} url('${url}'), url('/static/img/default-avatar.png');`;
};

export const getMemberImage = (
	member: { profileImg?: string; id: number },
	format: "style" | "css-var" = "style",
) => {
	const formatMap = {
		style: "background-image:",
		"css-var": "--background-image:",
	};
	const formatString = formatMap[format];

	const url =
		member.profileImg !== ""
			? member.profileImg
			: `${config.coach.server}/user/${member.id}/profile-image`;

	return `${formatString} url('${url}'), url('/static/img/default-avatar.png');`;
};

export const getIconName = (str: string) => {
	const map = {
		bezoekfrequentie: "frequentie",
		groepsles: "groepslessen",
		intake: "intake",
		meting: "metingen",
		trainingsschema: "schemas",
	} as Record<string, string>;

	const iconName = map[str];

	return `icon_${iconName}`;
};
