var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"aria-label":(_vm.containerAriaLabel + " " + _vm.hotkeyLabel),"tabIndex":-1}},_vm._l((_vm.possiblePositions),function(pos,index){return _c('ol',_vm._b({key:pos,ref:"listRef",refInFor:true,class:_vm.attrs.class,style:(_vm.toasterStyle),attrs:{"data-sonner-toaster":"","dir":_vm.dir === 'auto' ? _vm.getDocumentDirection() : _vm.dir,"tabIndex":-1,"data-theme":_vm.theme,"data-rich-colors":_vm.richColors ? 'true' : 'false',"data-y-position":pos.split('-')[0],"data-x-position":pos.split('-')[1]},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"mouseenter":function () { return (_vm.expanded = true); },"mousemove":function () { return (_vm.expanded = true); },"mouseleave":function () {
				// Avoid setting expanded to false when interacting with a toast, e.g. swiping
				if (!_vm.interacting) {
					_vm.expanded = false;
				}
			},"pointerdown":_vm.onPointerDown,"pointerup":function () { return (_vm.interacting = false); }}},'ol',_vm.$attrs,false),_vm._l((_vm.toasts.filter(
				function (toast) { return (!toast.position && index === 0) || toast.position === pos; }
			)),function(toast,idx){return _c('Toast',{key:toast.id,class:_vm.toastClass,style:(_vm.toastOptions.style),attrs:{"heights":_vm.heights.filter(function (h) { return h.position === toast.position; }),"icons":_vm.icons,"index":idx,"toast":toast,"default-rich-colors":_vm.richColors,"duration":_vm.toastDuration,"description-class":_vm.toastOptions.descriptionClass,"invert":_vm.invert,"visible-toasts":_vm.visibleToasts,"close-button":_vm.toastCloseButton,"interacting":_vm.interacting,"position":pos,"unstyled":_vm.toastOptions.unstyled,"classes":_vm.toastOptions.classes,"cancel-button-style":_vm.toastOptions.cancelButtonStyle,"action-button-style":_vm.toastOptions.actionButtonStyle,"toasts":_vm.toasts.filter(function (t) { return t.position === toast.position; }),"expand-by-default":_vm.expand,"gap":_vm.gap,"expanded":_vm.expanded,"pause-when-page-is-hidden":_vm.pauseWhenPageIsHidden,"cn":_vm.cn},on:{"update:heights":_vm.updateHeights,"removeToast":_vm.removeToast},scopedSlots:_vm._u([{key:"close-icon",fn:function(){return [_vm._t("close-icon",function(){return [_c('CloseIcon')]})]},proxy:true},{key:"loading-icon",fn:function(){return [_vm._t("loading-icon",function(){return [_c('LoaderIcon',{attrs:{"visible":toast.type === 'loading'}})]})]},proxy:true},{key:"success-icon",fn:function(){return [_vm._t("success-icon",function(){return [_c('SuccessIcon')]})]},proxy:true},{key:"error-icon",fn:function(){return [_vm._t("error-icon",function(){return [_c('ErrorIcon')]})]},proxy:true},{key:"warning-icon",fn:function(){return [_vm._t("warning-icon",function(){return [_c('WarningIcon')]})]},proxy:true},{key:"info-icon",fn:function(){return [_vm._t("info-icon",function(){return [_c('InfoIcon')]})]},proxy:true}],null,true)})}),1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }