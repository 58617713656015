<template>
	<div>
		<header class="pfg-header">
			<div class="pfg-header_heading">
				<h1 class="s_elem-has-icon-left a_fill_default">Maak Webcam foto</h1>
			</div>

			<div class="pfg-header_description">Gebruik de webcam om een foto te maken</div>
		</header>

		<hr class="v_less-margin" />
		<web-cam
			v-show="!img"
			ref="webcam"
			width="75%"
			height="75%"
			style="margin-left: 12%"
			:device-id="deviceId"
			@started="onStarted"
			@stopped="onStopped"
			@error="onError"
			@cameras="onCameras"
			@camera-change="onCameraChange"
		/>
		<img :src="img" />
		<select v-if="!img" v-model="camera" style="width: 75%">
			<option>-- Select device --</option>
			<option
				v-for="availableDevice in devices"
				:key="availableDevice.deviceId"
				:value="availableDevice.deviceId"
			>
				{{ availableDevice.label }}
			</option>
		</select>
		<button
			type="button"
			class="button btn-primary"
			style="margin-top: 10px; margin-left: 5px"
			@click="
				img ? (img = null) : onCapture();
				onStart();
			"
		>
			<span class="button-txt">{{ img ? "Opnieuw" : "Maak foto" }}</span>
		</button>

		<hr class="v_less-margin" />

		<div class="pfg-button_group" style="margin: 20px; margin-left: 0px">
			<button
				class="button v_ghost_brand_secondary v_smaller a_margin-left-10"
				@click="
					onStop();
					$emit('close');
				"
			>
				<span class="button-txt">Annuleren</span>
			</button>
			<button
				:disabled="!img"
				class="button v_ghost_brand_primary v_smaller a_margin-left-10"
				@click="
					callback(img);
					$emit('close');
				"
			>
				<span class="button-txt">Foto Gebruiken</span>
			</button>
			<span v-show="loading" style="display: inline-block; margin-left: 10px">
				<img
					style="width: 40px; height: px; margin-top: -10px"
					src="static/img/spinners/loading-small.gif"
				/>
				<!-- <p class="a_margin-top-10">laden..</p> -->
			</span>
		</div>
	</div>
</template>

<script>
//import { mapActions } from 'vuex';
//import { debounce } from '@/utils/debounce';
import { WebCam } from "vue-web-cam";
import { logger } from "@/logger";

//const DEBOUNCE_TIME = 1000;

export default {
	components: {
		WebCam,
	},
	props: {
		callback: {
			type: Function,
			default: () => {
				return;
			},
		},
	},
	data() {
		return {
			img: null,
			loading: false,
			camera: null,
			deviceId: null,
			devices: [],
		};
	},
	computed: {
		device: function () {
			return this.devices.find((n) => n.deviceId == this.deviceId);
		},
	},
	watch: {
		camera: function (id) {
			this.deviceId = id;
		},
		devices: function () {
			// Once we have a list select the first one
			const first = this.devices[0];
			if (first) {
				this.camera = first.deviceId;
				this.deviceId = first.deviceId;
			}
		},
	},
	methods: {
		onCapture() {
			this.img = this.$refs.webcam.capture();
		},
		onStop() {
			this.$refs.webcam.stop();
		},
		onStart() {
			this.$refs.webcam.start();
		},
		onError(error, stream) {
			logger.error("On Error Event", error, stream);
		},
		onCameras(cameras) {
			this.devices = cameras;
		},
		onCameraChange(deviceId) {
			this.deviceId = deviceId;
			this.camera = deviceId;
		},
	},
};
</script>

<style lang="scss" scoped>
.s_error {
	color: red;
	font-size: 80%;
}
</style>
