








































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { useInfiniteQuery, useQuery } from "@tanstack/vue-query";
import { mapActions } from "vuex";
import { useMemberFilters } from "@/lib/hooks/useMemberFilters";
import { queryOptions_gyms } from "@/lib/query/functions/gyms";
import { flattenMembers, infiniteQueryOptions_members } from "@/lib/query/functions/members";

export default defineComponent({
	setup() {
		const selectedMemberId = ref<number | undefined>(undefined);
		const { data: gyms } = useQuery(queryOptions_gyms());

		const { filters } = useMemberFilters();

		watch(filters, () => (selectedMemberId.value = undefined), { deep: true });

		const { status, data } = useInfiniteQuery(infiniteQueryOptions_members(filters));

		const members = computed(() => flattenMembers(data.value));

		return { selectedMemberId, filters, gyms, status, members };
	},
	methods: {
		...mapActions("modal", { openModal: "openModal" }),
		naarLid() {
			this.$emit("close");
			this.$router.push({
				name: "Member appointment create",
				params: { id: String(this.selectedMemberId) },
			});
		},
		openTempMemberModal() {
			this.openModal({
				name: "temp-member",
				data: {
					popup: {
						enable: true,
						title: "Het lid is succesvol aangemaakt",
						body: "Maak eventueel een afspraak met het lid",
						cancelText: "Sluiten",
						actionText: "Maak afspraak",
					},
				},
				callback: ({ id }: { id: number }) => {
					this.$router.push({
						name: "Member appointment create",
						params: { id: String(id) },
					});
				},
			});
		},
	},
});
