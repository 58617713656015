export const ROLE_MAP: Record<string, string> = {
	admin: "Admin",
	club_aanvoerder: "Club aanvoerder",
	trainer: "Trainer",
	lid: "Lid",
};
export const VERWIJZING_MAP: Record<string, string> = {
	"gratis-weekpas": "Gratis weekpas",
	vrienden: "Vrienden",
	facebook: "Facebook",
	instagram: "Instagram",
	zoekmachine: "Zoekmachine",
	anders: "Anders",
};
export const GESLACHT_MAP: Record<string, string> = {
	m: "Man",
	f: "Vrouw",
} as const;
export const LANGUAGE_MAP: Record<string, string> = {
	nld: "Nederlands",
	eng: "Engels",
} as const;

export const APPOINTMENT_MAP: Record<string, { icon: string; shortText: string; text: string }> = {
	meting: {
		icon: "metingen",
		shortText: "Meting",
		text: "Meting afnemen",
	},
	trainingsSchema: {
		icon: "schemas",
		shortText: "Schema",
		text: "Schema maken",
	},
	doelOpstellen: {
		icon: "doel",
		shortText: "Doel",
		text: "Doel opstellen",
	},
	personalTraining: {
		icon: "afspraken_personal-training",
		shortText: "Coach moment",
		text: "Coach moment",
	},
	kickOff: {
		icon: "afspraken_kickoff",
		shortText: "Intake",
		text: "Intake doen",
	},
} as const;
