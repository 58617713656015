import type { Route } from "vue-router";
import { z } from "zod";

export function routeProps<TProps extends z.ZodRawShape>(
	props: TProps,
	transform?: (route: Route) => z.input<z.ZodObject<TProps>>,
) {
	return (route: Route) => {
		try {
			return z.object(props).parse({
				...route,
				...transform?.(route),
			});
		} catch (error) {
			throw new Error(`Invalid route props for '${route.name || route.fullPath}'`, {
				cause: error as Error,
			});
		}
	};
}
