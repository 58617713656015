import { defineStore } from "pinia";
import { clubApi } from "@/lib/backend";
import type { IomodelsLidVoorFeed, IomodelsOverviewFilter } from "@/lib/backend/club.api";
import { LS_LIVEFEED_COACHINGSGROEP } from "@/lib/constants/localstorage";

type State = {
	initialized: boolean;
	items: IomodelsLidVoorFeed[];
	coachingsGroep: IomodelsOverviewFilter["coachingsGroep"] | "all";
};

export const useLiveFeedStore = defineStore("liveFeed", {
	state: (): State => ({
		initialized: false,
		items: [],
		coachingsGroep: "30%",
	}),
	getters: {
		all: (state) => state.items,
		byPersonalPreference: ({ items, coachingsGroep }) =>
			[...new Map(items.map((item) => [item["id"], item])).values()].filter((member) => {
				if (coachingsGroep === undefined || coachingsGroep === "all") {
					return member;
				}

				const isSameGroep = coachingsGroep === member.coachingsGroep;

				if (coachingsGroep !== "70%") {
					return isSameGroep;
				}

				return isSameGroep || member.coachingsGroep !== "30%";
			}),
		byPreference:
			({ items }) =>
			(coachingsGroep?: "all" | "30%" | "70%") =>
				[...new Map(items.map((item) => [item["id"], item])).values()].filter((member) => {
					if (coachingsGroep === undefined || coachingsGroep === "all") {
						return member;
					}

					const isSameGroep = coachingsGroep === member.coachingsGroep;

					if (coachingsGroep !== "70%") {
						return isSameGroep;
					}

					return isSameGroep || member.coachingsGroep !== "30%";
				}),
	},
	actions: {
		async init() {
			if (this.initialized) {
				return;
			}

			this.initialized = true;

			await this.get();
			await this.getPreference();
		},
		async get() {
			const response = await clubApi.liveFeed.liveFeedVoorCoachApp();

			switch (response.status) {
				case 200: {
					this.items = response.data;

					return this.items;
				}

				default: {
					throw response;
				}
			}
		},
		async getPreference() {
			const lsCoachingsGroep = localStorage.getItem(LS_LIVEFEED_COACHINGSGROEP);

			if (lsCoachingsGroep === "30%" || lsCoachingsGroep === "70%" || lsCoachingsGroep === "all") {
				this.coachingsGroep = lsCoachingsGroep;
			}
		},
	},
});
