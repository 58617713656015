import Vue from "vue";
// @ts-expect-error vue-slider-component is not typed
import VueSlider from "vue-slider-component";
// @ts-expect-error vuejs-datepicker is not typed
import Datepicker from "vuejs-datepicker";
import Icon from "@/components/elements/Icon.vue";
import ImageUploadComponent from "@/components/elements/ImageUploadComponent.vue";
import InlineDropdown from "@/components/elements/InlineDropdown.vue";
import Loader from "@/components/elements/Loader.vue";
// Modals
import ModalSendMessage from "@/components/elements/ModalSendMessage.vue";
import Popup from "@/components/elements/Popup.vue";
import Switcher from "@/components/elements/Switcher.vue";
import TableMetingen from "@/components/elements/TableMetingen.vue";
import TableVisits from "@/components/elements/TableVisits.vue";
import Intake from "@/components/forms/Intake.vue";
import ProefsportenPersoonlijkeGegevens from "@/components/forms/ProefsportenPersoonlijkeGegevens.vue";
import ProefsporterFitness from "@/components/forms/ProefsporterFitness.vue";
import ProefsporterGroepsles from "@/components/forms/ProefsporterGroepsles.vue";
import Welkomstformulier from "@/components/forms/Welkomstformulier.vue";

export const registerComponents = async () => {
	Vue.component("VueSlider", VueSlider);
	Vue.component("VueDatepicker", Datepicker);

	[
		Loader,
		Icon,
		Popup,
		Switcher,
		ImageUploadComponent,
		TableVisits,
		ProefsportenPersoonlijkeGegevens,
		ProefsporterFitness,
		ProefsporterGroepsles,
		Welkomstformulier,
		Intake,
		InlineDropdown,
		TableMetingen,

		// Modals
		ModalSendMessage,
	].forEach((component) => {
		Vue.component(component.name, component);
	});
};
