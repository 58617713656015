import { defineStore } from "pinia";

export type OccupancyFormat = "relative" | "absolute";

type AppState = {
	occupancyFormat: OccupancyFormat;
	fullScreen: boolean;
	showingTopbar: boolean;
};

export const useAppStore = defineStore("app", {
	state: (): AppState => ({
		occupancyFormat: "relative",
		fullScreen: false,
		showingTopbar: true,
	}),
});
