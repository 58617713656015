








































































































import {
	type PropType,
	computed,
	defineComponent,
	nextTick,
	ref,
	watch,
	watchEffect,
} from "@vue/composition-api";
import Toast from "./Toast.vue";
import CloseIcon from "./assets/CloseIcon.vue";
import ErrorIcon from "./assets/ErrorIcon.vue";
import InfoIcon from "./assets/InfoIcon.vue";
import LoaderIcon from "./assets/Loader.vue";
import SuccessIcon from "./assets/SuccessIcon.vue";
import WarningIcon from "./assets/WarningIcon.vue";
import { ToastState } from "./state";
import type { HeightT, Position, ToastT, ToastToDismiss, ToasterProps } from "./types";

// Visible toasts amount
const VISIBLE_TOASTS_AMOUNT = 3;

// Viewport padding
const VIEWPORT_OFFSET = "32px";

// Default toast width
const TOAST_WIDTH = 356;

// Default gap between toasts
const GAP = 14;

const isClient = typeof window !== "undefined" && typeof document !== "undefined";

function _cn(...classes: (string | undefined)[]) {
	return classes.filter(Boolean).join(" ");
}

export default defineComponent({
	name: "Toaster",
	components: {
		Toast,
		CloseIcon,
		ErrorIcon,
		InfoIcon,
		LoaderIcon,
		SuccessIcon,
		WarningIcon,
	},
	inheritAttrs: false,
	props: {
		invert: {
			type: Boolean as PropType<NonNullable<ToasterProps["invert"]>>,
			default: false,
		},
		theme: {
			type: String as PropType<NonNullable<ToasterProps["theme"]>>,
			default: "light",
		},
		position: {
			type: String as PropType<NonNullable<ToasterProps["position"]>>,
			default: "bottom-right",
		},
		hotkey: {
			type: Array as PropType<NonNullable<ToasterProps["hotkey"]>>,
			default: () => ["altKey", "KeyT"],
		},
		richColors: {
			type: Boolean as PropType<NonNullable<ToasterProps["richColors"]>>,
			default: false,
		},
		expand: {
			type: Boolean as PropType<NonNullable<ToasterProps["expand"]>>,
			default: false,
		},
		duration: {
			type: Number as PropType<ToasterProps["duration"]>,
			default: undefined,
		},
		gap: {
			type: Number as PropType<NonNullable<ToasterProps["gap"]>>,
			default: GAP,
		},
		visibleToasts: {
			type: Number as PropType<NonNullable<ToasterProps["visibleToasts"]>>,
			default: VISIBLE_TOASTS_AMOUNT,
		},
		closeButton: {
			type: Boolean as PropType<NonNullable<ToasterProps["closeButton"]>>,
			default: false,
		},
		toastOptions: {
			type: Object as PropType<NonNullable<ToasterProps["toastOptions"]>>,
			default: () => ({}),
		},
		offset: {
			type: [String, Number] as PropType<NonNullable<ToasterProps["offset"]>>,
			default: VIEWPORT_OFFSET,
		},
		dir: {
			type: String as PropType<NonNullable<ToasterProps["dir"]>>,
			default: "auto",
		},
		icons: {
			type: Object as PropType<ToasterProps["icons"]>,
			default: () => ({}),
		},
		containerAriaLabel: {
			type: String as PropType<NonNullable<ToasterProps["containerAriaLabel"]>>,
			default: "Notifications",
		},
		pauseWhenPageIsHidden: {
			type: Boolean as PropType<NonNullable<ToasterProps["pauseWhenPageIsHidden"]>>,
			default: false,
		},
		cn: {
			type: Function as PropType<NonNullable<ToasterProps["cn"]>>,
			default: _cn,
		},
	},
	setup(props, { attrs }) {
		function getDocumentDirection(): ToasterProps["dir"] {
			if (typeof window === "undefined") return "ltr";
			if (typeof document === "undefined") return "ltr"; // For Fresh purpose

			const dirAttribute = document.documentElement.getAttribute("dir");

			if (dirAttribute === "auto" || !dirAttribute) {
				return window.getComputedStyle(document.documentElement).direction as ToasterProps["dir"];
			}

			return dirAttribute as ToasterProps["dir"];
		}

		const toasts = ref<ToastT[]>([]);
		const possiblePositions = computed<Position[]>(() => {
			const posList = toasts.value
				.filter((toast) => toast.position)
				.map((toast) => toast.position) as Position[];
			return posList.length > 0
				? (Array.from(new Set([props.position].concat(posList))) as Position[])
				: // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					[props.position!];
		});
		const heights = ref<HeightT[]>([]);
		const expanded = ref(false);
		const interacting = ref(false);
		const actualTheme = ref(
			props.theme !== "system"
				? props.theme
				: typeof window !== "undefined"
					? window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
						? "dark"
						: "light"
					: "light",
		);

		const toasterStyle = computed(() => ({
			"--front-toast-height": `${heights.value?.[0]?.height ?? 0}px`,
			"--offset":
				typeof props.offset === "number" ? `${props.offset}px` : props.offset || VIEWPORT_OFFSET,
			"--width": `${TOAST_WIDTH}px`,
			"--gap": `${props.gap}px`,
			// @ts-expect-error Not typed correctly
			...attrs.style,
		}));

		const toastDuration = computed(() => props.toastOptions.duration ?? props.duration);
		const toastClass = computed(() => props.toastOptions.class ?? "");
		const toastCloseButton = computed(() => props.toastOptions.closeButton ?? props.closeButton);

		const listRef = ref<HTMLOListElement[] | HTMLOListElement | null>(null);
		const lastFocusedElementRef = ref<HTMLElement | null>(null);
		const isFocusWithinRef = ref(false);

		const hotkeyLabel = props.hotkey?.join("+").replace(/Key/g, "").replace(/Digit/g, "");

		function removeToast(toastToRemove: ToastT) {
			if (!toasts.value.find((toast) => toast.id === toastToRemove.id)?.delete) {
				ToastState.dismiss(toastToRemove.id);
			}

			toasts.value = toasts.value.filter(({ id }) => id !== toastToRemove.id);
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function onBlur(event: FocusEvent | any) {
			if (isFocusWithinRef.value && !event.currentTarget?.contains?.(event.relatedTarget)) {
				isFocusWithinRef.value = false;
				if (lastFocusedElementRef.value) {
					lastFocusedElementRef.value.focus({ preventScroll: true });
					lastFocusedElementRef.value = null;
				}
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function onFocus(event: FocusEvent | any) {
			const isNotDismissible =
				event.target instanceof HTMLElement && event.target.dataset.dismissible === "false";

			if (isNotDismissible) return;

			if (!isFocusWithinRef.value) {
				isFocusWithinRef.value = true;
				lastFocusedElementRef.value = event.relatedTarget as HTMLElement;
			}
		}

		function onPointerDown(event: PointerEvent) {
			if (event.target) {
				const isNotDismissible =
					event.target instanceof HTMLElement && event.target.dataset.dismissible === "false";

				if (isNotDismissible) return;
			}
			interacting.value = false;
		}

		function updateHeights(h: HeightT[]) {
			heights.value = h;
		}

		watchEffect((onInvalidate) => {
			const unsubscribe = ToastState.subscribe((toast) => {
				if ((toast as ToastToDismiss).dismiss) {
					toasts.value = toasts.value.map((t) => (t.id === toast.id ? { ...t, delete: true } : t));
					return;
				}

				nextTick(() => {
					const indexOfExistingToast = toasts.value.findIndex((t) => t.id === toast.id);

					// Update the toast if it already exists
					if (indexOfExistingToast !== -1) {
						toasts.value = [
							...toasts.value.slice(0, indexOfExistingToast),
							{ ...toasts.value[indexOfExistingToast], ...toast },
							...toasts.value.slice(indexOfExistingToast + 1),
						];
					} else {
						toasts.value = [toast, ...toasts.value];
					}
				});
			});

			onInvalidate(() => {
				unsubscribe();
			});
		});

		watch(
			() => props.theme,
			(newTheme) => {
				if (newTheme !== "system") {
					actualTheme.value = newTheme;
					return;
				}

				if (newTheme === "system") {
					// check if current preference is dark
					if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
						// it's currently dark
						actualTheme.value = "dark";
					} else {
						// it's not dark
						actualTheme.value = "light";
					}
				}

				if (typeof window === "undefined") return;

				window
					.matchMedia("(prefers-color-scheme: dark)")
					.addEventListener("change", ({ matches }) => {
						if (matches) {
							actualTheme.value = "dark";
						} else {
							actualTheme.value = "light";
						}
					});
			},
		);

		watchEffect(() => {
			if (listRef.value && lastFocusedElementRef.value) {
				lastFocusedElementRef.value.focus({ preventScroll: true });
				lastFocusedElementRef.value = null;
				isFocusWithinRef.value = false;
			}
		});

		watchEffect(() => {
			// Ensure expanded is always false when no toasts are present / only one left
			if (toasts.value.length <= 1) {
				expanded.value = false;
			}
		});

		watchEffect((onInvalidate) => {
			function handleKeyDown(event: KeyboardEvent) {
				const isHotkeyPressed = props.hotkey?.every(
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(key) => (event as any)[key] || event.code === key,
				);

				const listRefItem = Array.isArray(listRef.value) ? listRef.value[0] : listRef.value;

				if (isHotkeyPressed) {
					expanded.value = true;
					listRefItem?.focus();
				}

				const isItemActive =
					document.activeElement === listRef.value || listRefItem?.contains(document.activeElement);

				if (event.code === "Escape" && isItemActive) {
					expanded.value = false;
				}
			}

			if (!isClient) return;

			document.addEventListener("keydown", handleKeyDown);

			onInvalidate(() => {
				document.removeEventListener("keydown", handleKeyDown);
			});
		});

		return {
			props,
			attrs,
			toasts,
			possiblePositions,
			heights,
			expanded,
			interacting,
			actualTheme,
			listRef,
			lastFocusedElementRef,
			isFocusWithinRef,
			removeToast,
			onBlur,
			onFocus,
			onPointerDown,
			updateHeights,
			getDocumentDirection,
			hotkeyLabel,
			TOAST_WIDTH,
			toasterStyle,
			toastDuration,
			toastClass,
			toastCloseButton,
		};
	},
});
