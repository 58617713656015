<template>
	<div>
		<header class="pfg-header">
			<div class="pfg-header_heading">
				<h1 class="s_elem-has-icon-left">Telefoontje</h1>
			</div>
		</header>

		<hr class="v_less-margin" />

		<div>Notitie</div>
		<div class="modal-view-note_display">
			{{ data.note }}
		</div>

		<div class="actions">
			<button
				class="button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-bottom-0"
				@click="$emit('close')"
			>
				<span class="button-txt">Sluiten</span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			type: Object,
			required: true,
		},
		callback: {
			type: Function,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-view-note_display {
	width: 100%;
	height: 100px;
	border: none;
	border-radius: 10px;
	padding: 15px;
	font-size: 16px;
	resize: none;
	background-color: $brand-primary-lightester;
	color: $brand-primary-lighter;
	cursor: not-allowed;
	touch-action: none;

	&:focus {
		outline: none;
		background-color: $brand-primary-lightester;
		color: $brand-primary-lighter;
		border: none;
		cursor: not-allowed;
		touch-action: none;
	}
}

.actions {
	margin-top: 20px;
	margin-left: 0;
}
</style>
