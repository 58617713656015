import { axios } from "@/axios";
import { logger } from "@/logger";

/** @deprecated Please use `pinia` instead */
const model = {
	namespaced: true,
	state: {
		schemaModel: {},
	},
	getters: {},
	mutations: {
		setSchemaModel(state, payload) {
			state.schemaModel = payload;
		},
	},
	actions: {
		getSchemaModel({ commit }) {
			return new Promise((resolve, reject) =>
				axios
					.get("/schema-model")
					.then((response) => {
						if (response.status === 200) {
							commit("setSchemaModel", response.data);
							resolve(response.data);
						}
					})
					.catch((err) => {
						logger.error(err.response);
						reject(err.response);
					}),
			);
		},
	},
};

export default model;
