import { AxiosError } from "axios";

export function formatErrorMessage<T>(
	error: T,
	defaultValue: (error: T) => string = (error) => JSON.stringify(error),
): string {
	if (error instanceof AxiosError) {
		if (error.response?.data.error.message) {
			return error.response.data.error.message;
		}

		if (error.response?.data.message) {
			return error.response.data.message;
		}
	}

	if (error instanceof Error) {
		return error.message;
	}

	if (typeof error === "string") {
		return error;
	}

	return defaultValue(error);
}
