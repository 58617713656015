import type { CoachViewModel } from "@/lib/backend/core.api";

export function getCoachName(
	coach?: Pick<CoachViewModel, "givenName" | "familyName"> & Partial<Pick<CoachViewModel, "id">>,
) {
	if (!coach) {
		return "Coach";
	}

	if (!!coach.id && !coach.givenName && !coach.familyName) {
		return `Coach ${coach.id}`;
	}

	return [coach.givenName, coach.familyName].filter(Boolean).join(" ");
}
