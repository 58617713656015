import { QueryClient } from "@tanstack/vue-query";
import { minutesToMilliseconds } from "date-fns";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime: minutesToMilliseconds(30),
			staleTime: minutesToMilliseconds(5),
		},
	},
});
