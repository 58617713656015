












































































































































import {
	type PropType,
	computed,
	defineComponent,
	onMounted,
	onUnmounted,
	ref,
	watch,
	watchEffect,
} from "@vue/composition-api";
import { useIsDocumentHidden } from "./hooks";
import "./styles.css";
import { type HeightT, type ToastClasses, type ToastProps, type ToastT, isAction } from "./types";

export default defineComponent({
	props: {
		toast: {
			type: Object as PropType<ToastProps["toast"]>,
			required: true,
		},
		toasts: {
			type: Array as PropType<ToastProps["toasts"]>,
			required: true,
		},
		index: {
			type: Number as PropType<ToastProps["index"]>,
			required: true,
		},
		expanded: {
			type: Boolean as PropType<ToastProps["expanded"]>,
			required: true,
		},
		invert: {
			type: Boolean as PropType<ToastProps["invert"]>,
			required: true,
		},
		heights: {
			type: Array as PropType<ToastProps["heights"]>,
			required: true,
		},
		gap: {
			type: Number as PropType<ToastProps["gap"]>,
			default: undefined,
		},
		position: {
			type: String as PropType<NonNullable<ToastProps["position"]>>,
			required: true,
		},
		visibleToasts: {
			type: Number as PropType<ToastProps["visibleToasts"]>,
			required: true,
		},
		expandByDefault: {
			type: Boolean as PropType<ToastProps["expandByDefault"]>,
			required: true,
		},
		closeButton: {
			type: Boolean as PropType<ToastProps["closeButton"]>,
			required: true,
		},
		interacting: {
			type: Boolean as PropType<ToastProps["interacting"]>,
			required: true,
		},
		cancelButtonStyle: {
			type: Object as PropType<NonNullable<ToastProps["cancelButtonStyle"]>>,
			default: () => ({}),
		},
		actionButtonStyle: {
			type: Object as PropType<NonNullable<ToastProps["actionButtonStyle"]>>,
			default: () => ({}),
		},
		duration: {
			type: Number as PropType<ToastProps["duration"]>,
			default: undefined,
		},
		unstyled: {
			type: Boolean as PropType<ToastProps["unstyled"]>,
			default: undefined,
		},
		descriptionClass: {
			type: String as PropType<ToastProps["descriptionClass"]>,
			default: undefined,
		},
		loadingIcon: {
			type: Object as PropType<ToastProps["loadingIcon"]>,
			default: undefined,
		},
		classes: {
			type: Object as PropType<NonNullable<ToastProps["classes"]>>,
			default: () => ({}),
		},
		icons: {
			type: Object as PropType<NonNullable<ToastProps["icons"]>>,
			default: () => ({}),
		},
		closeButtonAriaLabel: {
			type: String as PropType<ToastProps["closeButtonAriaLabel"]>,
			default: undefined,
		},
		pauseWhenPageIsHidden: {
			type: Boolean as PropType<NonNullable<ToastProps["pauseWhenPageIsHidden"]>>,
			required: true,
		},
		cn: {
			type: Function as PropType<NonNullable<ToastProps["cn"]>>,
			required: true,
		},
		defaultRichColors: {
			type: Boolean as PropType<ToastProps["defaultRichColors"]>,
			default: undefined,
		},
	},
	emits: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		"update:heights": (heights: HeightT[]) => true,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		removeToast: (toast: ToastT) => true,
	},
	setup(props, { emit, attrs }) {
		// Default lifetime of a toasts (in ms)
		const TOAST_LIFETIME = 4000;

		const SWIPE_THRESHOLD = 20;

		const TIME_BEFORE_UNMOUNT = 200;

		const mounted = ref(false);
		const removed = ref(false);
		const swiping = ref(false);
		const swipeOut = ref(false);
		const offsetBeforeRemove = ref(0);
		const initialHeight = ref(0);
		const dragStartTime = ref<Date | null>(null);
		const toastRef = ref<HTMLLIElement | null>(null);
		const isFront = computed(() => props.index === 0);
		const isVisible = computed(() => props.index + 1 <= props.visibleToasts);
		const toastType = computed(() => props.toast.type);
		const dismissible = computed(() => props.toast.dismissible !== false);
		const toastDescriptionClass = computed(() => props.descriptionClass || "");
		const toastRichColors = computed(() => props.toast.richColors ?? props.defaultRichColors);
		const toastClass = computed(() =>
			props.cn(
				// @ts-expect-error Not typed correctly
				attrs.class,
				props.toast.class || "",
				props.classes.toast,
				props.toast.classes?.toast,
				props.classes?.[toastType.value as unknown as keyof ToastClasses],
				props.toast.classes?.[toastType.value as unknown as keyof ToastClasses],
			),
		);

		const toastStyle = props.toast.style || {};

		// Height index is used to calculate the offset as it gets updated before the toast array, which means we can calculate the new layout faster.
		const heightIndex = computed(
			() => props.heights.findIndex((height) => height.toastId === props.toast.id) || 0,
		);
		const toastCloseButton = computed(() => props.toast.closeButton ?? props.closeButton);
		const toastDuration = computed(() => props.toast.duration || props.duration || TOAST_LIFETIME);

		const closeTimerStartTimeRef = ref(0);
		const offset = ref(0);
		const lastCloseTimerStartTimeRef = ref(0);
		const pointerStartRef = ref<{ x: number; y: number } | null>(null);
		const coords = computed(() => props.position.split("-"));
		const y = computed(() => coords.value[0]);
		const x = computed(() => coords.value[1]);
		const isStringOfTitle = computed(() => typeof props.toast.title !== "string");
		const isStringOfDescription = computed(() => typeof props.toast.description !== "string");

		const toastsHeightBefore = computed(() => {
			return props.heights.reduce((prev, curr, reducerIndex) => {
				// Calculate offset up untill current  toast
				if (reducerIndex >= heightIndex.value) {
					return prev;
				}

				return prev + curr.height;
			}, 0);
		});
		const isDocumentHidden = useIsDocumentHidden();
		const toastInvert = computed(() => props.toast.invert || props.invert);
		const disabled = computed(() => toastType.value === "loading");

		onMounted(() => {
			if (!mounted.value) return;

			const toastNode = toastRef.value;
			const originalHeight = toastNode?.style.height;
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			toastNode!.style.height = "auto";
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const newHeight = toastNode!.getBoundingClientRect().height;
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			toastNode!.style.height = originalHeight as string;

			initialHeight.value = newHeight;

			let newHeightArr;
			const alreadyExists = props.heights.find((height) => height.toastId === props.toast.id);

			if (!alreadyExists) {
				newHeightArr = [
					{
						toastId: props.toast.id,
						height: newHeight,
						position: props.toast.position,
					},
					...props.heights,
				];
			} else {
				newHeightArr = props.heights.map((height) =>
					height.toastId === props.toast.id ? { ...height, height: newHeight } : height,
				);
			}

			emit("update:heights", newHeightArr as HeightT[]);
		});

		function deleteToast() {
			// Save the offset for the exit swipe animation
			removed.value = true;
			offsetBeforeRemove.value = offset.value;
			const height = props.heights.filter((height) => height.toastId !== props.toast.id);
			emit("update:heights", height);

			setTimeout(() => {
				emit("removeToast", props.toast);
			}, TIME_BEFORE_UNMOUNT);
		}

		function handleCloseToast() {
			if (disabled.value || !dismissible.value) {
				return;
			}

			deleteToast();
			props.toast.onDismiss?.(props.toast);
		}

		function onPointerDown(event: PointerEvent) {
			if (disabled.value || !dismissible.value) return;
			dragStartTime.value = new Date();
			offsetBeforeRemove.value = offset.value;
			// Ensure we maintain correct pointer capture even when going outside of the toast (e.g. when swiping)
			(event.target as HTMLElement).setPointerCapture(event.pointerId);
			if ((event.target as HTMLElement).tagName === "BUTTON") return;
			swiping.value = true;
			pointerStartRef.value = { x: event.clientX, y: event.clientY };
		}

		function onPointerUp() {
			if (swipeOut.value) return;
			pointerStartRef.value = null;

			const swipeAmount = Number(
				toastRef.value?.style.getPropertyValue("--swipe-amount").replace("px", "") || 0,
			);

			// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
			const timeTaken = new Date().getTime() - dragStartTime.value?.getTime()!;
			const velocity = Math.abs(swipeAmount) / timeTaken;

			// Remove only if treshold is met
			if (Math.abs(swipeAmount) >= SWIPE_THRESHOLD || velocity > 0.11) {
				offsetBeforeRemove.value = offset.value;
				props.toast.onDismiss?.(props.toast);
				deleteToast();
				swipeOut.value = true;
				return;
			}

			toastRef.value?.style.setProperty("--swipe-amount", "0px");
			swiping.value = false;
		}

		function onPointerMove(event: PointerEvent) {
			if (!pointerStartRef.value || !dismissible.value) return;

			const yPosition = event.clientY - pointerStartRef.value.y;
			const xPosition = event.clientX - pointerStartRef.value.x;

			const clamp = coords.value[0] === "top" ? Math.min : Math.max;
			const clampedY = clamp(0, yPosition);
			const swipeStartThreshold = event.pointerType === "touch" ? 10 : 2;
			const isAllowedToSwipe = Math.abs(clampedY) > swipeStartThreshold;

			if (isAllowedToSwipe) {
				toastRef.value?.style.setProperty("--swipe-amount", `${yPosition}px`);
			} else if (Math.abs(xPosition) > swipeStartThreshold) {
				// User is swiping in wrong direction so we disable swipe gesture
				// for the current pointer down interaction
				pointerStartRef.value = null;
			}
		}

		function onAction(event: MouseEvent) {
			if (!isAction(props.toast.action)) return;
			if (event.defaultPrevented) return;
			props.toast.action.onClick(event);
			deleteToast();
		}

		function onCancel(event: MouseEvent) {
			if (!isAction(props.toast.cancel)) return;
			if (!dismissible) return;
			props.toast.cancel.onClick(event);
			deleteToast();
		}

		watchEffect(() => {
			// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
			offset.value = heightIndex.value * props?.gap! + toastsHeightBefore.value;
		});

		watchEffect((onInvalidate) => {
			if (
				(props.toast.promise && toastType.value === "loading") ||
				props.toast.duration === Infinity ||
				props.toast.type === "loading"
			) {
				return;
			}
			let timeoutId: ReturnType<typeof setTimeout>;
			let remainingTime = toastDuration.value;

			// Pause the timer on each hover
			const pauseTimer = () => {
				if (lastCloseTimerStartTimeRef.value < closeTimerStartTimeRef.value) {
					// Get the elapsed time since the timer started
					const elapsedTime = new Date().getTime() - closeTimerStartTimeRef.value;

					remainingTime = remainingTime - elapsedTime;
				}

				lastCloseTimerStartTimeRef.value = new Date().getTime();
			};

			const startTimer = () => {
				if (remainingTime === Infinity) return;
				closeTimerStartTimeRef.value = new Date().getTime();

				// Let the toast know it has started
				timeoutId = setTimeout(() => {
					props.toast.onAutoClose?.(props.toast);
					deleteToast();
				}, remainingTime);
			};

			if (
				props.expanded ||
				props.interacting ||
				(props.pauseWhenPageIsHidden && isDocumentHidden)
			) {
				pauseTimer();
			} else {
				startTimer();
			}

			onInvalidate(() => {
				clearTimeout(timeoutId);
			});
		});

		watch(
			() => props.toast.delete,
			(value) => {
				if (value) {
					deleteToast();
				}
			},
		);

		onMounted(() => {
			if (toastRef.value) {
				const height = toastRef.value.getBoundingClientRect().height;
				// Add toast height tot heights array after the toast is mounted
				initialHeight.value = height;

				const newHeights = [
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					{ toastId: props.toast.id, height, position: props.toast.position! },
					...props.heights,
				];
				emit("update:heights", newHeights);
			}
			mounted.value = true;
		});

		onUnmounted(() => {
			if (toastRef.value) {
				const newHeights = props.heights.filter((height) => height.toastId !== props.toast.id);
				emit("update:heights", newHeights);
			}
		});

		return {
			props,
			emit,
			attrs,
			toastRef,
			isFront,
			isVisible,
			toastType,
			dismissible,
			toastClass,
			toastDescriptionClass,
			toastStyle,
			toastRichColors,
			heightIndex,
			toastCloseButton,
			toastDuration,
			closeTimerStartTimeRef,
			offset,
			pointerStartRef,
			coords,
			y,
			x,
			isStringOfTitle,
			isStringOfDescription,
			deleteToast,
			handleCloseToast,
			onPointerDown,
			onPointerUp,
			onPointerMove,
			onAction,
			onCancel,
			toastInvert,
			disabled,
			removed,
			swiping,
			swipeOut,
			offsetBeforeRemove,
			initialHeight,
			dragStartTime,
			isAction,
			mounted,
		};
	},
});
