import { default as Axios } from "axios";
import { config } from "@/lib/config";
import { LS_LOCATION } from "@/lib/constants/localstorage";
import { DEFAULT_LOCATION } from "@/lib/constants/misc";

export const axios = Axios.create({
	baseURL: config.coach.server,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Method": "*",
		"Gym-Location": localStorage.getItem(LS_LOCATION) ?? DEFAULT_LOCATION,
	},
});

export const axiosInterceptorRequests: number[] = [];
export const axiosInterceptorResponses: number[] = [];

export default axios;
