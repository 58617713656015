/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const NODE_ENV = process.env.NODE_ENV!;
export const LOGGING = process.env.VUE_APP_LOGGING === "true" ? true : false;
export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN!;
export const SENTRY_SAMPLE_RATE = Number(process.env.VUE_APP_SENTRY_SAMPLE_RATE!);
export const APPLICATION_NAME = process.env.APPLICATION_NAME!;
export const APPLICATION_VERSION = process.env.APPLICATION_VERSION!;
export const APPLICATION_ENVIRONMENT = process.env.APPLICATION_ENVIRONMENT!;
export const APPLICATION_COMMIT_HASH = process.env.APPLICATION_COMMIT_HASH!;
export const APPLICATION_TIME_OF_BUILD = process.env.APPLICATION_TIME_OF_BUILD!;
