




























import { defineComponent } from "@vue/composition-api";
import User from "@/components/elements/User.vue";

export default defineComponent({
	components: {
		User,
	},
	data() {
		return {
			links: ["Coach dashboard", "Leden", "Live feed", "Groepslessen", "Afspraken", "Formulieren"],
		};
	},
});
