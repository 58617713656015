































































































































































import { type PropType, defineComponent } from "@vue/composition-api";
import type { IomodelsProefsporterLes } from "@/lib/backend/reserveer.api";
import { VERWIJZING_MAP } from "@/lib/constants/options";

export default defineComponent({
	name: "PfgFormWelkomstformulier",
	props: {
		geslacht: {
			type: String as PropType<Geslacht>,
			required: false,
			default: undefined,
		},
		geboortedatum: {
			type: String,
			required: false,
			default: undefined,
		},
		voornaam: {
			type: String,
			required: false,
			default: undefined,
		},
		achternaam: {
			type: String,
			required: false,
			default: undefined,
		},
		email: {
			type: String,
			required: false,
			default: undefined,
		},
		telefoonNummer: {
			type: String,
			required: false,
			default: undefined,
		},
		telefoonMobiel: {
			type: String,
			required: false,
			default: undefined,
		},
		verwijzing: {
			type: String as PropType<IomodelsProefsporterLes["verwijzing"]>,
			required: false,
			default: undefined,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		doel: {
			type: String,
			required: false,
			default: undefined,
		},
		bereiken: {
			type: String,
			required: false,
			default: undefined,
		},
		frequentie: {
			type: Number,
			required: false,
			default: undefined,
		},
		interests: {
			type: Array as PropType<
				Array<{
					slug: string;
					label: string;
					key: string;
					value: boolean;
				}>
			>,
			required: false,
			default: () => [],
		},
	},
	data() {
		return { VERWIJZING_MAP };
	},
});
