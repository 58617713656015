












































import { defineComponent } from "@vue/composition-api";
import { mapStores } from "pinia";
import { mapActions } from "vuex";
import { usePopupStore } from "@/pinia/popup";
import BaseModal from "./BaseModal.vue";

export default defineComponent({
	name: "PfgModalSendMessage",
	components: {
		BaseModal,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			title: "",
			message: "",
		};
	},
	computed: {
		...mapStores(usePopupStore),
	},
	methods: {
		...mapActions("modal", {
			closeModal: "closeModal",
		}),
		...mapActions("groepsles", {
			sendMessageToParticipants: "sendMessageToParticipants",
		}),
		async onSendMessage() {
			const isValid = await this.$validator.validateAll();

			if (!isValid) return;

			this.popupStore.open({
				title: "Bevestig bericht",
				body: "Weet je zeker dat je het bericht wil versturen?",
				buttons: {
					cancel: "Nee",
					confirm: "Ik weet het zeker",
				},
				callback: async () => {
					await this.sendMessageToParticipants({
						lesId: this.data.les.les_id,
						title: this.title,
						message: this.message,
					});
					this.popupStore.close();
					this.closeModal();

					this.popupStore.open({
						title: "Bericht verstuurd!",
						body: "Het bericht is verstuurd naar de deelnemers.",
						buttons: {
							cancel: "Sluiten",
						},
					});
				},
			});
		},
	},
});
