<!--
With our version of the modal (Modal.vue), we can only use one modal at a time.
To use a confirm modal on top of another modal, we need to create a new modal.
-->

<template>
	<!-- eslint-disable vue/no-v-html -->
	<div>
		<transition name="drop">
			<div v-show="opened" class="pfg-modal">
				<div class="pfg-modal_container">
					<transition name="drop">
						<modal-confirm
							v-if="opened == 'confirm'"
							:callback="modalCallbacks['confirm']"
							:data="modalData['confirm']"
							@close="closeModal"
						/>

						<div v-if="opened == 'error'" class="pfg-modal_error">
							<header class="pfg-header">
								<div class="pfg-header_heading">
									<h1 class="s_elem-has-icon-left a_fill_default">Er is iets fout gegaan</h1>
								</div>
							</header>
							<p class="a_margin-top-20" v-html="modalData.error" />
							<div class="pfg-button_group a_flex a_justify_flex-end a_margin-top-40">
								<button
									class="button v_brand_primary v_smallest a_margin-bottom-0"
									@click="closeModal"
								>
									<span class="button-txt">Sluiten</span>
								</button>
							</div>
						</div>
					</transition>
				</div>
			</div>
		</transition>

		<transition name="fade">
			<div v-show="opened" class="pfg-modal_bg" @click="closeModal"></div>
		</transition>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ModalConfirm from "@/components/elements/ModalConfirm";

export default {
	name: "Modal2",
	components: {
		ModalConfirm,
	},
	computed: {
		...mapState("modal2", ["opened", "modalData", "modalCallbacks"]),
	},
	methods: {
		...mapActions("modal2", ["closeModal"]),
	},
};
</script>

<style lang="scss" scoped>
////
////    VUE TRANSITIONS
////

.drop-enter-active {
	transition: all 0.2s ease;
}
.drop-leave-active {
	transition: all 0.2s ease;
}
.drop-enter {
	opacity: 0;
	transform: translateY(-40px);
}
.drop-enter-to,
.drop-leave {
	opacity: 1;
	transform: translateY(0);
}
.drop-leave-to {
	opacity: 0;
	transform: translateY(-40px);
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}
.fade-enter,
.fade-leave-to {
	transition: opacity 0.2s ease 0.1s;
	opacity: 0;
}

////
////    GENETRICS
////
.pfg-modal {
	position: fixed;
	z-index: 5556;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.pfg-modal_container {
	background: $brand-white;
	box-shadow: 0 0 30px rgba(black, 0.3);
	padding: $default-padding;
	border-radius: 15px;
	width: 700px;
	max-width: 85vw;

	@include breakpoint(medium) {
		padding: $default-padding * 2;
	}
}

////
////    BG
////
.pfg-modal_bg {
	position: fixed;
	z-index: 5555;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($brand-primary, 0.9);
}
</style>
