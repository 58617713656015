








import { defineComponent } from "@vue/composition-api";

export default defineComponent({
	props: { visible: { type: Boolean } },
	setup() {
		return {
			bars: Array(12)
				.fill(0)
				.map((_, i) => i),
		};
	},
});
