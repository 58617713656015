// eslint-disable-next-line no-restricted-imports
import type { RootLogger } from "loglevel";
import prefix from "loglevel-plugin-prefix";

const COLORS = {
	RESET: "\x1b[0m",
	TEXT: {
		BLACK: "\x1b[30m",
		RED: "\x1b[31m",
		GREEN: "\x1b[32m",
		YELLOW: "\x1b[33m",
		BLUE: "\x1b[34m",
		MAGENTA: "\x1b[35m",
		CYAN: "\x1b[36m",
		WHITE: "\x1b[37m",
	},
	BACKGROUND: {
		BLACK: "\x1b[40m",
		RED: "\x1b[41m",
		GREEN: "\x1b[42m",
		YELLOW: "\x1b[43m",
		BLUE: "\x1b[44m",
		MAGENTA: "\x1b[45m",
		CYAN: "\x1b[46m",
		WHITE: "\x1b[47m",
	},
} as const;

function wrapInColor(text: string, color: string) {
	return `${color}${text}${COLORS.RESET}`;
}

export function prefixLogger(logger: RootLogger) {
	prefix.reg(logger);

	prefix.apply(logger, {
		timestampFormatter: (date) => date.toISOString(),
		levelFormatter: (level) => level.toUpperCase(),
		nameFormatter: (name) => name || "root",
		format: (level, name, timestamp) =>
			[
				wrapInColor(`[${timestamp}]`, COLORS.TEXT.BLACK),
				wrapInColor(
					level,
					{
						TRACE: COLORS.TEXT.BLACK,
						DEBUG: COLORS.TEXT.GREEN,
						INFO: COLORS.TEXT.MAGENTA,
						WARN: COLORS.TEXT.YELLOW,
						ERROR: COLORS.TEXT.RED,
					}[level.toUpperCase()] ?? COLORS.TEXT.WHITE,
				),
				wrapInColor(`(${name})`, COLORS.TEXT.CYAN),
			].join(" ") + ":",
	});
}
