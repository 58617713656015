import { differenceInYears, getWeek } from "date-fns";
import { DateTime } from "klokwerk";
import moment from "moment";

export function getWeekNumber(date: Date = new Date()): number {
	return getWeek(date);
}

export const weekDag = (value: string | moment.Moment, formatValue?: string) => {
	return moment(value, formatValue).format("dddd");
};

export const weekDagVandaag = (value: string | moment.Moment) => {
	if (moment(value).isSame(moment(), "day")) {
		return "vandaag";
	}
	return moment(value).format("dddd");
};

export const tijdstip = (value: string | moment.Moment) => {
	return moment(value).format("H:mm");
};

export const dagMaand = (value: string | moment.Moment, formatValue?: string) => {
	return moment(value, formatValue).format("D MMMM");
};

export const dagMaandJaar = (value: string | moment.Moment, formatValue?: string) => {
	return moment(value, formatValue).format("D MMMM YYYY");
};

export const dagMaandKort = (value: string | moment.Moment) => {
	return moment(value).format("D/M");
};

export const weekDagKort = (value: string | moment.Moment) => {
	return moment(value).format("ddd");
};

export const thisWeek = (value: string | moment.Moment) => {
	return moment(value).week() === moment().week();
};

export function formatDate(date: Date = new Date(), options?: Intl.DateTimeFormatOptions): string {
	return new Intl.DateTimeFormat("nl", options).format(date);
}

export function getTimeOfDay(
	date: Date = new Date(),
	timeStyle: Intl.DateTimeFormatOptions["timeStyle"] = "short",
): string {
	return formatDate(date, { timeStyle });
}

export function getWeekDay(
	date: Date = new Date(),
	weekday: Intl.DateTimeFormatOptions["weekday"] = "long",
): string {
	return formatDate(date, { weekday });
}

export function getDayMonthYear(
	date: Date = new Date(),
	dateStyle: Intl.DateTimeFormatOptions["dateStyle"] = "long",
): string {
	return formatDate(date, { dateStyle });
}

export function getDayMonth(
	date: Date = new Date(),
	options: Intl.DateTimeFormatOptions = {
		year: undefined,
		month: "long",
		day: "numeric",
	},
): string {
	return formatDate(date, options);
}

export function getMonthYear(
	date: Date = new Date(),
	options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "numeric",
		day: undefined,
	},
): string {
	return formatDate(date, options);
}

export function getDateFromDatum(datum: string): Date {
	const [date, month, year] = datum.split("-");

	// Set lowest number first, as this fails when doing it the other way around.
	// 31-05-2022 -> 31 Mei 2022 ✅
	// 01-06-2022 -> 1 Juli 2022 ❌
	return new DateTime()
		.setDate(Number(date))
		.setMonth(Number(month) - 1)
		.setFullYear(Number(year)).native;
}

export function getDateFromDatumAndTijd(datum: string, tijd: string): Date {
	const datumDateTime = getDateFromDatum(datum);

	const [hours, minutes] = tijd.split(":");

	return new DateTime(datumDateTime).setMinutes(Number(minutes)).setHours(Number(hours)).native;
}

export function getAge(value?: number | string | Date): number {
	return differenceInYears(new Date(), new Date(value ?? ""));
}
