





























import { defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";
import { base64ToBlob } from "@/utils/base64ToBlob";

type Data = {
	loading: boolean;
	file?: Blob;
};

export default defineComponent({
	name: "PfgImageUploadComponent",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: String,
			default: undefined,
			required: false,
		},
	},
	emits: ["input", "fileChange"],
	data(): Data {
		return {
			loading: false,
			file: undefined,
		};
	},
	computed: {
		isWebcamSupported(): boolean {
			if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) return false;

			return true;
		},
	},
	methods: {
		...mapActions("modal", {
			openModal: "openModal",
		}),
		handleChange(payload: Event) {
			this.fileChange((payload.target as HTMLInputElement).files);
		},
		openWebcamModal() {
			this.openModal({
				name: "webcam",
				callback: (image: string) => {
					this.$emit("input", image);

					const [contentTypeBlock, dataBlock] = image.split(";");
					const [, contentType] = contentTypeBlock.split(":");
					const [, realData] = dataBlock.split(",");

					const blob = base64ToBlob(realData, contentType);

					this.$emit("fileChange", blob);
				},
			});
		},
		reset() {
			// @ts-expect-error Apply not correctly typed
			Object.assign(this.$data, this.$options.data?.apply(this));
		},
		fileChange(files: FileList | null) {
			if (files) {
				this.file = files[0];
				this.$emit("fileChange", this.file);
			}

			const reader = new FileReader();
			reader.onloadend = () => {
				this.$emit("input", reader.result);
			};

			if (this.file) reader.readAsDataURL(this.file);
		},
	},
});
