













































import { type PropType, defineComponent } from "@vue/composition-api";
import TableMetingenPart from "@/components/elements/TableMetingenPart.vue";
import type { IomodelsCoachappAfgenomenMeting } from "@/lib/backend/club.api";

export default defineComponent({
	name: "PfgTableMetingen",
	components: {
		TableMetingenPart,
	},
	props: {
		data: {
			type: Array as PropType<IomodelsCoachappAfgenomenMeting[]>,
			default: (): IomodelsCoachappAfgenomenMeting[] => [],
		},
		limits: {
			type: Array as PropType<number[]>,
			default: () => [25, 50, 100],
		},
	},
	data() {
		return {
			limit: 0,
			page: 1,
		};
	},
	computed: {
		limitedData(): IomodelsCoachappAfgenomenMeting[] {
			if (!this.data) return [];
			const limitedData = this.data.filter((_, index) => {
				return index >= (this.page - 1) * this.limit && index < this.page * this.limit;
			});
			if (!this.limit) return this.data || [];
			return limitedData;
		},
		pages() {
			if (!this.data || !this.data.length || !this.limit) return 0;
			const pages = Math.ceil(this.data.length / this.limit);
			return pages;
		},
	},
	watch: {
		limit() {
			this.page = 1;
		},
	},
	mounted() {
		if (this.limits.length) {
			this.limit = this.limits[0];
		}
	},
	methods: {
		paginate(num: number) {
			this.page = num;
		},
	},
});
