<template>
	<div>
		<header class="pfg-header">
			<div class="pfg-header_heading">
				<h1 class="s_elem-has-icon-left a_fill_default">
					Annuleren terugdraaien
					<pfg-icon icon-id="icon_reset" />
				</h1>
			</div>
		</header>

		<hr class="v_less-margin" />
		<div class="a_padding-20 a_bg_brand_white">
			<div class="pfg-content">
				<p v-if="message === ''">
					Weet je zeker dat je het annuleren van deze les ongedaan wil maken?
				</p>
				<p v-else :class="{ 'error-message': error }">
					{{ message }}
				</p>
			</div>
		</div>

		<hr class="v_less-margin" />

		<div class="pfg-button_group">
			<!-- Actions -->
			<template v-if="message === '' && !error">
				<button
					class="button v_ghost_brand_secondary v_has-icon-right v_smaller a_margin-left-10"
					@click="$emit('close')"
				>
					<span class="button-txt">{{ message ? "Sluiten" : "Nee, toch niet" }}</span>
				</button>
				<button
					class="button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-left-10"
					@click="onUndoLessonCancellation"
				>
					<span class="button-txt">Ja, ik weet het zeker</span>
				</button>
			</template>
			<!-- After action -->
			<template v-else>
				<button
					class="button v_ghost_brand_secondary v_has-icon-right v_smaller a_margin-left-10"
					@click="$emit('close')"
				>
					<span class="button-txt">Sluiten</span>
				</button>
			</template>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import { logger } from "@/logger";

export default {
	name: "ModalLesAnnuleren",
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		callback: {
			type: Function,
			default: () => {
				return;
			},
		},
	},
	data() {
		return {
			message: "",
			error: false,
		};
	},
	computed: {},
	methods: {
		...mapActions("groepsles", ["undoCancellation"]),
		async onUndoLessonCancellation() {
			try {
				await this.undoCancellation(this.data.lesson.id);
				this.callback();
				this.message = "Annulering van les is teruggedraaid!";
			} catch (error) {
				this.message = "Fout bij het terugdraaien van de annulering van deze les.";
				this.error = true;
				logger.error(error);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.error-message {
	color: red;
}

.pfg-content {
	p {
		margin: 0;
	}
}
</style>
