import { axios } from "@/axios";
import { logger } from "@/logger";

function process(request) {
	return new Promise((resolve, reject) => {
		request
			.then((res) => {
				if (!res || !res.status) {
					return reject("no response");
				}
				if (res && res.status === 200) {
					resolve(res.data);
				} else {
					// logger.error('an error occured posting to', url, res.message);
					reject(res.message);
				}
			})
			.catch((err) => {
				logger.error(err.response.data.error);
				const response =
					err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: err.message;
				logger.error({ response });
				reject(response);
			});
	});
}

const service = {
	get(url) {
		return process(axios.get(url));
	},
	post(url, data = {}) {
		return process(axios.post(url, data));
	},
	put(url, data = {}) {
		return process(axios.put(url, data));
	},
	delete(url, data = {}) {
		return process(axios.delete(url, data));
	},
};

export default service;
