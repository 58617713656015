










































































import { type PropType, defineComponent } from "@vue/composition-api";
import { getDayMonthYear, getTimeOfDay, getWeekDay } from "@/utils/date";

type Data = {
	limit: number;
	page: number;
};

export default defineComponent({
	name: "PfgTableVisits",
	props: {
		data: {
			type: Array as PropType<TODO[]>,
			default: (): TODO[] => [],
			required: false,
		},
		limits: {
			type: Array as PropType<number[]>,
			default: function (): number[] {
				return [25, 50, 100];
			},
			required: false,
		},
	},
	data(): Data {
		return {
			limit: 0,
			page: 1,
		};
	},
	computed: {
		limitedData(): TODO[] {
			if (!this.data) return [];
			const limitedData = this.data.filter((_, index) => {
				return index >= (this.page - 1) * this.limit && index < this.page * this.limit;
			});

			if (!this.limit) return this.data || [];

			return limitedData;
		},
		numberOfPages(): number {
			if (!this.data || !this.data.length || !this.limit) return 0;

			return Math.ceil(this.data.length / this.limit);
		},
	},
	watch: {
		limit() {
			this.page = 1;
		},
	},
	mounted() {
		if (this.limits.length) {
			this.limit = this.limits[0];
		}
	},
	methods: {
		paginate(page: number) {
			this.page = page;
		},
		getWeekDay,
		getDayMonthYear,
		getTimeOfDay,
	},
});
