













































































































import { type PropType, defineComponent } from "@vue/composition-api";
import { isSameDay, isToday } from "date-fns";
import { DateTime } from "klokwerk";
import type { LesUitgebreid } from "@/lib/backend/coach.api";
import type { IomodelsProefsporterLes } from "@/lib/backend/reserveer.api";
import { VERWIJZING_MAP } from "@/lib/constants/options";
import { getDateFromDatumAndTijd } from "@/utils/date";

export default defineComponent({
	name: "PfgFormProefsportenGroepsles",
	props: {
		geslacht: {
			type: String as PropType<Geslacht>,
			required: false,
			default: undefined,
		},
		geboortedatum: {
			type: String,
			required: false,
			default: undefined,
		},
		voornaam: {
			type: String,
			required: false,
			default: undefined,
		},
		achternaam: {
			type: String,
			required: false,
			default: undefined,
		},
		email: {
			type: String,
			required: false,
			default: undefined,
		},
		telefoonNummer: {
			type: String,
			required: false,
			default: undefined,
		},
		telefoonMobiel: {
			type: String,
			required: false,
			default: undefined,
		},
		verwijzing: {
			type: String as PropType<IomodelsProefsporterLes["verwijzing"]>,
			required: false,
			default: undefined,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		groepsles: {
			type: Number,
			required: false,
			default: undefined,
		},
		groepslessen: {
			type: Array as PropType<LesUitgebreid[]>,
			required: false,
			default: (): LesUitgebreid[] => [],
		},
	},
	data() {
		return { VERWIJZING_MAP };
	},
	methods: {
		relativeMoment(les: LesUitgebreid) {
			const weekMap = [
				"zondag",
				"maandag",
				"dinsdag",
				"woensdag",
				"donderdag",
				"vrijdag",
				"zaterdag",
			];

			const date = getDateFromDatumAndTijd(les.datum, les.tijd);

			if (isToday(date)) return "vandaag";

			if (isSameDay(date, new DateTime().setDate((current) => current.date + 1).native))
				return "morgen";

			return weekMap[date.getDay()];
		},
	},
});
