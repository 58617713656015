import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { NODE_ENV, SENTRY_DSN, SENTRY_SAMPLE_RATE } from "./lib/constants/env";

if (NODE_ENV === "production") {
	Sentry.init({
		Vue,
		dsn: SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: SENTRY_SAMPLE_RATE,
		environment: NODE_ENV,
		attachProps: true,
		logErrors: true,
	});
}
